import {Button, Grid, Tooltip} from '@material-ui/core';
import {
  CenterFocusStrong,
  Fullscreen as FullscreenIcon,
  FullscreenExit as FullscreenExitIcon,
  Menu,
  MenuOpen,
} from '@material-ui/icons';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';

// @ts-ignore
import {OrbitControls} from '@kibou/three-orbitcontrols-ts';
import {FullScreen, useFullScreenHandle} from 'react-full-screen';
import {toast} from 'react-toastify';
import {Matrix4, PerspectiveCamera, Plane, Scene, WebGLRenderer} from 'three';

import {useContainerWidth, useWindowSize} from '../../../../utils/utilHooks';
import {BUTTON_HEIGHT, BUTTON_MARGIN} from '../2D/utils';
import {BlankDiv} from '../FadingComponents';
import {SidebarContainer, SIDEBAR_WIDTH} from '../SidebarContainer';
import {PartPointClouds} from './PartPointCloud';
import {
  onClippingPlaneChange,
  toggleAxesHelper,
  toggleBoundsLines,
  toggleBuildPlatformHelper,
  toggleClippingPlane,
  toggleClippingPlaneHelper,
  toggleHelpers,
} from './viewportFunctions/control';
import {View3DViewportParams} from './View3DViewport';
import {
  calcCameraResetPosition,
  initialiseScene,
  onBoundsChange,
  resetCamera,
  resizeViewport,
} from './viewportFunctions/scene';
import {onBackgroundColourChange} from './viewportFunctions/decoration';

export type Axis = 'x' | 'y' | 'z';

type Coord3d = {[axis in Axis]: number};

export interface BoundingBox {
  min: Coord3d;
  dimensions: Coord3d;
  layerBounds?: {min: number; max: number};
}

export interface ViewportParams {
  backgroundColour: string;

  clippingPlaneEnabled: boolean;
  clippingPlaneReverse: boolean;
  clippingPlaneDirection: Axis;
  clippingPlanePosition: number;
  clippingPlane: Plane;

  showAxes: boolean;
  showGrid: boolean;
  showClippingPlane: boolean;
  showBoundingBox: boolean;

  showLayerImage?: boolean;
  reverseImageClipping: boolean;
  layerImageOpacity: number;
  layerImagePosition: number;

  plateSize?: [number, number];
}

export const initialParams: ViewportParams = {
  backgroundColour: '#222222',

  clippingPlaneEnabled: false,
  clippingPlaneReverse: false,
  clippingPlaneDirection: 'y',
  clippingPlanePosition: 0.5,
  clippingPlane: new Plane(),

  showAxes: false,
  showGrid: true,
  showClippingPlane: false,
  showBoundingBox: false,

  reverseImageClipping: false,
  layerImageOpacity: 1,
  layerImagePosition: 1,
};

export interface Base3DViewportProps {
  parentGridColumns?: number; // this is needed to trigger resizes when adding/removing views

  height?: number;

  params: ViewportParams;

  scene: Scene;
  sceneGroup: PartPointClouds;
  sceneBounds: BoundingBox | null;

  sidebar: JSX.Element;
  sidebarInitOpen: boolean;
  overlayMessage?: JSX.Element;
  showOverlayMessage?: boolean;

  leftButtons?: JSX.Element;
  rightButtons?: JSX.Element;

  // Enables showing clipping plane location, axes and grid
  // (also requires them to be enabled in params)
  showHelpers: boolean;

  // Contains a callback to force an update of the viewport
  rerenderRef: React.MutableRefObject<() => void>;

  // Contains a callback to centre the camera on some bounds
  centreCameraOnBoundsRef?: React.MutableRefObject<(bounds: BoundingBox) => void>;

  // Update Transforms from TransformHelper
  updateTransforms?: (tf: Matrix4) => void;

  disableAutofocus?: boolean;
}

const ViewerButton = styled(Button)`
  color: #ffffff;
  min-width: 0px;
  padding: 0;
`;

type LeftButtonsContainerProps = {
  stageWidth: number;
  stageHeight: number;
  sidebarOpen: boolean;
};
const LeftButtonsContainer = styled.div`
  float: left;
  position: relative;
  width: ${(props: LeftButtonsContainerProps) =>
    props.sidebarOpen ? props.stageWidth - SIDEBAR_WIDTH : props.stageWidth}px;
  height: ${(props: LeftButtonsContainerProps) => props.stageHeight}px;
  pointer-events: none;
  color: red;
  > * {
    pointer-events: all;
  }
`;

const OverlayMessageContainer = styled(Grid)`
  position: absolute;
  top: 0;
  left: 0;
  height: ${(props: {$stageHeight: number}) => props.$stageHeight}px;
  color: white;
`;

export default function Base3DViewport(props: Base3DViewportProps) {
  // Calling this causes the viewport width to update on window resize
  useWindowSize();

  const {width: availableWidth = 0, containerRef, setContainerRef: setContainer} = useContainerWidth();
  const [renderContainer, setRenderContainer] = useState<HTMLDivElement | null>(null);
  const [camera] = useState(() => new PerspectiveCamera(50, 1, 0.1, 5000));
  const [renderer] = useState(() => new WebGLRenderer());
  const [cameraAspectWasBad, setCameraAspectWasBad] = useState(false);
  // Controls will be initialised once we have a reference to the domElement
  const [controls, setControls] = useState<OrbitControls | null>(null);

  const [fullScreen, setFullScreen] = useState(false);
  const fullScreenHandle = useFullScreenHandle();

  const [sidebarOpen, setSidebarOpen] = useState(props.sidebarInitOpen);
  const [sidebarOpenDelayed, setSidebarOpenDelayed] = useState(props.sidebarInitOpen);

  const viewportHeight = window.innerHeight;

  const stageWidth = availableWidth;
  const stageHeight = useMemo(() => {
    if (fullScreen) return window.innerHeight;
    if (props.height) return props.height;

    const heightLimit = viewportHeight - 380;
    return Math.max(100, Math.min(heightLimit, (availableWidth / 16) * 9));
  }, [availableWidth, fullScreen, props.height, viewportHeight]);

  const renderScene = useCallback(() => {
    if (renderer && props.scene && camera) {
      renderer.render(props.scene, camera);
      if (!renderer.domElement.contains(document.activeElement) && !containerRef?.contains(document.activeElement)) {
        if (!props.disableAutofocus) {
          renderer.domElement.focus();
        }
      }
    }
  }, [camera, renderer, props.scene, containerRef, props.disableAutofocus]);
  props.rerenderRef.current = renderScene;

  useEffect(() => {
    resizeViewport(camera, renderer, renderScene, stageWidth, stageHeight);
  }, [camera, renderScene, renderer, stageWidth, stageHeight]);

  useEffect(() => {
    initialiseScene(
      props.scene,
      camera,
      setControls,
      renderer,
      props.sceneGroup.group,
      props.params.clippingPlane,
      props.updateTransforms,
      props.params.plateSize
    );
    // We only want to initialise the scene once, when the renderer is ready
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [renderer.domElement]);

  const ctAlignmentHelperEnabled = (props.params as View3DViewportParams).ctAlignmentHelperEnabled;

  useEffect(() => {
    // This is a huge hack. If the viewport loads while the user isn't actively on the tab/browser then the
    // aspect of the camera goes to infinity and is never restored.
    // This checks every 2 seconds if the aspect was bad on last calculation but is good on this,
    // If so, reset the camera
    const interval = setInterval(() => {
      if (props.sceneBounds) {
        const [cameraPos] = calcCameraResetPosition(props.sceneBounds, camera, ctAlignmentHelperEnabled);
        const aspectIsBad = Object.values(cameraPos).some((value) => value === Infinity);

        if (aspectIsBad) {
          setCameraAspectWasBad(true);
        } else {
          setCameraAspectWasBad(false);
          if (cameraAspectWasBad) {
            resetCamera(props.sceneBounds, camera, controls, renderScene, ctAlignmentHelperEnabled);
          }
        }
      }
    }, 2000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera, renderScene, props.sceneBounds, cameraAspectWasBad]);

  const resetViewportCamera = useCallback(
    (bounds: BoundingBox) => resetCamera(bounds, camera, controls, renderScene, ctAlignmentHelperEnabled),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [camera, controls, renderScene]
  );
  if (props.centreCameraOnBoundsRef) props.centreCameraOnBoundsRef.current = resetViewportCamera;

  // Update model bounding box in scene, and reset camera position.
  const sceneBoundsString = JSON.stringify(props.sceneBounds);
  useEffect(() => {
    onBoundsChange(props.params.showBoundingBox, props.sceneBounds, resetViewportCamera, props.scene);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.params.showBoundingBox, resetViewportCamera, props.scene, sceneBoundsString]);

  useEffect(() => {
    onBackgroundColourChange(props.params.backgroundColour, renderScene, props.scene);
  }, [props.params.backgroundColour, renderScene, props.scene]);

  useEffect(() => {
    toggleClippingPlane(props.params.clippingPlaneEnabled, renderScene, renderer);
  }, [props.params.clippingPlaneEnabled, renderScene, renderer]);

  useEffect(() => {
    onClippingPlaneChange(
      props.sceneBounds,
      props.params.clippingPlane,
      props.params.clippingPlaneDirection,
      props.params.clippingPlanePosition,
      props.params.clippingPlaneReverse,
      renderScene
    );
  }, [
    props.sceneBounds,
    props.params.clippingPlane,
    props.params.clippingPlaneDirection,
    props.params.clippingPlanePosition,
    props.params.clippingPlaneReverse,
    renderScene,
  ]);

  useEffect(() => {
    if (props.params.showLayerImage) {
      onClippingPlaneChange(
        props.sceneBounds,
        props.params.clippingPlane,
        'y',
        props.params.clippingPlanePosition,
        props.params.reverseImageClipping,
        renderScene
      );
    }
  }, [
    props.sceneBounds,
    props.params.showLayerImage,
    props.params.clippingPlane,
    props.params.clippingPlanePosition,
    props.params.reverseImageClipping,
    renderScene,
  ]);

  useEffect(() => {
    if (!props.params.showLayerImage) {
      toggleClippingPlane(props.params.clippingPlaneEnabled, renderScene, renderer);
    } else if (props.params.showLayerImage) {
      toggleClippingPlane(true, renderScene, renderer);
    }
  }, [props.params.clippingPlaneEnabled, props.params.showLayerImage, renderScene, renderer]);

  useEffect(() => {
    toggleAxesHelper(props.params.showAxes, renderScene, props.scene);
  }, [props.params.showAxes, renderScene, props.scene]);

  useEffect(() => {
    toggleBuildPlatformHelper(props.params.showGrid, renderScene, props.scene);
  }, [props.params.showGrid, renderScene, props.scene]);

  useEffect(() => {
    toggleClippingPlaneHelper(props.params.showClippingPlane, renderScene, props.scene);
  }, [props.params.showClippingPlane, renderScene, props.scene]);

  useEffect(() => {
    // Bounding box is currently not togglable by UI. Enable manually in code.
    toggleBoundsLines(props.params.showBoundingBox, renderScene, props.scene);
  }, [props.params.showBoundingBox, renderScene, props.scene]);

  useEffect(() => {
    // Toggle showing all helpers. (will be hidden while no models are loaded)
    toggleHelpers(props.showHelpers, renderScene, props.scene);
  }, [props.showHelpers, renderScene, props.scene]);

  useEffect(() => {
    if (controls) {
      controls.addEventListener('change', renderScene);
      return () => {
        controls.removeEventListener('change', renderScene);
      };
    }
  }, [controls, renderScene]);

  useEffect(() => {
    if (renderContainer && renderer && camera) {
      renderer.domElement.tabIndex = 1;
      renderContainer.appendChild(renderer.domElement);
      if (!props.disableAutofocus) {
        renderer.domElement.focus();
      }
    }
  }, [camera, renderContainer, renderer, props.disableAutofocus]);

  const handleToggleSidebar = () => {
    setSidebarOpen((open) => !open);
    setTimeout(() => {
      setSidebarOpenDelayed((open) => !open);
    }, 250);
  };

  const toggleFullScreen = () => {
    try {
      fullScreen ? fullScreenHandle.exit() : fullScreenHandle.enter();
    } catch (e) {
      toast('Fullscreen is unavailable on this device', {type: 'info'});
    }
  };

  return (
    <FullScreen handle={fullScreenHandle} onChange={setFullScreen}>
      <BlankDiv
        ref={(node: HTMLDivElement) => {
          setContainer(node);
        }}
        style={{
          height: stageHeight,
          paddingLeft: (availableWidth - stageWidth) / 2,
        }}
        onClick={() => {
          if (document.activeElement?.tagName !== 'INPUT') {
            renderer.domElement?.focus();
          }
        }}
      >
        <div style={{position: 'relative'}}>
          <div
            // This div contains the three.js canvas.
            ref={setRenderContainer}
            style={{
              height: stageHeight,
              paddingLeft: (availableWidth - stageWidth) / 2,
              width: stageWidth,
            }}
          />
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              overflow: 'hidden',
              width: stageWidth,
              pointerEvents: 'none',
            }}
            tabIndex={0}
          >
            <SidebarContainer
              stageHeight={stageHeight}
              sidebarOpen={sidebarOpen}
              sidebarOpenDelayed={sidebarOpenDelayed}
            >
              {props.sidebar}
            </SidebarContainer>

            <LeftButtonsContainer stageWidth={stageWidth} stageHeight={stageHeight} sidebarOpen={sidebarOpen}>
              {props.showOverlayMessage && (
                <OverlayMessageContainer
                  container
                  direction="column"
                  alignContent="center"
                  alignItems="center"
                  justifyContent="center"
                  $stageHeight={stageHeight}
                >
                  {props.overlayMessage}
                </OverlayMessageContainer>
              )}

              <ViewerButton
                onClick={handleToggleSidebar}
                style={{
                  left: BUTTON_MARGIN,
                  top: BUTTON_MARGIN,
                }}
              >
                <Tooltip title="Toggle sidebar" placement="right">
                  {sidebarOpenDelayed ? <MenuOpen /> : <Menu />}
                </Tooltip>
              </ViewerButton>

              {props.leftButtons}
            </LeftButtonsContainer>

            <div style={{pointerEvents: 'all'}}>
              <Tooltip title="Fit model to screen" placement="left">
                <ViewerButton
                  onClick={() => props.sceneBounds && resetViewportCamera(props.sceneBounds)}
                  style={{
                    right: BUTTON_MARGIN,
                    bottom: BUTTON_MARGIN * 2 + BUTTON_HEIGHT,
                    position: 'absolute',
                  }}
                >
                  <CenterFocusStrong />
                </ViewerButton>
              </Tooltip>

              <Tooltip title={fullScreen ? 'Exit Fullscreen' : 'Fullscreen'} placement="left">
                <ViewerButton
                  onClick={toggleFullScreen}
                  style={{
                    right: BUTTON_MARGIN,
                    bottom: BUTTON_MARGIN,
                    position: 'absolute',
                  }}
                >
                  {fullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
                </ViewerButton>
              </Tooltip>

              {props.rightButtons}
            </div>
          </div>
        </div>
      </BlankDiv>
    </FullScreen>
  );
}
