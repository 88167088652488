import React from 'react';
import {Button, Divider as MuiDivider, Grid, ThemeProvider} from '@material-ui/core';
import {createTheme} from '@material-ui/core/styles';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';

import {View3DViewportParams} from '../View3DViewport';
import PointCloudPicker from './PointCloudPicker';
import RenderOptions, {SimilarityRenderOptions} from './RenderOptions';
import ClippingPlaneOptions from './ClippingPlaneOptions';
import HelperOptions from './HelperOptions';
import {BoundingBox} from '../Base3DViewport';
import {CTAlignmentOptions} from './CTAlignmentOptions';
import {View3DState} from '../types/pointCloudTypes';
import {PartModelPicker} from './PartModelManagement';

const Divider = styled(MuiDivider)(spacing);

const theme = createTheme({palette: {type: 'dark'}});

export interface ViewportSidebarProps<T> {
  params: T;
  sceneBounds: BoundingBox | null;
  centreCameraOnPart?: (partUuid: string) => void;
  setParams: (newParams: T) => void;
  resetParams: () => void;
  viewportState: View3DState;
  hidePartSelection?: boolean;
}

interface ViewportSidebarBaseProps<T> extends ViewportSidebarProps<T> {
  sidebarContent: ((props: ViewportSidebarProps<T>) => JSX.Element)[];
}

function ViewportSidebarBase<T>(props: ViewportSidebarBaseProps<T>) {
  return (
    <ThemeProvider theme={theme}>
      <Grid container direction={'column'} style={{padding: 10}}>
        {props.sidebarContent.map((Component, index) => (
          <React.Fragment key={index}>
            <Component {...props} />
            <Divider my={4} />
          </React.Fragment>
        ))}

        <Grid item>
          <Button onClick={() => props.resetParams()}>Reset sidebar settings</Button>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export function View3DViewportSidebar(props: ViewportSidebarProps<View3DViewportParams>) {
  return (
    <ViewportSidebarBase
      sidebarContent={[PointCloudPicker, PartModelPicker, RenderOptions, ClippingPlaneOptions, HelperOptions]}
      {...props}
    />
  );
}

export function PartPreviewViewportSidebar(props: ViewportSidebarProps<View3DViewportParams>) {
  return <ViewportSidebarBase sidebarContent={[RenderOptions, ClippingPlaneOptions, HelperOptions]} {...props} />;
}

export function SimilarityViewportSidebar(props: ViewportSidebarProps<View3DViewportParams>) {
  return (
    <ViewportSidebarBase sidebarContent={[SimilarityRenderOptions, ClippingPlaneOptions, HelperOptions]} {...props} />
  );
}

export function CTAlignmentViewportSidebar(props: ViewportSidebarProps<View3DViewportParams>) {
  return <ViewportSidebarBase sidebarContent={[CTAlignmentOptions]} {...props} />;
}

export function CTResultsViewportSidebar(props: ViewportSidebarProps<View3DViewportParams>) {
  return (
    <ViewportSidebarBase
      sidebarContent={[PointCloudPicker, RenderOptions, ClippingPlaneOptions, HelperOptions]}
      {...props}
    />
  );
}
