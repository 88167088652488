import React, {Dispatch, SetStateAction} from 'react';
import {Box, Checkbox, CircularProgress, Grid, Tooltip, Typography} from '@material-ui/core';
import MaterialTable from 'material-table';
import {CameraRow} from '../ActiveStepFOCUS';
import {BASE_TABLE_OPTIONS, TableContainer} from '../../../../shared/IndexPage/IndexTable';
import EllipsisTextWithTooltip from '../../../../../components/atoms/Texts/EllipsisTextWithTooltip';
import {ChipStatus} from '../../../../../components/atoms/Status/Status';
import {materialTableIcons} from '../../../../../components/material-table-icons';
import {grayColor} from '../../../../../assets/jss/material-dashboard-react';
import {useSmallScreenSize} from '../../../../../utils/utilHooks';
import {Info} from '@material-ui/icons';

const AutoFocusResultsTable = ({
  cameraRows,
  setCameraRows,
}: {
  cameraRows: CameraRow[];
  setCameraRows: Dispatch<SetStateAction<CameraRow[]>>;
}) => {
  const isSmallScreen = useSmallScreenSize();

  const onRowSelected = (id: number, selected: boolean) => {
    const newCameraRows = cameraRows.map((row: CameraRow) => (row.id === id ? {...row, selected} : row));
    setCameraRows(newCameraRows);
  };

  const autoFocusColumns = [
    {
      title: (
        <Box display="flex" style={{marginLeft: '-12px'}}>
          <Checkbox
            checked={cameraRows.every((row) => row.selected)}
            onChange={() => {
              const allSelected = cameraRows.every((row) => row.selected);
              setCameraRows(cameraRows.map((row) => ({...row, selected: !allSelected})));
            }}
          />{' '}
          ID
        </Box>
      ),
      field: 'id',
      width: '96px',
      render: (row: CameraRow) => {
        return (
          <Grid container alignItems="center" wrap="nowrap" style={{marginLeft: '-12px'}}>
            <Grid item style={{minWidth: '42px'}}>
              <Checkbox checked={row.selected} onChange={() => onRowSelected(row.id, !row.selected)} />
            </Grid>
            <Grid item style={{overflow: 'hidden'}}>
              <EllipsisTextWithTooltip>{row.id}</EllipsisTextWithTooltip>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'Result',
      field: 'result',
      width: '164px',
      render: (row: CameraRow) => {
        if (row.result === 'pending') {
          if (isSmallScreen)
            return (
              <Box display="flex" alignItems="center">
                <ChipStatus variant="warning" message="Pending" chipSize={isSmallScreen ? 'small' : 'medium'} />
                <CircularProgress size={20} style={{color: grayColor[1], marginLeft: '1rem'}} />
              </Box>
            );

          return <ChipStatus variant="warning" message="Pending" chipSize={isSmallScreen ? 'small' : 'medium'} />;
        }

        if (row.result === 'none')
          return <ChipStatus variant="error" message="No Results" chipSize={isSmallScreen ? 'small' : 'medium'} />;
        if (row.result === 'success')
          return <ChipStatus variant="success" message="Success" chipSize={isSmallScreen ? 'small' : 'medium'} />;
        if (row.result === 'failure')
          return <ChipStatus variant="error" message="Failed" chipSize={isSmallScreen ? 'small' : 'medium'} />;
      },
    },
    {
      title: (
        <>
          Comments
          <Tooltip
            title={
              <Typography>
                The Comments here are based on the quality of focus of each camera and how it compares to it's
                neighboring cameras.
              </Typography>
            }
          >
            <Info color="action" style={{height: '22px', margin: '0px 0px -4px 6px'}} />
          </Tooltip>
        </>
      ),
      field: 'message',
      width: 'auto',
      render: (row: CameraRow) => {
        if (row.result === 'none') return 'No autofocus results available.';
        if (row.result !== 'pending') return row.message;
        return (
          <Box display="flex" alignItems="center">
            <CircularProgress size={20} style={{color: grayColor[1], marginRight: '1rem'}} />
            <Typography style={{color: grayColor[1]}}>Waiting for autofocus to complete...</Typography>
          </Box>
        );
      },
    },
    {
      title: 'Last Focused',
      field: 'timestamp',
      width: '184px',
    },
  ];

  const smallScreenFocusColumns = [
    {
      ...autoFocusColumns[0],
      width: undefined,
      cellStyle: {padding: '8px'},
      headerStyle: {padding: '8px'},
    },
    {
      ...autoFocusColumns[1],
      width: undefined,
      cellStyle: {padding: '8px'},
      headerStyle: {padding: '8px'},
    },
  ];

  return (
    <MaterialTable
      columns={isSmallScreen ? smallScreenFocusColumns : (autoFocusColumns as any)}
      data={cameraRows}
      icons={materialTableIcons}
      components={{
        Container: (props) => (
          <TableContainer
            {...props}
            $minTableWidth={isSmallScreen ? undefined : '600px'}
            className="material-index-table"
          />
        ),
      }}
      detailPanel={!isSmallScreen ? undefined : DetailPanel}
      options={{...BASE_TABLE_OPTIONS, paging: false}}
    />
  );
};

export default AutoFocusResultsTable;

const DetailPanel = (row: CameraRow) => {
  return (
    <Box px={4} py={2}>
      <Typography>
        <b>Last focused: </b> {row.timestamp}
      </Typography>
      <br />
      <Typography>{row.message}</Typography>
    </Box>
  );
};
