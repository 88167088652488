import React from 'react';
import {useSelector} from 'react-redux';
import {cloneDeep, get, set} from 'lodash';
import {useParams} from 'react-router-dom';
import {IconButton} from '@material-ui/core';
import {Remove} from '@material-ui/icons';
import {RootState} from '../../store/reducers';
import {useAsyncDispatch} from '../../ReduxRoot';
import buildReportDefectProfileActions from '../../store/actions/buildReportDefectProfileActions';
import {dangerColor} from '../../assets/jss/material-dashboard-react';

function BuildReportDefectProfileRemoveCondition({
  grade,
  path,
  conditionIndex,
  conditionGroupIndex,
}: {
  grade: 'a' | 'b' | 'c';
  path: string;
  conditionIndex: number;
  conditionGroupIndex: number;
}) {
  const asyncDispatch = useAsyncDispatch();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const gradeConditions = useSelector(
    (state: RootState) => state.buildReportDefectProfileStore.byId[selectedUuid][`${grade}Grade`]
  );

  async function removeCondition() {
    const currentConditions = cloneDeep(get(gradeConditions, path));
    currentConditions.splice(conditionIndex, 1);
    const newGradeConditions = cloneDeep(gradeConditions);
    if (currentConditions.length === 0) {
      newGradeConditions.conditionGroups.splice(conditionGroupIndex, 1);
    } else {
      set(newGradeConditions, path, currentConditions);
    }

    await asyncDispatch(
      buildReportDefectProfileActions.updateProfile(selectedUuid, {[`${grade}Grade`]: newGradeConditions})
    );
  }

  return (
    <IconButton style={{color: dangerColor[0], paddingLeft: '0px', marginLeft: '-4px'}} onClick={removeCondition}>
      <Remove />
    </IconButton>
  );
}

export default BuildReportDefectProfileRemoveCondition;
