import React, {useCallback, useState} from 'react';
import {Typography, FormControlLabel, Checkbox, Grid, Tooltip, Button, Box, ThemeProvider} from '@material-ui/core';
import Dropzone from 'react-dropzone';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useSelector} from 'react-redux';

import {CloudUpload} from '@material-ui/icons';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

import {ViewportSidebarProps} from './View3DViewportSidebar';
import {View3DViewportParams} from '../View3DViewport';
import SearchAndSelect from '../../../Selector/SearchAndSelect';
import {useMultiPartUpload} from '../../../../../utils/contexts/MultiPartUploadContext';
import {MultiPartUploadResourceType} from '../../../../../utils/contexts/IMultiPartUploadContext';
import {GenericDialog} from '../../../DialogButton';
import maTheme from '../../../../../theme';
import {RootState} from '../../../../../store/reducers';
import DropZoneContainer from '../../../Cards/DropZoneContainer';
import GenericTable from '../../../Table/GenericTable';
import styled from 'styled-components';

export const PartModelPicker = (props: ViewportSidebarProps<View3DViewportParams>) => {
  const [partUploaderOpen, setPartUploaderOpen] = useState(false);
  const [partManagerOpen, setPartManagerOpen] = useState(false);
  const theme = useSelector((state: RootState) => state.theme);

  if (!props.params.availablePartModels || !props.params.selectedPartModels) return <></>;

  const onRowDelete = async (partModelUuid: string) => {
    props.setParams({
      ...props.params,
      availablePartModels: props.params.availablePartModels!.filter((p) => p.uuid !== partModelUuid),
      selectedPartModels: props.params.selectedPartModels!.filter((p) => p.uuid !== partModelUuid),
    });
  };

  return (
    <>
      <Grid item style={{width: 'inherit'}}>
        <Typography>Part STL Models</Typography>

        <div style={{paddingTop: '6px'}}>
          <Button color="primary" variant="contained" fullWidth onClick={() => setPartUploaderOpen(true)}>
            Upload Part STL Model
          </Button>
        </div>
        <div style={{paddingTop: '6px'}}>
          <Button color="primary" variant="contained" fullWidth onClick={() => setPartManagerOpen(true)}>
            Manage Part STL Models
          </Button>
        </div>
        <Grid item>
          <Tooltip
            title={
              <Typography>
                Rotate your part STL models to align with our point cloud. Only one part may be selected at a time when
                in this mode.
              </Typography>
            }
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={props.params.partModelRotationMode}
                  onChange={(event) => {
                    const checked = event.target.checked;
                    props.setParams({
                      ...props.params,
                      selectedPartModels: [],
                      selectedParts: [],
                      partModelRotationMode: checked,
                    });
                  }}
                  name="enablePartModelRotation"
                />
              }
              label="Model Rotation Mode"
            />
          </Tooltip>
        </Grid>
        {/* View3DViewportSidebar sets it's own "Dark" theme. We're reverting it back to the main app theme here. */}
        <ThemeProvider theme={maTheme[theme.currentTheme]}>
          <GenericDialog
            title="Upload Part Model"
            isOpen={partUploaderOpen}
            closeDialog={() => setPartUploaderOpen(false)}
            closeText="Close"
            hideCloseTextButton
            content={
              <Box width="100%" style={{overflowY: 'auto'}}>
                <PartStlUploader
                  parts={props.params.availableParts}
                  alreadyUploadedPartUuids={props.params.availablePartModels.map((part) => part.partUuid)}
                  closeModal={() => setPartUploaderOpen(false)}
                  postUploadCallback={(partUuid) => {
                    toast(
                      <>
                        <b>You are now in Part Model Rotation mode.</b>
                        <br />
                        <br />
                        Rotate your part STL models to align with our point cloud. Only one part may be selected at a
                        time when in this mode.
                        <br />
                        <br />
                        Once you are done with rotation, click the checkbox below to exit this mode.
                      </>,
                      {type: 'info', autoClose: 10000, toastId: 'partModelRotationInfo', pauseOnHover: true}
                    );
                    props.setParams({
                      ...props.params,
                      partModelRotationMode: true,
                      selectedParts: [props.params.availableParts.find((part) => part.uuid === partUuid)!],
                      selectedPartModels: [],
                    });
                  }}
                />
              </Box>
            }
            maxWidth="lg"
          />
        </ThemeProvider>

        <ThemeProvider theme={maTheme[theme.currentTheme]}>
          <GenericDialog
            title="Manage Part STL Models"
            isOpen={partManagerOpen}
            closeDialog={() => setPartManagerOpen(false)}
            closeText="Close"
            hideCloseTextButton
            content={
              <Box width="100%" style={{overflowY: 'auto'}}>
                <ManagedPartStlModels onRowDelete={onRowDelete} />
              </Box>
            }
            maxWidth="lg"
          />
        </ThemeProvider>
      </Grid>
    </>
  );
};

const PartStlUploader = ({
  parts,
  alreadyUploadedPartUuids,
  closeModal,
  postUploadCallback,
}: {
  parts: IPartGETResponse[];
  alreadyUploadedPartUuids: string[];
  closeModal: () => void;
  postUploadCallback: (partUuid: string) => void;
}) => {
  const [part, setPart] = useState<IPartGETResponse>();
  const {onDrop} = useMultiPartUpload();

  const searchParts = useCallback(
    async (search: string): Promise<IPartGETResponse[]> => {
      const excludeAlreadyUploadedParts = parts.filter((part) => !alreadyUploadedPartUuids.includes(part.uuid));
      if (!search) return excludeAlreadyUploadedParts;

      return excludeAlreadyUploadedParts.filter((part) => part.name.toLowerCase().includes(search.toLowerCase()));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [alreadyUploadedPartUuids.length, parts.length]
  );

  function dropZoneOnDrop(droppedFiles: File[]) {
    if (droppedFiles.length > 1) {
      toast('Can only upload one file at a time', {type: 'info'});
      return;
    }
    onDrop(droppedFiles, MultiPartUploadResourceType.partModelAttachment, {
      resourceUuid: part!.uuid,
      orgUuid: part!.organizationUuid,
      setNumFilesUploading: () => {},
      postUploadCallback: () => postUploadCallback(part!.uuid),
    });
    closeModal();
  }

  return (
    <Box padding="12px" style={{maxWidth: '332px', width: '332px', overflow: 'hidden'}}>
      <SearchAndSelect
        selected={part}
        setSelected={setPart}
        getSuggestionValue={(resource) => (resource as any).name}
        isSelected={(resource) => (part ? part.uuid === (resource as any).uuid : false)}
        fetchFunction={searchParts}
        label="Part"
      />
      {!!part && (
        <Dropzone onDrop={dropZoneOnDrop} noClick accept={{'application/*': ['.stl']}}>
          {({getRootProps, getInputProps, open, isDragActive}) => (
            <DropZoneContainer isDragActive={isDragActive} style={{marginTop: '12px'}}>
              <section className="container">
                <div {...getRootProps({className: 'dropzone'})}>
                  <input {...getInputProps()} />
                  <Row>
                    <CloudUpload color="primary" style={{fontSize: 32}} />
                  </Row>
                  <Row>
                    <Typography variant="h6">Drag & drop to upload</Typography>
                  </Row>
                  <Row>
                    <Typography variant="subtitle1">or</Typography>
                  </Row>
                  <Row>
                    <Button variant="contained" color="primary" onClick={open} size="medium" fullWidth={false}>
                      Browse files...
                    </Button>
                  </Row>
                  <Row>
                    <Typography style={{fontStyle: 'italic', marginTop: '16px'}}>Only accepts STL files</Typography>
                  </Row>
                </div>
              </section>
            </DropZoneContainer>
          )}
        </Dropzone>
      )}
    </Box>
  );
};

const ManagedPartStlModels = ({onRowDelete}: {onRowDelete: (uuid: string) => void}) => {
  const {uuid} = useParams<{uuid: string}>();

  const usePartModels = () => {
    const partModels = useSelector((state: RootState) => state.partModelAttachmentStore.list);
    return partModels.map((partModel) => ({
      ...partModel,
      onRowDelete: () => onRowDelete(partModel.uuid),
    }));
  };

  return (
    <GenericTable
      resourceType="partModelAttachment"
      filteringEnabled={false}
      useData={usePartModels}
      permanentFilters={{buildUuid: uuid}}
    />
  );
};

const Row = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4px 0px;
`;
