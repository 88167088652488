import React, {useState} from 'react';
import {Box, Button, CircularProgress} from '@material-ui/core';
import {dangerColor} from '../../assets/jss/material-dashboard-react';
import {useExtraSmallScreenSize} from '../../utils/utilHooks';
import {RootState} from '../../store/reducers';
import {useSelector} from 'react-redux';
import {useHistory, useParams} from 'react-router-dom';
import {useAsyncDispatch} from '../../ReduxRoot';
import buildReportDefectProfileActions from '../../store/actions/buildReportDefectProfileActions';
import {
  buildReportDefectProfileDELETE,
  buildReportDefectProfilePATCH,
  buildReportDefectProfilePOST,
} from '../../api/ajax/buildReportDefectProfile';
import {omit} from 'lodash';
import {IBuildReportDefectProfile} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {ApiResult} from '../../api/ajax/ajaxWrapper';

function BuildReportDefectProfileActions() {
  const isXsScreen = useExtraSmallScreenSize();
  const asyncDispatch = useAsyncDispatch();
  const history = useHistory();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profile = useSelector((state: RootState) => state.buildReportDefectProfileStore.byId[selectedUuid]);
  const hasUnsavedChanges = useSelector(
    (state: RootState) => !!state.buildReportDefectProfileStore.originals[selectedUuid]
  );
  const [deleting, setDeleting] = useState(false);
  const [saving, setSaving] = useState(false);

  const isNewProfile = selectedUuid === 'new';

  async function onSave() {
    setSaving(true);
    let res: ApiResult<IBuildReportDefectProfile>;

    if (isNewProfile) {
      res = await buildReportDefectProfilePOST(omit(profile, 'uuid'));
    } else {
      res = await buildReportDefectProfilePATCH(selectedUuid, omit(profile, 'uuid'));
    }

    if (res.success) {
      await asyncDispatch(buildReportDefectProfileActions.removeUnsavedChanges(selectedUuid));
      if (isNewProfile) {
        history.replace(`/reports/buildReport/defectProfile/${res.data.uuid}`);
      }
    }

    setSaving(false);
  }
  async function onDelete() {
    setDeleting(true);
    const res = await buildReportDefectProfileDELETE(selectedUuid);
    if (res.success) {
      history.replace('/reports/buildReport/defectProfile');
    }
    setDeleting(false);
  }

  async function onCancelChanges() {
    await asyncDispatch(buildReportDefectProfileActions.revertUnsavedChanges(selectedUuid));

    if (isNewProfile) {
      history.replace('/reports/buildReport/defectProfile');
    }
  }

  return (
    <Box width="100%" display="flex" justifyContent="flex-end" flexWrap="wrap">
      {!isNewProfile && (
        <Button
          variant="contained"
          color="primary"
          fullWidth={isXsScreen}
          onClick={onDelete}
          disabled={deleting}
          style={{
            ...(deleting ? {} : {backgroundColor: dangerColor[0], color: 'white'}),
            marginRight: 'auto',
          }}
        >
          Delete {deleting ? <CircularProgress size={20} style={{marginLeft: '12px'}} /> : <></>}
        </Button>
      )}

      <Button
        variant="outlined"
        color="primary"
        fullWidth={isXsScreen}
        onClick={onCancelChanges}
        disabled={saving || (!isNewProfile && !hasUnsavedChanges)}
        style={isXsScreen ? {marginTop: '12px'} : {marginRight: '12px'}}
      >
        Cancel
      </Button>

      <Button
        variant="contained"
        color="primary"
        fullWidth={isXsScreen}
        onClick={onSave}
        disabled={saving || (!isNewProfile && !hasUnsavedChanges)}
        style={isXsScreen ? {marginTop: '12px'} : {}}
      >
        Save {saving ? <CircularProgress size={20} style={{marginLeft: '12px'}} /> : <></>}
      </Button>
    </Box>
  );
}

export default BuildReportDefectProfileActions;
