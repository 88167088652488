import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Divider as MuiDivider, Grid, Paper} from '@material-ui/core';
import {spacing} from '@material-ui/system';
import {RootState} from '../../store/reducers';
import {NoProfiles} from './BuildReportDefectProfilesNoProfiles';
import BuildReportDefectProfileHeader from './BuildReportDefectProfileHeader';
import BuildReportDefectProfileGradeCriteriaForm, {
  BuildReportDefectProfileNCGradeCriteria,
} from './BuildReportDefectProfileGradeCriteriaForm';
import styled from 'styled-components';
import BuildReportDefectProfileActions from './BuildReportDefectProfileActions';

const Divider = styled(MuiDivider)(spacing);

function BuildReportDefectProfileCurrent() {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profile = useSelector((state: RootState) => state.buildReportDefectProfileStore.byId[selectedUuid]);

  if (!profile) {
    return <NoProfiles />;
  }

  return (
    <Paper style={{padding: '24px'}}>
      <Grid container direction="column" spacing={2}>
        <BuildReportDefectProfileHeader />

        <Divider my={6} />

        <Grid item xs={12}>
          <BuildReportDefectProfileGradeCriteriaForm grade="a" />
        </Grid>

        <Divider my={6} />

        <Grid item xs={12}>
          <BuildReportDefectProfileGradeCriteriaForm grade="b" />
        </Grid>

        <Divider my={6} />

        <Grid item xs={12}>
          <BuildReportDefectProfileGradeCriteriaForm grade="c" />
        </Grid>

        <Divider my={6} />

        <Grid item xs={12}>
          <BuildReportDefectProfileNCGradeCriteria />
        </Grid>

        <Divider my={6} style={{marginLeft: '-6px', marginRight: '-6px'}} />

        <Grid item xs={12}>
          <BuildReportDefectProfileActions />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default BuildReportDefectProfileCurrent;
