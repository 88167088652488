import React from 'react';
import {Box, Checkbox, FormControlLabel, Grid, Slider, Tooltip, Typography} from '@material-ui/core';
import {ScatterPlot, DragIndicator, FiberManualRecord} from '@material-ui/icons';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';

import {ViewportSidebarProps} from './View3DViewportSidebar';
import {turboColourMap, redBlueDivergentColourMap} from '../types/pointCloudTypes';
import {ViewportParams} from '../Base3DViewport';
import {View3DViewportParams} from '../View3DViewport';
import {MaterialColorPicker} from '../../../Picker/MaterialColorPicker';
import {PointCloudViewportParams} from '../types/params';

export default function RenderOptions(props: ViewportSidebarProps<View3DViewportParams>) {
  return (
    <>
      <Use3DPointsToggle {...props} />
      <MaxPointsToDisplaySlider {...props} />
      <PointSizeSlider {...props} />
      <DefectAreaSlider {...props} />
      <ModelOpacitySlider {...props} />
      <PointColourPicker {...props} />
      <BackgroundColourPicker {...props} />
    </>
  );
}

export function SimilarityRenderOptions(props: ViewportSidebarProps<View3DViewportParams>) {
  return (
    <>
      <ToggleSimilarityGeometry {...props} />
      {props.params.selectedAnalysisTypes[AnalysisType3D.Geometry] ? <></> : <Use3DPointsToggle {...props} />}
      <TransparencyToggle {...props} />
      <PointSizeSlider {...props} />
      <PointSizeCutoffSlider {...props} />
      <AnalysisAmountCutoffSlider {...props} />
      <AnalysisScalingToggle {...props} />
      <BackgroundColourPicker {...props} />
    </>
  );
}

export const ToggleSimilarityGeometry = <T extends View3DViewportParams>({
  params,
  setParams,
}: ViewportSidebarProps<T>) => {
  if (params.selectedAnalysisTypes[AnalysisType3D.Geometry]) return <></>;

  return (
    <>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={params.sourceGeometryEnabled}
              onChange={(event) =>
                setParams({
                  ...params,
                  sourceGeometryEnabled: event.target.checked,
                })
              }
              name="sourcePartGeometry"
            />
          }
          label="Source Part Geometry"
        />
      </Grid>
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={params.targetGeometryEnabled}
              onChange={(event) =>
                setParams({
                  ...params,
                  targetGeometryEnabled: event.target.checked,
                })
              }
              name="targetPartGeometry"
            />
          }
          label="Target Part Geometry"
        />
      </Grid>
    </>
  );
};

export const Use3DPointsToggle = <T extends PointCloudViewportParams & ViewportParams>(
  props: ViewportSidebarProps<T>
) => {
  return (
    <Grid item>
      <Tooltip
        title="May make geometry more visible at the cost of decreased performance.
                   You may need to decrease the number of points shown below."
      >
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={props.params.use3DPoints}
              onChange={(event) =>
                props.setParams({
                  ...props.params,
                  use3DPoints: event.target.checked,
                })
              }
              name="use3DPoints"
            />
          }
          label="Use 3D points"
        />
      </Tooltip>
    </Grid>
  );
};

export const TransparencyToggle = <T extends PointCloudViewportParams & ViewportParams>(
  props: ViewportSidebarProps<T>
) => {
  return (
    <Grid item>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={props.params.isTransparent}
            onChange={(event) =>
              props.setParams({
                ...props.params,
                isTransparent: event.target.checked,
                modelOpacity: event.target.checked ? 0.5 : 1,
              })
            }
            name="transparent"
          />
        }
        label="Transparent"
      />
    </Grid>
  );
};

export const AnalysisScalingToggle = <T extends View3DViewportParams>(props: ViewportSidebarProps<T>) => {
  const selectedType = Object.keys(props.params.selectedAnalysisTypes)[
    Object.values(props.params.selectedAnalysisTypes).indexOf(true)
  ] as AnalysisType3D;
  if (selectedType === AnalysisType3D.Model || selectedType === AnalysisType3D.Geometry) {
    return <></>;
  }
  return (
    <Grid item>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={props.params.comparisonScaling}
            onChange={(event) =>
              props.setParams({
                ...props.params,
                comparisonScaling: event.target.checked,
              })
            }
            name="scaling"
          />
        }
        label="Scale Colour Dynamically"
      />
    </Grid>
  );
};

export const MaxPointsToDisplaySlider = <T extends PointCloudViewportParams & ViewportParams>(
  props: ViewportSidebarProps<T>
) => {
  return (
    <Grid item>
      <Typography>Max. points to display: {props.params.pointLimit.toExponential(1)}</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <ScatterPlot />
        </Grid>
        <Grid item xs>
          <Slider
            value={Math.log10(props.params.pointLimit)}
            onChange={(_, newValue) =>
              props.setParams({
                ...props.params,
                pointLimit: 10 ** (newValue as number),
              })
            }
            min={5}
            max={7}
            step={0.05}
          />
        </Grid>
        <Grid item>
          <DragIndicator />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const PointSizeSlider = <T extends PointCloudViewportParams & ViewportParams>(
  props: ViewportSidebarProps<T>
) => {
  // Points size between 0-0.2 are hard to adjust and it's a common use case. These transformations
  // Allow the slider range to expand for smaller values and contract for larger values
  // It turns out to be:
  // Point Size -> Slider Value
  // 0   - 0.2 ->  0   - 0.6
  // 0.2 - 1   ->  0.6 - 1.4
  // 1   - 2   ->  1.4 - 2
  function valueIn(value: number) {
    if (value < 0.2) {
      return 3 * value;
    } else if (value < 1) {
      return value + 0.4;
    } else {
      return 1.4 + (value - 1) * 0.6;
    }
  }

  function valueOut(value: number) {
    if (value < 0.6) {
      return value / 3;
    } else if (value < 1.4) {
      return value - 0.4;
    } else {
      return 1 + (value - 1.4) / 0.6;
    }
  }

  return (
    <Grid item>
      <Typography>Point size: {props.params.pointSize.toFixed(3)}</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FiberManualRecord fontSize="small" />
        </Grid>
        <Grid item xs>
          <Slider
            value={valueIn(props.params.pointSize)}
            onChange={(_, newValue) => {
              props.setParams({
                ...props.params,
                pointSize: valueOut(newValue as number),
              });
            }}
            min={0}
            max={2}
            step={0.001}
          />
        </Grid>
        <Grid item>
          <FiberManualRecord fontSize="large" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const DefectAreaSlider = <T extends View3DViewportParams>({params, setParams}: ViewportSidebarProps<T>) => {
  const defectsEnabled = Object.values(AnalysisType3D).some(
    (type) => !!params.selectedAnalysisTypes[type] && ![AnalysisType3D.Model, AnalysisType3D.Geometry].includes(type)
  );
  if (!params.defectAreaFilter) return <></>;

  const {min: currentMin, max: currentMax} = params.defectAreaFilter;
  const {min = 0, max = 0} = params.defectAreaSizes ? params.defectAreaSizes : {};
  const step = (max - min) / 100;

  if ((min === 0 && max === 0) || !defectsEnabled) return <></>;

  return (
    <Grid item>
      <Typography>Defect area filter</Typography>
      <Typography variant="caption">Min: {(currentMin || min).toFixed(2)}mm²</Typography>
      <br />
      <Typography variant="caption">Max: {(currentMax || max).toFixed(2)}mm²</Typography>

      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FiberManualRecord fontSize="small" />
        </Grid>
        <Grid item xs>
          <Slider
            value={[currentMin || min, currentMax || max]}
            onChange={(_, newValue) => {
              const [newMin, newMax] = newValue as [number, number];
              setParams({
                ...params,
                defectAreaFilter: {
                  min: newMin > min ? newMin : min,
                  max: newMax < max ? newMax : max,
                },
              });
            }}
            min={min}
            max={max}
            step={step}
          />
        </Grid>
        <Grid item>
          <FiberManualRecord fontSize="large" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const PointSizeCutoffSlider = <T extends View3DViewportParams>({params, setParams}: ViewportSidebarProps<T>) => {
  if (!params.pointSizeCutoff) return <></>;

  const {min: currentMin, max: currentMax} = params.pointSizeCutoff;
  const {min = 0, max = 0} = params.analysisTypeSizes ? params.analysisTypeSizes[AnalysisType3D.Model!] : {};

  const step = (max - min) / 100;

  return (
    <Grid item>
      <Typography>
        Size cutoff: {currentMin || min} to {currentMax || max}
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FiberManualRecord fontSize="small" />
        </Grid>
        <Grid item xs>
          <Slider
            value={[currentMin || min, currentMax || max]}
            onChange={(_, newValue) => {
              const [newMin, newMax] = newValue as [number, number];

              setParams({
                ...params,
                pointSizeCutoff: {
                  min: newMin,
                  max: newMax < max ? newMax : undefined,
                },
              });
            }}
            min={min}
            max={max}
            step={step}
          />
        </Grid>
        <Grid item>
          <FiberManualRecord fontSize="large" />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const AnalysisAmountCutoffSlider = <T extends View3DViewportParams>({
  params,
  setParams,
}: ViewportSidebarProps<T>) => {
  const selectedType = Object.values(AnalysisType3D).find((type) => !!params.selectedAnalysisTypes[type]);
  if (!selectedType || [AnalysisType3D.Model, AnalysisType3D.Geometry].includes(selectedType)) return <></>;
  if (!params.analysisAmountCutoff) return <></>;

  const {min = 0, max = 0} = params.analysisTypeSizes ? params.analysisTypeSizes[selectedType!] : {};

  const {min: currentMin, max: currentMax} = params.analysisAmountCutoff;

  const asimSelected = selectedType === AnalysisType3D.ASIM;
  const internalMin = asimSelected ? Math.max(0, min) : Math.max(-100, min);
  const internalMax = asimSelected ? Math.min(100, max) : Math.min(100, max);
  const step = (internalMax - internalMin) / 100;

  const minColour = asimSelected
    ? turboColourMap[params.comparisonScaling ? 0 : Math.min(Math.floor(internalMin) * 100, 99)]
    : redBlueDivergentColourMap[params.comparisonScaling ? 0 : Math.min(Math.floor(internalMin) + 100, 199)];
  const maxColour = asimSelected
    ? turboColourMap[params.comparisonScaling ? 99 : Math.min(Math.ceil(internalMax) * 100, 99)]
    : redBlueDivergentColourMap[params.comparisonScaling ? 199 : Math.min(Math.ceil(internalMax) + 100, 199)];

  if (min === 0 && max === 0) return <Typography>No results found</Typography>;

  return (
    <Grid item>
      <Typography>
        Analysis cutoff: {(currentMin || internalMin).toFixed(1)}% to {(currentMax || internalMax).toFixed(1)}%
      </Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FiberManualRecord
            fontSize="medium"
            style={{
              fill: `rgb(${minColour[0]}, ${minColour[1]}, ${minColour[2]}`,
            }}
          />
        </Grid>
        <Grid item xs>
          <Slider
            value={[currentMin || internalMin, currentMax || internalMax]}
            onChange={(_, newValue) => {
              const [newMin, newMax] = newValue as [number, number];
              setParams({
                ...params,
                analysisAmountCutoff: {
                  min: newMin > internalMin ? newMin : undefined,
                  max: newMax < internalMax ? newMax : undefined,
                },
              });
            }}
            min={internalMin}
            max={internalMax}
            step={step}
          />
        </Grid>
        <Grid item>
          <FiberManualRecord
            fontSize="medium"
            style={{
              fill: `rgb(${maxColour[0]}, ${maxColour[1]}, ${maxColour[2]}`,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const ModelOpacitySlider = <T extends PointCloudViewportParams & ViewportParams>(
  props: ViewportSidebarProps<T>
) => {
  return (
    <Grid item>
      <Typography>Model opacity: {props.params.modelOpacity.toFixed(2)}</Typography>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <FiberManualRecord style={{opacity: 0.3}} />
        </Grid>
        <Grid item xs>
          <Slider
            value={props.params.modelOpacity}
            onChange={(_, newValue) =>
              props.setParams({
                ...props.params,
                modelOpacity: newValue as number,
              })
            }
            min={1 / 256} // Lowest opacity before rounding to 0
            max={1}
            step={0.001}
          />
        </Grid>
        <Grid item>
          <FiberManualRecord />
        </Grid>
      </Grid>
    </Grid>
  );
};

export const PointColourPicker = <T extends PointCloudViewportParams & ViewportParams>(
  props: ViewportSidebarProps<T>
) => {
  return (
    <Grid item>
      <Box display="flex" flexDirection="row" alignItems="center">
        <MaterialColorPicker
          title="Part Model Colour"
          currentValue={props.params.overrideColour}
          onChange={(newColour) =>
            props.setParams({
              ...props.params,
              overrideColour: '#' + newColour,
            })
          }
        />
        <Checkbox
          color="primary"
          checked={props.params.overridePointColour}
          onChange={(event) =>
            props.setParams({
              ...props.params,
              overridePointColour: event.target.checked,
            })
          }
        />
      </Box>
    </Grid>
  );
};

export const BackgroundColourPicker = <T extends PointCloudViewportParams & ViewportParams>(
  props: ViewportSidebarProps<T>
) => {
  return (
    <Grid item>
      <MaterialColorPicker
        title="Background Colour"
        currentValue={props.params.backgroundColour}
        onChange={(newColour) =>
          props.setParams({
            ...props.params,
            backgroundColour: '#' + newColour,
          })
        }
      />
    </Grid>
  );
};
