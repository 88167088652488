import * as React from 'react';
import {Provider, useDispatch} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import axios from 'axios';
import App from './App';
import configureStore from './configureStore';
import envConfig from './envConfig';
import {toast, ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const {store} = configureStore();

export type AsyncDispatch = ThunkDispatch<any, any, AnyAction>;
// Use this dispatch if the return value of the dispatched action is needed from an async thunk.
export const useAsyncDispatch = (): AsyncDispatch => useDispatch();

const UNAUTHENTICATED_ROUTES = ['/login', '/support', '/noRole', '/unauthorized'];

axios.interceptors.response.use(
  (response) => {
    // Dismiss the network error toast on any successful response.
    toast.dismiss('networkError');
    return response;
  },
  (error) => {
    if (
      !envConfig.isOnPremise &&
      error.code &&
      (error.code === 'ERR_INTERNET_DISCONNECTED' || error.code === 'ERR_NETWORK_CHANGED')
    ) {
      toast('Network connection lost. Please check your internet connection and try again.', {
        type: 'info',
        toastId: 'networkError',
        autoClose: false,
      });
    } else if (error.response?.status === 401) {
      if (
        UNAUTHENTICATED_ROUTES.some((unauthenticatedRoute) => window.location.pathname.includes(unauthenticatedRoute))
      ) {
        return Promise.reject(error);
      }
      if (envConfig.isOnPremise) {
        window.location.href = '/login/';
        return;
      } else {
        window.location.href = '/api/auth/login';
        return;
      }
    }

    return Promise.reject(error);
  }
);

export function ReduxRoot() {
  return (
    <React.Fragment>
      <Provider store={store}>
        <App />
      </Provider>
      <ToastContainer />
    </React.Fragment>
  );
}
