import React, {useEffect, useState} from 'react';
import * as qs from 'qs';
import {useHistory, useLocation} from 'react-router-dom';
import {spacing} from '@material-ui/system';
import {Grid, Paper as MuiPaper} from '@material-ui/core';
import {isString} from 'lodash';
import styled from 'styled-components';
import {titleize, pluralize, humanize, camelize, underscore} from 'inflection';
import IndexTable from './IndexTable';
import actions from '../../../store/actions';
import {defaultFilters} from './filterSchemas';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {FilterHeader} from './FilterHeader';
import Header from '../../../components/organisms/Header';

const Paper = styled(MuiPaper)(spacing);

export type ResourceTypes =
  | 'build'
  | 'buildEvent'
  | 'buildNote'
  | 'machine'
  | 'buildAttachment'
  | 'sliceAttachment'
  | 'batch'
  | 'recipient'
  | 'defectNotification'
  | 'machineParameter'
  | 'part'
  | 'similarityReport'
  | 'similarityComparison'
  | 'buildIntensityReport'
  | 'ctReport'
  | 'defectSummary'
  | 'defectStatistic'
  | 'resourcePermission'
  | 'user'
  | 'organization'
  | 'material'
  | 'device'
  | 'buildReport'
  | 'alert'
  | 'partModelAttachment';

export type SortDirection = 'ASC' | 'DESC' | undefined;
export type FiltersState = {
  [key: string]: any;
  take: number;
  page: number;
  sortBy?: {[key: string]: SortDirection};
};
export type ComparisonFilters = Omit<FiltersState, 'take' | 'page' | 'sortBy'>;
export type UseDataFn = () => Array<{[key: string]: any; onClickUrl?: string}>;

const queryToDefaultFilters = (query: FiltersState, resourceType: ResourceTypes) => {
  const {take, page, ...rest} = query;
  const baseFilters: FiltersState = {
    take: isString(take) ? parseInt(take) : 25,
    page: isString(page) ? parseInt(page) : 0,
  };

  if (Object.keys(rest).length) return {...baseFilters, ...rest};
  return {...baseFilters, ...defaultFilters[`${resourceType}DefaultFilters`]};
};

const IndexPage = ({
  resourceType,
  CreateResourceComponent,
  useData,
  title,
  onSelectionChange,
  minTableWidth,
  forcedRefresh,
}: {
  resourceType: ResourceTypes;
  CreateResourceComponent: React.FC<{}>;
  useData: UseDataFn;
  title?: string;
  onSelectionChange?: (row: any) => void;
  minTableWidth?: string;
  forcedRefresh?: boolean;
}) => {
  const camelizedResourceType = camelize(underscore(resourceType));
  const resourceTitle =
    resourceType === 'ctReport' ? 'CT Reports' : titleize(pluralize(humanize(underscore(resourceType))));

  const history = useHistory();
  const {search} = useLocation();
  const isSmallScreen = useSmallScreenSize();
  const query = qs.parse(search.replace(/^\?/, '')) as FiltersState;

  const [filters, setFilters] = useState<FiltersState>(queryToDefaultFilters(query, resourceType));

  const resourceActions = actions[`use${camelizedResourceType}StoreActions`]();

  useEffect(() => {
    return () => {
      resourceActions.unsubscribeFromList();
      resourceActions.invalidateListHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, forcedRefresh]);

  useEffect(() => {
    const {page, ...params} = filters;
    const skip = page * params.take;
    resourceActions.fetchAndSubscribeToList({...params, skip});
    history.replace({search: qs.stringify(filters, {arrayFormat: 'brackets'})});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filters), forcedRefresh]);

  useEffect(() => {
    if (Object.keys(query).length === 0) {
      setFilters(queryToDefaultFilters(query, resourceType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceType, JSON.stringify(query)]);

  const handlePageChange = (newPageNumber: number) => {
    setFilters({...filters, page: newPageNumber});
  };
  const handleRowsPerPageChange = (rowsPerPage: number) => {
    setFilters({...filters, take: rowsPerPage, page: 0});
  };
  const handleSortOrderChange = (field: string, direction: SortDirection | null) => {
    const {sortBy: _sortBy, ...newFilters} = filters;
    if (!direction) setFilters(newFilters);
    else setFilters({...newFilters, sortBy: {[field]: direction}});
  };

  return (
    <>
      <Header
        helmet={`${title || resourceTitle} List Page`}
        title={title || resourceTitle}
        breadcrumbs={[resourceTitle]}
        endAdornment={CreateResourceComponent && <CreateResourceComponent />}
      />

      <Paper style={{padding: isSmallScreen ? '18px' : '24px 32px', maxHeight: 'max(720px, 100vh - 124px)'}}>
        <Grid container direction="column">
          <FilterHeader
            resourceType={resourceType}
            filters={filters}
            setFilters={setFilters}
            filteringEnabled
            headerFlexDirection="row"
            tableTitle={['user', 'organization'].includes(resourceType) ? resourceTitle : `My ${resourceTitle}`}
          />
          <IndexTable
            resourceType={resourceType}
            useData={useData}
            filters={filters}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
            handleSortOrderChange={handleSortOrderChange}
            tableOptions={{
              selection: !!onSelectionChange,
              maxBodyHeight: 'max(calc(720px - 156px), calc(100vh - 310px))',
            }}
            tableProps={{
              onSelectionChange: onSelectionChange,
            }}
            minTableWidth={minTableWidth}
          />
        </Grid>
      </Paper>
    </>
  );
};

export default IndexPage;
