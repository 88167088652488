import React from 'react';
import {useSelector} from 'react-redux';
import {cloneDeep, get, set} from 'lodash';
import {useParams} from 'react-router-dom';
import {IconButton} from '@material-ui/core';
import {AddOutlined} from '@material-ui/icons';
import {RootState} from '../../store/reducers';
import {useAsyncDispatch} from '../../ReduxRoot';
import buildReportDefectProfileActions from '../../store/actions/buildReportDefectProfileActions';
import {LogicalOperator} from '@common/api/models/builds/data/defects/IDefectNotifications';

function BuildReportDefectProfileAddConditionGroup({grade}: {grade: 'a' | 'b' | 'c'}) {
  const asyncDispatch = useAsyncDispatch();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const gradeConditions = useSelector(
    (state: RootState) => state.buildReportDefectProfileStore.byId[selectedUuid][`${grade}Grade`]
  );

  async function addConditionGroup() {
    const currentConditionGroups = get(gradeConditions, 'conditionGroups');
    const newConditionGroups = [
      ...cloneDeep(currentConditionGroups),
      {
        operator: LogicalOperator.AND,
        conditions: [
          {
            defectType: undefined,
            unit: undefined,
            threshold: undefined,
          },
        ],
      },
    ];
    const newGradeConditions = cloneDeep(gradeConditions);
    set(newGradeConditions, 'conditionGroups', newConditionGroups);
    await asyncDispatch(
      buildReportDefectProfileActions.updateProfile(selectedUuid, {[`${grade}Grade`]: newGradeConditions})
    );
  }

  return (
    <IconButton onClick={addConditionGroup} color="primary" style={{paddingLeft: '0px', marginLeft: '-4px'}}>
      <AddOutlined />
    </IconButton>
  );
}

export default BuildReportDefectProfileAddConditionGroup;
