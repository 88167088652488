import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {cloneDeep, get, set} from 'lodash';
import {useParams} from 'react-router-dom';
import {Button, Popover, Typography} from '@material-ui/core';
import {RootState} from '../../store/reducers';
import {useAsyncDispatch} from '../../ReduxRoot';
import buildReportDefectProfileActions from '../../store/actions/buildReportDefectProfileActions';
import {LogicalOperator} from '@common/api/models/builds/data/defects/IDefectNotifications';
import {useSmallScreenSize} from '../../utils/utilHooks';

function BuildReportDefectProfileChangeOperator({grade, path}: {grade: 'a' | 'b' | 'c'; path: string}) {
  const asyncDispatch = useAsyncDispatch();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const isSmallScreen = useSmallScreenSize();
  const [anchorEl, setAnchorEl] = useState<Element>();
  const [popperOpen, setPopperOpen] = useState(false);
  const gradeConditions = useSelector(
    (state: RootState) => state.buildReportDefectProfileStore.byId[selectedUuid][`${grade}Grade`]
  );

  const currentOperator = get(gradeConditions, path);

  async function onChangeOperator(operator: LogicalOperator) {
    const newConditions = cloneDeep(gradeConditions);
    set(newConditions, path, operator);
    await asyncDispatch(
      buildReportDefectProfileActions.updateProfile(selectedUuid, {[`${grade}Grade`]: newConditions})
    );
    setPopperOpen(false);
  }

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        onClick={(event) => {
          setAnchorEl(event.currentTarget);
          setPopperOpen(true);
        }}
        size="small"
        style={{margin: '1rem 1rem 1rem 0px', width: isSmallScreen ? '180px' : 'auto'}}
      >
        {currentOperator?.toLocaleUpperCase()}
      </Button>

      <Popover open={popperOpen} onClose={() => setPopperOpen(false)} anchorEl={anchorEl} style={{cursor: 'pointer'}}>
        <Typography style={{padding: '8px 16px'}} onClick={() => onChangeOperator(LogicalOperator.AND)}>
          AND
        </Typography>
        <Typography style={{padding: '8px 16px'}} onClick={() => onChangeOperator(LogicalOperator.OR)}>
          OR
        </Typography>
      </Popover>
    </>
  );
}

export default BuildReportDefectProfileChangeOperator;
