import React from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {isEqual} from 'lodash';
import {Grid} from '@material-ui/core';
import {RootState} from '../../../../store/reducers/index';
import {useAsyncDispatch} from '../../../../ReduxRoot';
import sensorProfileActions from '../../../../store/actions/sensorProfileActions';
import ValidationStatusChip from './ValidationStatusChip';
import EditableHeader from '../../../../components/atoms/EditableHeader';

function ProfileName() {
  const dispatch = useAsyncDispatch();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profile = useSelector((state: RootState) => state.sensorProfileStore.byId[selectedUuid]);
  const hasUnsavedChanges = useSelector(
    (state: RootState) =>
      !isEqual(state.sensorProfileStore.originalProfiles[selectedUuid], state.sensorProfileStore.profiles[selectedUuid])
  );

  async function saveName(name: string) {
    return await dispatch(sensorProfileActions.saveProfileName(selectedUuid, name!));
  }

  return (
    <Grid container style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
      <EditableHeader
        label="Profile Name"
        name={profile.name}
        saveName={saveName}
        viewEndAdornment={
          <ValidationStatusChip
            state={profile.state}
            hasUnsavedChanges={hasUnsavedChanges}
            isNew={selectedUuid === 'new'}
          />
        }
        viewEndAdornmentSize="124px"
      />
    </Grid>
  );
}

export default ProfileName;
