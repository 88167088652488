import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {spacing} from '@material-ui/system';
import {Box, Link, Card as MuiCard, Divider as MuiDivider, Typography} from '@material-ui/core';

import {CameraFocusingState, DeviceDetailedState, DeviceState} from '@common/api/models/devices/IDevice';

import {StagingBuildDataProps} from '../';
import {useSmallScreenSize} from '../../../../utils/utilHooks';
import {RootState} from '../../../../store/reducers/index';
import {renderDateTimeString} from '../../../../utils/string';
import NoFocusResults from './FocusStep/NoFocusResults';
import DeviceFocusAlert from './FocusStep/DeviceFocusAlert';
import AutoFocusProgressBar from './FocusStep/AutoFocusProgressBar';
import AutoFocusResultsTable from './FocusStep/AutoFocusResultsTable';
import ActionButtons from './FocusStep/ActionButtons';
import ManualFocusingWidget from './FocusStep/ManualFocusingWidget';
import {Alert} from '@material-ui/lab';

const Divider = styled(MuiDivider)(spacing);

export interface CameraRow {
  id: number;
  message: string | number;
  result: 'success' | 'failure' | 'pending' | 'none';
  selected: boolean;
  timestamp: string;
}

export const ActiveStepFOCUS = ({
  build,
  isCurrentlyCalibrating,
  waitingForFocus,
  setWaitingForFocus,
  waitingForPreview,
  setWaitingForPreview,
  previewError,
  setPreviewError,
  focusingError,
  setFocusingError,
}: StagingBuildDataProps) => {
  const isSmallScreen = useSmallScreenSize();
  const device = useSelector((state: RootState) => state.deviceStore.byMachineUuid[build.machineUuid!]);
  const [cameraRows, setCameraRows] = useState<CameraRow[]>([]);
  const [didStartManualFocusing, setDidStartManualFocusing] = useState(false);
  const [internalState, setInternalState] = useState<DeviceDetailedState>(device.stateDetailed);

  const focusResults = Object.values(device?.focusResult?.cameras || {}).filter(
    (camera) => !camera.pending && !!camera.result
  );

  useEffect(() => {
    if (device && device.cameraStates) {
      const cameras = Object.keys(device.cameraStates).map((cam) => Number.parseInt(cam));
      const isAutoFocusing =
        device.state === DeviceState.FOCUSING ||
        [
          DeviceDetailedState.Focusing,
          DeviceDetailedState.FocusingStopping,
          DeviceDetailedState.FocusingStarting,
        ].includes(device.stateDetailed);
      const cameraResults = device.focusResult?.cameras;
      setCameraRows((previousCameras) =>
        mapCameraResults(cameras, cameraResults || {}, previousCameras, isAutoFocusing)
      );
    }
  }, [device]);

  useEffect(() => {
    const currentState = device.stateDetailed;

    if (currentState === internalState) return;
    if (currentState === DeviceDetailedState.Staging && internalState === DeviceDetailedState.FocusingStarting) {
      setFocusingError(true);
    }

    setInternalState(device.stateDetailed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device.stateDetailed, internalState]);

  if (!device) return <></>;

  return (
    <FocusingCard p={isSmallScreen ? 4 : 6}>
      <DeviceFocusAlert
        device={device}
        focusResults={focusResults}
        isCurrentlyCalibrating={isCurrentlyCalibrating}
        build={build}
      />

      {focusResults.length || device.state === DeviceState.FOCUSING ? (
        <>
          <Typography variant={isSmallScreen ? 'h6' : 'h5'} style={{padding: isSmallScreen ? '12px 0px' : '16px 0px'}}>
            Focusing Results
          </Typography>
          {device.state === DeviceState.FOCUSING && <AutoFocusProgressBar cameraRows={cameraRows} />}
          <AutoFocusResultsTable cameraRows={cameraRows} setCameraRows={setCameraRows} />
        </>
      ) : (
        <NoFocusResults device={device} waitingForFocus={waitingForFocus} />
      )}
      <Divider mt={isSmallScreen ? 3 : 6} style={{height: '2px'}} />
      <ActionButtons
        cameraRows={cameraRows}
        focusResults={focusResults}
        device={device}
        buildUuid={build.uuid}
        waitingForFocus={waitingForFocus}
        setWaitingForFocus={setWaitingForFocus}
        setDidStartManualFocusing={setDidStartManualFocusing}
        setWaitingForPreview={setWaitingForPreview}
        waitingForPreview={waitingForPreview}
        setFocusingError={setFocusingError}
        setPreviewError={setPreviewError}
      />
      <Box mt={4}>
        {focusingError && (
          <Alert severity="error" onClose={() => setFocusingError(false)}>
            Unable to start auto focus. <Link href={`/builds/uuid/${build.uuid}/live/alerts/`}>View device Logs</Link>{' '}
            for more information.
          </Alert>
        )}
        <ManualFocusingWidget
          buildUuid={build.uuid}
          device={device}
          didStartManualFocusing={didStartManualFocusing}
          waitingForPreview={waitingForPreview}
          previewError={previewError}
          setPreviewError={setPreviewError}
        />
      </Box>
    </FocusingCard>
  );
};

const getRowSelection = (cameraId: number, currentResult: CameraFocusingState, previousResults: CameraRow[]) => {
  const previousRow = previousResults.find((row) => row.id === cameraId);
  if (!previousRow) {
    // Loaded with failed: select
    if (!currentResult.pending && !currentResult.result.success) return true;
  } else {
    // Changed from non-pending to pending: deselect
    if (previousRow.result !== 'pending' && currentResult.pending) return false;
    // Changed from pending to failed: select
    if (previousRow.result === 'pending' && !currentResult.pending) return currentResult.result.success === false;
  }
  return previousRow?.selected || false;
};
function assessFocusQuality(
  currentCameraContrast: number,
  cameraId: number,
  cameraResults: {
    [cameraNumber: number]: CameraFocusingState;
  }
): string {
  // This assumes that Each FOV has a pair of cameras where the first camera's number is Odd
  const neighboringCameraId = cameraId % 2 === 0 ? cameraId - 1 : cameraId + 1;
  if (!neighboringCameraId) return '';

  const neighboringCamResult = cameraResults[neighboringCameraId];

  if (!neighboringCamResult || neighboringCamResult.pending || !neighboringCamResult.result.success) {
    return `Camera successfully focused. Cannot assess focus quality until Camera ${neighboringCameraId} is focused`;
  }

  const neighboringCamContrast = neighboringCamResult.result.result;

  // Intra FOV Comparison
  if (!!neighboringCamContrast) {
    let logDiff = Math.log10(currentCameraContrast) - Math.log10(neighboringCamContrast);
    if (logDiff < -0.3) {
      return 'Camera appears to be just out of focus. Please reattempt focus';
    }
  }

  return 'Camera focus is good';
}

const getRowMessage = (
  currentCameraId: number,
  cameraResults: {
    [cameraNumber: number]: CameraFocusingState;
  }
) => {
  const currentResult = cameraResults[currentCameraId];

  if (!currentResult) return '';

  if (currentResult.pending) return 'pending';
  else {
    if (currentResult.result.success) {
      return assessFocusQuality(currentResult.result.result, currentCameraId, cameraResults);
    } else {
      return currentResult.result.message;
    }
  }
};

const mapCameraResults = (
  cameras: number[],
  cameraResults: {
    [cameraNumber: number]: CameraFocusingState;
  },
  previousResults: CameraRow[],
  isAutoFocusing: boolean
): CameraRow[] => {
  const resultValues = Object.values(cameraResults);
  const allSelectedOnInit =
    !previousResults.length &&
    (!resultValues.length || Object.values(cameraResults).every((result) => !result.pending && result.result.success));

  return cameras.map((cameraId) => {
    const currentResult = cameraResults[cameraId];
    if (!currentResult)
      return {
        id: cameraId,
        message: '',
        result: isAutoFocusing ? 'pending' : 'none',
        selected: true,
        timestamp: '',
      };
    return {
      id: cameraId,
      message: getRowMessage(cameraId, cameraResults),
      result: currentResult.pending ? 'pending' : currentResult.result.success ? 'success' : 'failure',
      selected: allSelectedOnInit || getRowSelection(cameraId, currentResult, previousResults),
      timestamp: currentResult.pending ? '' : renderDateTimeString('short', currentResult.result.timestamp),
    };
  });
};

const FocusingCard = styled(styled(MuiCard)(spacing))`
  .material-index-table {
    tr:last-child {
      td {
        border: none;
      }
    }
  }

  .MuiTableCell-paddingNone {
    width: 42px;
  }
`;
