import React, {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Box} from '@material-ui/core';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {useBuildReportDefectProfileStoreActions} from '../../store/actions/index';
import {RootState} from '../../store/reducers/index';
import {useSelector} from 'react-redux';
import {FetchingState} from '../../store/model/liveUpdateStore';
import LoadingPage from '../../components/organisms/LoadingPage';
import Header from '../../components/organisms/Header';
import BuildReportDefectProfilesList from './BuildReportDefectProfilesList';
import NewProfileButton from './NewProfileButton';
import BuildReportDefectProfileBottomToolbar from './BuildReportDefectProfileBottomToolbar';
import BuildReportDefectProfileCurrent from './BuildReportDefectProfileCurrent';

function alertUser(e: any) {
  e.preventDefault();
  e.returnValue = '';
}

function BuildReportDefectProfilePage() {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const buildReportDefectProfileStoreActions = useBuildReportDefectProfileStoreActions();
  const newProfileExists = useSelector((state: RootState) => !!state.sensorProfileStore.byId['new']);
  const firstProfile = useSelector((state: RootState) => Object.values(state.buildReportDefectProfileStore.byId)?.[0]);
  const isFetchingProfiles = useSelector(
    (state: RootState) => state.buildReportDefectProfileStore.fetched === FetchingState.Fetching
  );

  useEffect(() => {
    buildReportDefectProfileStoreActions.ensureConsistent({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selectedUuid && firstProfile) {
      history.replace(`/reports/buildReport/defectProfile/${firstProfile.uuid}/`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUuid, firstProfile]);

  useEffect(() => {
    if (newProfileExists) {
      window.addEventListener('beforeunload', alertUser);
    }

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [newProfileExists]);

  if (isFetchingProfiles) return <LoadingPage loadingText="Loading Build Report Defect Profiles..." />;

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Header
        helmet="Build Report Defect Profiles"
        title="Build Report Defect Profiles"
        breadcrumbs={[{title: 'Build Reports', path: '/reports/buildReport'}, 'Defect Profiles']}
        endAdornment={isSmallScreen ? undefined : <NewProfileButton />}
      />

      <Box display="flex" flex={1}>
        {!isSmallScreen && (
          <Box height="100%" maxHeight="100%" width="400px" marginRight="24px">
            <BuildReportDefectProfilesList />
          </Box>
        )}

        <Box height="100%" width={isSmallScreen ? '100%' : 'calc(100% - 400px - 24px)'}>
          <BuildReportDefectProfileCurrent />
        </Box>
      </Box>

      {isSmallScreen && <BuildReportDefectProfileBottomToolbar />}
    </Box>
  );
}

export default BuildReportDefectProfilePage;
