import React from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import EditableHeader from '../../components/atoms/EditableHeader';
import {useAsyncDispatch} from '../../ReduxRoot';
import buildReportDefectProfileActions from '../../store/actions/buildReportDefectProfileActions';
import {RootState} from '../../store/reducers';
import {ChipStatus} from '../../components/atoms/Status/Status';

function BuildReportDefectProfileHeader() {
  const asyncDispatch = useAsyncDispatch();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profile = useSelector((state: RootState) => state.buildReportDefectProfileStore.byId[selectedUuid]);
  const hasUnsavedChanges = useSelector(
    (state: RootState) => !!state.buildReportDefectProfileStore.originals[selectedUuid]
  );

  async function saveName(name: string) {
    return await asyncDispatch(buildReportDefectProfileActions.updateProfile(selectedUuid, {name}));
  }

  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box display="flex" alignItems="center">
        <EditableHeader label="Profile Name" name={profile.name} saveName={saveName} />
      </Box>

      {hasUnsavedChanges && <ChipStatus chipSize="small" message="Unsaved" variant="none" />}
    </Box>
  );
}

export default BuildReportDefectProfileHeader;
