import React, {useState} from 'react';
import {Delete, CloudDownload} from '@material-ui/icons';
import {Box, CircularProgress, IconButton, Tooltip, Typography} from '@material-ui/core';
import {EllipsisTextColumnTooltip} from './sharedColumns';
import {downloadUrl} from '../../../../utils/webtools';
import {IPartModelAttachmentGETResponse} from '@common/api/models/attachments/IPartModelAttachment';
import {
  partModelAttachmentByUuidDELETE,
  partModelAttachmentDownloadUrlGET,
} from '../../../../api/ajax/partModelAttachments';

export interface IPartModelAttachmentRow extends IPartModelAttachmentGETResponse {
  onRowDelete: () => void;
}

const partModelAttachmentTableSchema = [
  {
    title: 'Filename',
    field: 'filename',
    width: 'auto',
    render: (row: IPartModelAttachmentRow) =>
      row.filename && <EllipsisTextColumnTooltip>{row.filename}</EllipsisTextColumnTooltip>,
  },
  {
    title: 'PartName',
    field: 'partName',
    width: 'auto',
    render: (row: IPartModelAttachmentRow) =>
      row.filename && <EllipsisTextColumnTooltip>{row.partName}</EllipsisTextColumnTooltip>,
  },
  {
    title: 'Actions',
    width: '165px',
    sorting: false,
    render: (rowData: IPartModelAttachmentRow) => <PartModelAttachmentActions rowData={rowData} />,
  },
];

export default partModelAttachmentTableSchema;

const PartModelAttachmentActions = ({rowData}: {rowData: IPartModelAttachmentRow}) => {
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const isDeleted = !!rowData.deletedAt;

  const onDownload = async () => {
    setDownloading(true);
    const url = await partModelAttachmentDownloadUrlGET(rowData.uuid);
    if (url.success) downloadUrl(url.data, rowData.filename);
    setDownloading(false);
  };

  const deleteModel = async () => {
    setDeleting(true);
    await partModelAttachmentByUuidDELETE(rowData.uuid);
    rowData.onRowDelete();
    setDeleting(false);
  };

  return (
    <Box display="flex" justifyContent="flex-end">
      <Tooltip title={<Typography>{isDeleted ? 'File Deleted' : 'Download'}</Typography>}>
        <span>
          <IconButton aria-label="download" onClick={onDownload} disabled={isDeleted} style={{padding: '0px 6px'}}>
            {downloading ? <CircularProgress size={20} /> : <CloudDownload />}
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title={<Typography>{isDeleted ? 'File Deleted' : 'Remove'}</Typography>}>
        <span>
          <IconButton
            aria-label="delete"
            onClick={deleteModel}
            disabled={isDeleted || deleting}
            style={{padding: '0px 6px'}}
          >
            {deleting ? <CircularProgress size={20} /> : <Delete />}
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
};
