import axios from 'axios';
import * as qs from 'qs';
import {IBuildReportDefectProfile} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {AjaxApiResult, ajaxWrapper} from './ajaxWrapper';
import {QueryFilter} from '../../store/actions/liveUpdateStore';

export async function buildReportDefectProfileAllGET(
  filter: QueryFilter<IBuildReportDefectProfile>
): AjaxApiResult<IBuildReportDefectProfile[]> {
  return ajaxWrapper(() => {
    const query = qs.stringify(filter, {arrayFormat: 'brackets'});
    return axios.get('/api/reports/buildReportDefectProfiles/' + (query && '?' + query));
  }, 'Could not fetch build report defect profiles');
}

export async function buildReportDefectProfilePOST(
  buildReportDefectProfile: Partial<IBuildReportDefectProfile>
): AjaxApiResult<IBuildReportDefectProfile> {
  return ajaxWrapper(() => {
    return axios.post('/api/reports/buildReportDefectProfiles/', buildReportDefectProfile);
  }, 'Could not create build report defect profile');
}

export async function buildReportDefectProfilePATCH(
  uuid: string,
  changes: Partial<IBuildReportDefectProfile>
): AjaxApiResult<IBuildReportDefectProfile> {
  return ajaxWrapper(() => {
    return axios.patch(`/api/reports/buildReportDefectProfiles/${uuid}`, {...changes});
  }, 'Could not update build report defect profile');
}

export async function buildReportDefectProfileDELETE(uuid: string): AjaxApiResult<void> {
  return ajaxWrapper(() => {
    return axios.delete(`/api/reports/buildReportDefectProfiles/${uuid}`);
  }, 'Could not delete build report defect profile');
}
