import React from 'react';
import {
  Typography,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Grid,
  Link,
  Tooltip,
  IconButton,
  Box,
  Radio,
} from '@material-ui/core';
import styled from 'styled-components';

import {Search} from '@material-ui/icons';
import {AnalysisType3D, getPrettyAnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';

import {ViewportSidebarProps} from './View3DViewportSidebar';
import {View3DViewportParams} from '../View3DViewport';

export default function PointCloudPicker(props: ViewportSidebarProps<View3DViewportParams>) {
  return (
    <>
      {!props.hidePartSelection && <PartSelection {...props} />}
      <AnalysisTypeSelection {...props} />
    </>
  );
}

const PartSelection = (props: ViewportSidebarProps<View3DViewportParams>) => {
  const selectedPartUuids = props.params.selectedParts.map((part) => part.uuid);

  // Sub-sort by uuid to handle conflicting names and preserve correct sorting
  const comparePartNames = (a: IPartGETResponse, b: IPartGETResponse) =>
    `${a.name}-${a.uuid}` > `${b.name}-${b.uuid}` ? 1 : -1;

  const onPartSelected = (checked: boolean, part: IPartGETResponse) => {
    const matchingPartModel = props.params.availablePartModels?.find((partModel) => partModel.partUuid === part.uuid);

    props.setParams({
      ...props.params,
      selectedParts: checked
        ? props.params.partModelRotationMode
          ? [part]
          : [...props.params.selectedParts, part]
        : props.params.selectedParts.filter((p) => p.uuid !== part.uuid),
      selectedPartModels: props.params.partModelRotationMode
        ? matchingPartModel
          ? [matchingPartModel]
          : []
        : props.params.selectedPartModels,
    });
  };

  return (
    <Grid item style={{width: 'inherit'}}>
      <Typography>Part selection</Typography>
      <PartListContainer>
        <div style={{paddingBottom: 5}}>
          {props.params.selectedParts.length}/{props.params.availableParts.length} parts selected.{' '}
          <Link href="#" onClick={() => props.setParams({...props.params, selectedParts: []})}>
            Deselect all
          </Link>
        </div>
        {props.params.availableParts.length > 0 ? (
          <PartsFormGroup>
            {props.params.availableParts.sort(comparePartNames).map((part) => {
              const notAvailableForRotation =
                props.params.partModelRotationMode &&
                !props.params.availablePartModels?.some((model) => model.partUuid === part.uuid);

              return (
                <Grid
                  key={`${part.name}-${part.uuid}`}
                  container
                  direction="row"
                  justifyContent="space-between"
                  style={{
                    maxWidth: '100%',
                    flexWrap: 'nowrap',
                    borderRadius: 5,
                    backgroundColor: props.params.hoveredPartUuid === part.uuid ? '#222' : undefined,
                  }}
                  onMouseEnter={() => {
                    // Only hover selected parts
                    if (!selectedPartUuids.includes(part.uuid)) return;
                    props.setParams({
                      ...props.params,
                      hoveredPartUuid: part.uuid,
                    });
                  }}
                  onMouseLeave={() => {
                    // Make sure we don't remove the hover if another part is already hovered
                    if (props.params.hoveredPartUuid !== part.uuid) return;
                    props.setParams({
                      ...props.params,
                      hoveredPartUuid: undefined,
                    });
                  }}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    style={{height: '22px', paddingLeft: '5px', marginLeft: '-8px'}}
                  >
                    {' '}
                    {props.params.partModelRotationMode ? (
                      <Radio
                        checked={selectedPartUuids.includes(part.uuid)}
                        onChange={(_, checked) => onPartSelected(checked, part)}
                        color="primary"
                        size="small"
                        style={{height: '100%', padding: '4px'}}
                        disabled={props.viewportState === 'loading' || notAvailableForRotation}
                      />
                    ) : (
                      <Checkbox
                        checked={selectedPartUuids.includes(part.uuid)}
                        onChange={(_, checked) => onPartSelected(checked, part)}
                        color="primary"
                        size="small"
                        style={{height: '100%', padding: '4px'}}
                        disabled={props.viewportState === 'loading'}
                      />
                    )}
                    {props.params.selectedParts.includes(part) && (
                      <Tooltip title="Centre camera on this part">
                        <IconButton
                          size="small"
                          onClick={() => props.centreCameraOnPart?.(part.uuid)}
                          style={{marginRight: '4px'}}
                        >
                          <Search fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title={<Typography>{part.name}</Typography>}>
                      <Box
                        onClick={() =>
                          notAvailableForRotation ? null : onPartSelected(!selectedPartUuids.includes(part.uuid), part)
                        }
                        style={{cursor: notAvailableForRotation ? 'default' : 'pointer'}}
                      >
                        <Typography>{part.name}</Typography>
                      </Box>
                    </Tooltip>
                  </Box>
                </Grid>
              );
            })}
          </PartsFormGroup>
        ) : (
          'No parts available.'
        )}
      </PartListContainer>
    </Grid>
  );
};

const AnalysisTypeSelection = (props: ViewportSidebarProps<View3DViewportParams>) => {
  const availableAnalysisTypes = Object.values(AnalysisType3D).filter(
    (type) => props.params.availableAnalysisTypes[type]
  );

  return (
    <Grid item style={{width: 'inherit'}}>
      <Typography>Analysis type selection</Typography>
      <div
        style={{
          maxHeight: 400,
          overflowX: 'scroll',
          padding: 10,
          margin: '10px 0 10px 0',
          border: '2px solid gray',
          whiteSpace: 'nowrap',
        }}
      >
        <FormGroup>
          {!props.params.partModelRotationMode && availableAnalysisTypes.length > 0 ? (
            availableAnalysisTypes.map((type) => (
              <FormControlLabel
                key={type}
                control={
                  <Checkbox
                    checked={props.params.selectedAnalysisTypes[type]}
                    onChange={(_, checked) => {
                      const newAnalysisTypes = {
                        ...props.params.selectedAnalysisTypes,
                        [type]: checked,
                      };
                      const defectTypeSelected = Object.entries(newAnalysisTypes).some(
                        ([key, selected]) =>
                          ![AnalysisType3D.Model, AnalysisType3D.PartModel].includes(key as AnalysisType3D) && selected
                      );
                      props.setParams({
                        ...props.params,
                        selectedAnalysisTypes: newAnalysisTypes,
                        modelOpacity: defectTypeSelected ? 0.1 : props.params.modelOpacity,
                      });
                    }}
                    color="primary"
                    size="small"
                    style={{height: '100%'}}
                  />
                }
                label={getPrettyAnalysisType3D(type)}
                style={{height: '1.5em'}}
                disabled={props.viewportState === 'loading'}
              />
            ))
          ) : props.params.selectedParts.length > 0 ? (
            'No analysis types available'
          ) : (
            // Disabled mock checkbox for before user selects part
            <FormControlLabel
              control={<Checkbox checked disabled color="primary" size="small" />}
              label="Model"
              style={{height: '1.5em'}}
            />
          )}
        </FormGroup>
      </div>
    </Grid>
  );
};

const PartListContainer = styled.div`
  max-height: 400px;
  overflow-x: scroll;
  padding: 10px;
  margin: 10px 0 10px 0;
  border: 2px solid gray;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
`;

const PartsFormGroup = styled(FormGroup)<{height?: string}>`
  max-height: ${({height}) => height || '350px'};
  overflow-y: auto;
  overflow-x: auto;
  flex-wrap: nowrap;
  padding-right: 4px;
  padding: 10px 4px 10px 0px;
`;
