import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';

import {Color, InstancedMesh, Float32BufferAttribute, Scene} from 'three';
import {PartPointClouds} from '../PartPointCloud';
import {debounce} from 'lodash';
import {AnalysisTypeMap, ExplicitPoint, GetBlockColorFn, ThreePoints} from '../types/pointCloudTypes';
import {loadInstancedMeshMatrices} from '../loaders/meshLoaders';

export const onBackgroundColourChange = (backgroundColour: string, renderScene: () => void, scene: Scene) => {
  if (!scene) return;
  scene.background = new Color(backgroundColour);
  renderScene();
};

export const updatePointSize = debounce(
  (
    pointSize: number,
    pointClouds: PartPointClouds,
    renderScene: () => void,
    shouldDisplayFn?: (point: ExplicitPoint, analysisType: AnalysisType3D) => boolean,
    getBlockColour?: GetBlockColorFn,
    analysisTypeColorOverrides?: Partial<AnalysisTypeMap<string>>
  ) => {
    pointClouds.forEachPointCloud((partModel) => {
      if (partModel.userData.isPartModel) return;

      if (partModel.userData.isSimilarityPartPointCloud) {
        (partModel as ThreePoints).material.size = pointSize / 4;
      } else if (partModel.type === 'Points') {
        (partModel as ThreePoints).material.size = pointSize;

        // Apply defect area filter
        if (shouldDisplayFn && partModel.userData.analysisType !== 'model') {
          // Save out the original positions if we haven't already. These are needed as positioning can be lost.
          if (!partModel.userData.originalPositions) {
            const originalPositions = Array.from((partModel as ThreePoints).geometry.getAttribute('position').array);
            partModel.userData.originalPositions = originalPositions;
          }

          const values: {colors: Array<number>; positions: Array<number>} = {colors: [], positions: []};

          partModel.userData.pointData.forEach((point: ExplicitPoint, index: number) => {
            if (shouldDisplayFn(point, partModel.userData.analysisType as AnalysisType3D)) {
              if (
                analysisTypeColorOverrides &&
                analysisTypeColorOverrides[partModel.userData.analysisType as AnalysisType3D]
              ) {
                const overrideColor = new Color(
                  analysisTypeColorOverrides[partModel.userData.analysisType as AnalysisType3D]
                );
                values.colors.push(overrideColor.r, overrideColor.g, overrideColor.b);
              } else {
                values.colors.push(...point.colour.toArray());
              }
              values.positions.push(
                partModel.userData.originalPositions[index * 3],
                partModel.userData.originalPositions[index * 3 + 1],
                partModel.userData.originalPositions[index * 3 + 2]
              );
            }
          });

          (partModel as ThreePoints).geometry.setAttribute('color', new Float32BufferAttribute(values.colors, 3));
          (partModel as ThreePoints).geometry.setAttribute('position', new Float32BufferAttribute(values.positions, 3));
        }
      } else {
        const partModelMesh = partModel as InstancedMesh;
        loadInstancedMeshMatrices(
          partModelMesh,
          partModel.userData.pointData,
          pointSize,
          partModel.userData.analysisType as AnalysisType3D,
          shouldDisplayFn,
          getBlockColour,
          partModel.userData.isSimilarityComparison
        );
      }
    });
    renderScene();
  },
  50
);

export const updateModelOpacity = (modelOpacity: number, pointClouds: PartPointClouds, renderScene: () => void) => {
  pointClouds.forEachPointCloud((partModel) => ((partModel as ThreePoints).material.opacity = modelOpacity), {
    analysisTypes: [AnalysisType3D.Model, AnalysisType3D.PartModel],
  });
  renderScene();
};

export const updatePointColourOverride = (
  doOverride: boolean,
  overrideColour: string,
  pointClouds: PartPointClouds,
  is3dPoints: boolean,
  renderScene: () => void,
  overrideAnalysisTypeColors?: boolean,
  analysisTypeColorOverrides?: Partial<AnalysisTypeMap<string>>
) => {
  pointClouds.forEachPointCloud((partModel) => {
    if (partModel.userData.isSimilarityPartPointCloud) return;
    if (
      ![AnalysisType3D.Model, AnalysisType3D.CTModel, AnalysisType3D.PartModel].includes(
        partModel.userData.analysisType
      )
    )
      return;
    // analysisTypeColorOverrides for now only works with ctModel
    // For CT Point Clouds, If overridden, uses the Override Colour, uses white otherwise
    // In Any Case, CT Point Clouds will not be Overridden by doOverride (model override)
    if (
      overrideAnalysisTypeColors &&
      analysisTypeColorOverrides &&
      analysisTypeColorOverrides.ctModel &&
      partModel.userData.analysisType === AnalysisType3D.CTModel
    ) {
      (partModel as ThreePoints).material.vertexColors = false;
      (partModel as ThreePoints).material.color = new Color(analysisTypeColorOverrides.ctModel);
    } else if (doOverride && partModel.userData.analysisType !== AnalysisType3D.CTModel) {
      (partModel as ThreePoints).material.vertexColors = false;
      (partModel as ThreePoints).material.color = new Color(overrideColour);
    } else {
      // Vertex colour multiplies the base material colour rather than replacing it.
      // So, we need to set the material colour back to white.
      (partModel as ThreePoints).material.color = new Color(0xffffff);
      (partModel as ThreePoints).material.vertexColors = is3dPoints || partModel.userData.isPartModel ? false : true;
    }
    (partModel as ThreePoints).material.needsUpdate = true;
  });
  renderScene();
};

export const updatePointCloudHighlight = (
  highlightedPartUuid: string | undefined,
  overridePointColour: boolean,
  overrideColour: string,
  pointClouds: PartPointClouds,
  is3dPoints: boolean,
  renderScene: () => void,
  overrideAnalysisTypeColors?: boolean,
  analysisTypeColorOverrides?: Partial<AnalysisTypeMap<string>>
) => {
  // Clear any existing highlights by applying colour override rules
  updatePointColourOverride(
    overridePointColour,
    overrideColour,
    pointClouds,
    is3dPoints,
    () => {}, // No need to render yet
    overrideAnalysisTypeColors,
    analysisTypeColorOverrides
  );
  if (highlightedPartUuid) {
    pointClouds.forEachPointCloud((partModel) => {
      if (
        partModel.userData.partUuid === highlightedPartUuid &&
        [AnalysisType3D.Model, AnalysisType3D.PartModel].includes(partModel.userData.analysisType)
      ) {
        (partModel as ThreePoints).material.color = new Color(0xffa500); // orange
        (partModel as ThreePoints).material.vertexColors = false;
        (partModel as ThreePoints).material.needsUpdate = true;
      }
    });
  }
  renderScene();
};
