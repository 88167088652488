import {IBuildReportDefectProfile} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {LiveStoreState, createInitialLiveStore} from './liveUpdateStore';

export const PROFILE_IGNORE_SECTIONS = ['device_id', 'type', 'version', 'loaded_from'];

export enum BuildReportDefectProfileActions {
  UPDATE_BUILD_REPORT_DEFECT_PROFILE = 'UPDATE_BUILD_REPORT_DEFECT_PROFILE',
  CLONE_BUILD_REPORT_DEFECT_PROFILE = 'CLONE_BUILD_REPORT_DEFECT_PROFILE',
  REMOVE_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE = 'REMOVE_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE',
  REVERT_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE = 'REVERT_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE',
}

export interface BuildReportDefectProfileStoreState extends LiveStoreState<IBuildReportDefectProfile> {
  originals: {[uuid: string]: IBuildReportDefectProfile};
}

export const initialBuildReportDefectProfileState: BuildReportDefectProfileStoreState = {
  ...createInitialLiveStore(),
  originals: {},
};

interface BuildReportDefectProfileActionType<_T, P> {
  type: BuildReportDefectProfileActions;
  payload: P;
}

export type UpdateProfileAction = BuildReportDefectProfileActionType<
  BuildReportDefectProfileActions.UPDATE_BUILD_REPORT_DEFECT_PROFILE,
  {profile: IBuildReportDefectProfile; uuid: string}
>;

export type CloneProfileAction = BuildReportDefectProfileActionType<
  BuildReportDefectProfileActions.CLONE_BUILD_REPORT_DEFECT_PROFILE,
  {profile: IBuildReportDefectProfile}
>;

export type RemoveUnsavedChangesAction = BuildReportDefectProfileActionType<
  BuildReportDefectProfileActions.REMOVE_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE,
  {uuid: string}
>;

export type RevertUnsavedChangesAction = BuildReportDefectProfileActionType<
  BuildReportDefectProfileActions.REVERT_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE,
  {uuid: string}
>;
