import React, {useEffect, useState} from 'react';
import {Box, Button, CircularProgress, Tooltip, Typography} from '@material-ui/core';
import styled from 'styled-components';
import {CTDefectType3D} from '@common/api/models/builds/data/defects/IDefect';
import {CommonCTStepProps} from './CTSteps';
import CTResultsViewport from '../results/CTResultsViewport';
import {useContainerHeight, useSmallScreenSize} from '../../../utils/utilHooks';
import CTDefectsTable from '../results/CTDefectsTable';
import CTDefectFilters from '../results/CTDefectFilters';
import {CTFiltersMap} from '../../../components/molecules/Viewport/3D/types/pointCloudTypes';
import {objMapValues} from '../../../utils/objectFunctions';
import CTResultsBottomToolbar from '../results/CTResultsBottomToolbar';
import {ctReportDownloadStatsCsvURLsGET} from '../../../api/ajax/ctReport';
import {downloadUrl} from '../../../utils/webtools';
import {toast} from 'react-toastify';
import {Check, FileCopy, GetApp} from '@material-ui/icons';

function CTResultsStep({currentCTReport}: CommonCTStepProps) {
  const isSmallScreen = useSmallScreenSize();
  const [activeDefectId, setActiveDefectId] = useState<number | null>(null);
  const [filterRanges, setFilterRanges] = useState<CTFiltersMap>(
    objMapValues(CTDefectType3D, () => ({})) as CTFiltersMap
  );
  const [ctFilters, setCtFilters] = useState<CTFiltersMap>(objMapValues(CTDefectType3D, () => ({})) as CTFiltersMap);
  const [csvRequesting, setCsvRequesting] = useState(false);
  const {height, setContainerRef} = useContainerHeight();

  const handleCsvDownload = async () => {
    setCsvRequesting(true);
    const url = await ctReportDownloadStatsCsvURLsGET(currentCTReport.uuid);
    if (url.success) {
      downloadUrl(url.data.url, `${currentCTReport.partName}-ct-report-stats.csv`);
    }
    setCsvRequesting(false);
  };

  return (
    <Box height="100%" minHeight="700px">
      <Container ref={setContainerRef} isSmallScreen={isSmallScreen}>
        <CTResultsViewport
          ctReport={currentCTReport}
          stageHeight={height}
          activeDefectId={activeDefectId}
          ctFilters={ctFilters}
        />
      </Container>
      <Box display="flex" justifyContent="flex-end">
        {activeDefectId && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActiveDefectId(null)}
            fullWidth={isSmallScreen}
            style={{marginBottom: '8px'}}
          >
            Clear Active Defect
          </Button>
        )}

        <CopyTransformationMatrixToClipboard transformationMatrix={currentCTReport.transformationMatrix} />

        <Button
          variant="contained"
          color="primary"
          onClick={handleCsvDownload}
          style={{marginLeft: '8px', marginBottom: '8px'}}
          disabled={csvRequesting}
          fullWidth={isSmallScreen}
          endIcon={<GetApp />}
        >
          Download Stats CSV {csvRequesting && <CircularProgress size={20} style={{marginLeft: '12px'}} />}
        </Button>
      </Box>
      {!isSmallScreen && (
        <CTDefectFilters ctFilterRanges={filterRanges} ctFilters={ctFilters} setCtFilters={setCtFilters} />
      )}
      {isSmallScreen && (
        <CTResultsBottomToolbar ctFilterRanges={filterRanges} ctFilters={ctFilters} setCtFilters={setCtFilters} />
      )}
      <CTDefectsTable
        currentCTReport={currentCTReport}
        setActiveDefectId={setActiveDefectId}
        setFilterRanges={setFilterRanges}
        ctFilters={ctFilters}
        activeDefectId={activeDefectId}
      />
    </Box>
  );
}

export default CTResultsStep;

const Container = styled.div<{isSmallScreen: boolean}>`
  /* Don't ask how this works, just don't change it or the screen grows infinitely */
  height: calc(100% - 14px);
  min-height: 400px;
  max-height: ${({isSmallScreen}) => (isSmallScreen ? '450px' : '800px')};
  margin-bottom: 32px;
`;

function CopyTransformationMatrixToClipboard({transformationMatrix}: {transformationMatrix: number[]}) {
  const isSmallScreen = useSmallScreenSize();
  const [copied, setCopied] = useState(false);

  function arrayToMatrix(): number[][] {
    if (transformationMatrix.length !== 16) {
      throw new Error('Array must have exactly 16 elements');
    }

    const matrix: number[][] = [];
    for (let i = 0; i < 4; i++) {
      matrix.push(transformationMatrix.slice(i * 4, i * 4 + 4));
    }

    return matrix;
  }

  const onCopy = async () => {
    if (!navigator.clipboard) {
      toast('Clipboard unavailable.', {type: 'error'});
      return;
    }
    try {
      const matrixString = JSON.stringify(arrayToMatrix())
        .replace(/\],\[/g, '],\n  [')
        .replace(/\[\[/, '[\n  [')
        .replace(/\]\]/, ']\n]');
      // .replace(/\n\s+\]$/g, ']]');
      await navigator.clipboard.writeText(matrixString);
      setCopied(true);
    } catch {
      toast('Failed to copy to clipboard.', {type: 'error'});
    }
  };

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied]);

  return (
    <Tooltip title={<Typography>Copied to clipboard!</Typography>} open={copied}>
      <Button
        variant="contained"
        color="primary"
        onClick={onCopy}
        style={{marginLeft: '8px', marginBottom: '8px'}}
        fullWidth={isSmallScreen}
        endIcon={copied ? <Check /> : <FileCopy />}
      >
        Copy Transformation Matrix
      </Button>
    </Tooltip>
  );
}
