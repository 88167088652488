import React from 'react';
import {Menu} from '@material-ui/icons';
import {Box, Button, Drawer, makeStyles} from '@material-ui/core';
import EllipsisTextWithTooltip from '../../components/atoms/Texts/EllipsisTextWithTooltip';
import BottomToolbarContainer from '../../components/atoms/BottomToolbarContainer';
import {useMobileDrawer} from '../../utils/utilHooks';
import NewReportButton from './NewReportButton';
import LocationBasedDefectsList from './LocationBasedDefectsList';

function LocationBasedDefectsBottomToolbar() {
  const drawerStyles = useDrawerStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useMobileDrawer();

  return (
    <BottomToolbarContainer columns="80% 20%">
      <Button
        variant="text"
        color="secondary"
        onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        style={{justifySelf: 'self-start', width: '100%'}}
      >
        <Menu />
        <Box ml={3} width="calc(100% - 32px)">
          <EllipsisTextWithTooltip align="left" style={{width: '100%'}}>
            Report List
          </EllipsisTextWithTooltip>
        </Box>
      </Button>

      <NewReportButton />

      <Drawer anchor="bottom" open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} classes={drawerStyles}>
        <Box height="calc(100vh - 64px)">
          <LocationBasedDefectsList setIsDrawerOpen={setIsDrawerOpen} />
        </Box>
      </Drawer>
    </BottomToolbarContainer>
  );
}

export default LocationBasedDefectsBottomToolbar;

const useDrawerStyles = makeStyles({
  paperAnchorBottom: {
    bottom: '4rem !important',
  },
  root: {
    '& .MuiBackdrop-root': {
      bottom: '4rem !important',
    },
  },
  modal: {
    zIndex: '1298 !important' as any,
  },
});
