import React from 'react';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';
import {Box, Grid} from '@material-ui/core';
import {ConditionGroup, Units} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {DefectType} from '@common/api/models/builds/data/defects/IDefect';
import {LogicalOperator} from '@common/api/models/builds/data/defects/IDefectNotifications';
import BuildReportDefectProfileGradeSingleCondition from './BuildReportDefectProfileSingleCondition';
import BuildReportDefectProfileChangeOperator from './BuildReportDefectProfileChangeOperator';
import BuildReportDefectProfileAddCondition from './BuildReportDefectProfileAddCondition';
import BuildReportDefectProfileAddConditionGroup from './BuildReportDefectProfileAddConditionGroup';
import BuildReportDefectProfileRemoveCondition from './BuildReportDefectProfileRemoveCondition';
import {RootState} from '../../store/reducers';
import {grayColor} from '../../assets/jss/material-dashboard-react';

interface BuildReportDefectProfileConditionGroupProps {
  grade: 'a' | 'b' | 'c';
  conditionGroup: ConditionGroup;
  conditionGroupIndex: number;
  onChange: (path: string, value: number | Units | DefectType | LogicalOperator | null) => void;
}

function BuildReportDefectProfileConditionGroup({
  grade,
  conditionGroup,
  conditionGroupIndex,
  onChange,
}: BuildReportDefectProfileConditionGroupProps) {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const gradeConditions = useSelector(
    (state: RootState) => state.buildReportDefectProfileStore.byId[selectedUuid][`${grade}Grade`]
  );

  return (
    <Grid item xs={12} container spacing={2}>
      {conditionGroupIndex !== 0 && (
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <BuildReportDefectProfileChangeOperator grade={grade} path="operator" />
        </Box>
      )}

      <ConditionGroupTopBorder />

      {conditionGroup.conditions.map((condition, conditionIndex) => {
        return (
          <Grid item xs={12} key={`${grade}-${conditionGroupIndex}-${conditionIndex}`}>
            <Box display="flex" alignItems="center" flexWrap="wrap">
              {conditionIndex !== 0 && (
                <BuildReportDefectProfileChangeOperator
                  grade={grade}
                  path={`conditionGroups[${conditionGroupIndex}].operator`}
                />
              )}

              <BuildReportDefectProfileGradeSingleCondition
                threshold={get(condition, `threshold`)}
                unit={get(condition, `unit`)}
                defectType={get(condition, `defectType`)}
                onChange={(field, value) =>
                  onChange(`conditionGroups[${conditionGroupIndex}].conditions[${conditionIndex}].${field}`, value)
                }
              />

              {!(conditionGroupIndex === 0 && conditionIndex === 0) && (
                <BuildReportDefectProfileRemoveCondition
                  grade={grade}
                  path={`conditionGroups[${conditionGroupIndex}].conditions`}
                  conditionIndex={conditionIndex}
                  conditionGroupIndex={conditionGroupIndex}
                />
              )}
              {conditionIndex === conditionGroup.conditions.length - 1 && (
                <BuildReportDefectProfileAddCondition
                  grade={grade}
                  path={`conditionGroups[${conditionGroupIndex}].conditions`}
                />
              )}
            </Box>
          </Grid>
        );
      })}

      <ConditionGroupBottomBorder />

      {conditionGroupIndex === gradeConditions.conditionGroups.length - 1 && (
        <Box width="100%" display="flex" alignItems="center" justifyContent="center">
          <BuildReportDefectProfileAddConditionGroup grade={grade} />
        </Box>
      )}
    </Grid>
  );
}

export default BuildReportDefectProfileConditionGroup;

const ConditionGroupTopBorder = styled(Box)`
  height: 20px;
  width: 100%;
  margin-top: 10px;
  border-top: 3px solid ${grayColor[12]};
  border-radius: 10% / 50%;
`;

const ConditionGroupBottomBorder = styled(Box)`
  height: 20px;
  width: 100%;
  padding-top: 10px;
  border-bottom: 3px solid ${grayColor[12]};
  border-radius: 10% / 50%;
`;
