import {generateValidationResult} from '@common/api/types';
import {DefectType, FILTERABLE_DEFECT_TYPES} from './defects/IDefect';
import {LogicalOperator} from './defects/IDefectNotifications';

export interface IBuildReportDefectProfile {
  uuid: string;
  name: string;
  organizationUuid: string;
  aGrade: IBuildReportDefectProfileConditions;
  bGrade: IBuildReportDefectProfileConditions;
  cGrade: IBuildReportDefectProfileConditions;
}

export interface IBuildReportDefectProfileConditions {
  consecutiveLayers: number;
  operator: LogicalOperator;
  conditionGroups: ConditionGroup[];
}

export interface ConditionGroup {
  operator: LogicalOperator;
  conditions: SingleCondition[];
}

export interface SingleCondition {
  defectType: DefectType;
  unit: Units;
  threshold: number;
}

export enum Units {
  AREA = 'mm²',
  COVERAGE = '%',
  VOLUME = 'mm³',
}

export const DEFAULT_BUILD_REPORT_DEFECT_PROFILE: Partial<IBuildReportDefectProfile> = {
  uuid: 'new',
  name: 'New Profile',
  aGrade: {
    consecutiveLayers: 3,
    operator: LogicalOperator.AND,
    conditionGroups: [
      {
        operator: LogicalOperator.AND,
        conditions: [
          {
            defectType: DefectType.Lsdd,
            unit: Units.VOLUME,
            threshold: 0.06,
          },
        ],
      },
    ],
  },
  bGrade: {
    consecutiveLayers: 3,
    operator: LogicalOperator.AND,
    conditionGroups: [
      {
        operator: LogicalOperator.AND,
        conditions: [
          {
            defectType: DefectType.Lsdd,
            unit: Units.VOLUME,
            threshold: 0.2,
          },
        ],
      },
    ],
  },
  cGrade: {
    consecutiveLayers: 3,
    operator: LogicalOperator.AND,
    conditionGroups: [
      {
        operator: LogicalOperator.AND,
        conditions: [
          {
            defectType: DefectType.Lsdd,
            unit: Units.VOLUME,
            threshold: 0.5,
          },
        ],
      },
    ],
  },
};

export function validateBuildReportDefectConditions(buildReportDefectProfile: IBuildReportDefectProfile) {
  const failureMessages: string[] = [];

  if (!buildReportDefectProfile.name) {
    failureMessages.push('Profile Name is required');
  }

  const {aGrade, bGrade, cGrade} = buildReportDefectProfile;

  failureMessages.push(...validateGradeConditions('A', aGrade));
  failureMessages.push(...validateGradeConditions('B', bGrade));
  failureMessages.push(...validateGradeConditions('C', cGrade));

  return generateValidationResult(failureMessages.length === 0, failureMessages);
}

function validateGradeConditions(grade: 'A' | 'B' | 'C', gradeConditions: IBuildReportDefectProfileConditions) {
  const failureMessages: string[] = [];

  if (!gradeConditions.consecutiveLayers) {
    failureMessages.push(`${grade} Grade consecutive Layers is required`);
  }

  if (gradeConditions.consecutiveLayers < 1) {
    failureMessages.push(`${grade} Grade consecutive Layers must be at least 1`);
  }

  if (!gradeConditions.conditionGroups.length) {
    failureMessages.push(`${grade} Grade requires at least one condition group`);
  }

  if (!gradeConditions.operator) {
    failureMessages.push(`${grade} Grade must have an operator`);
  }

  gradeConditions.conditionGroups.forEach((conditionGroup, conditionGroupIndex) => {
    if (!conditionGroup.operator) {
      failureMessages.push(`${grade} Grade condition group ${conditionGroupIndex + 1} must have an operator`);
    }
    if (!conditionGroup.conditions.length) {
      failureMessages.push(
        `${grade} Grade condition group ${conditionGroupIndex + 1} must have at least one condition`
      );
    }

    conditionGroup.conditions.forEach((condition, conditionIndex) => {
      if (!condition.defectType) {
        failureMessages.push(
          `${grade} Grade condition ${conditionIndex + 1} in Group ${conditionGroupIndex + 1} must have a Defect Type`
        );
      } else if (!FILTERABLE_DEFECT_TYPES.includes(condition.defectType)) {
        failureMessages.push(
          `${grade} Grade condition ${conditionIndex + 1} in Group ${
            conditionGroupIndex + 1
          } must have a valid Defect Type`
        );
      }

      if (!condition.unit) {
        failureMessages.push(
          `${grade} Grade condition ${conditionIndex + 1} in Group ${conditionGroupIndex + 1} must have a Unit`
        );
      } else if (![Units.AREA, Units.COVERAGE, Units.VOLUME].includes(condition.unit)) {
        failureMessages.push(
          `${grade} Grade condition ${conditionIndex + 1} in Group ${conditionGroupIndex + 1} must have a valid Unit`
        );
      }

      if (!condition.threshold) {
        failureMessages.push(
          `${grade} Grade condition ${conditionIndex + 1} in Group ${conditionGroupIndex + 1} must have a Threshold`
        );
      } else if (isNaN(condition.threshold) || condition.threshold <= 0) {
        failureMessages.push(
          `${grade} Grade condition ${conditionIndex + 1} in Group ${
            conditionGroupIndex + 1
          } must have be positive number`
        );
      }
    });
  });

  return failureMessages;
}
