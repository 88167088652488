import React, {useMemo, useEffect} from 'react';
import styled from 'styled-components';
import {Menu, MenuOpen} from '@material-ui/icons';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import {max} from 'lodash';
import {Stage} from 'react-konva';

import {ViewerButton} from '../../FadingComponents';
import {SidebarContainer} from '../../SidebarContainer';
import {BUTTON_HEIGHT, BUTTON_MARGIN, calculateImageOffset, transformPartBoundsToImagePosition} from '../utils';
import {grayColor} from '../../../../../assets/jss/material-dashboard-react';
import {Tooltip, Typography} from '@material-ui/core';
import ConditionalTooltip from '../../../../atoms/Texts/ConditionalTooltip';

interface PartSelectorProps {
  stageHeight: number;
  parts: IPartGETResponse[];
  onPartHover?: (part: IPartGETResponse | null) => void;
  hoveredViewportPart?: IPartGETResponse | null;
  sidebarOpen: boolean;
  setSidebarOpen: (open: boolean) => void;
  currentLayer?: number;
  imageWidthMM: number;
  imageHeightMM: number;
  plateBoundingBox?: [number, number, number, number];
  calibrationResolution?: [number, number];
  scale: number;
  // @ts-ignore
  stage: Stage | null;
  setIsViewportFit: (viewportFit: boolean) => void;
  setFitToView: () => void;
}

const PartSelector = ({
  stageHeight,
  parts,
  onPartHover,
  hoveredViewportPart,
  sidebarOpen,
  setSidebarOpen,
  currentLayer,
  imageHeightMM,
  imageWidthMM,
  plateBoundingBox,
  calibrationResolution,
  scale,
  stage,
  setIsViewportFit,
  setFitToView,
}: PartSelectorProps) => {
  const isAvailable = !!plateBoundingBox && !!calibrationResolution;

  const transformedParts = useMemo(() => {
    if (!!plateBoundingBox && !!calibrationResolution) {
      const {imageOrigin, imageScale} = calculateImageOffset(
        plateBoundingBox,
        scale,
        calibrationResolution,
        imageWidthMM,
        imageHeightMM
      );
      return transformPartBoundsToImagePosition(parts, imageOrigin, imageScale);
    }

    return [];
  }, [parts, calibrationResolution, plateBoundingBox, scale, imageWidthMM, imageHeightMM]);

  useEffect(() => {
    if (!sidebarOpen) onPartHover?.(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarOpen]);

  const onPartSelected = (part: IPartGETResponse) => {
    if (!stage) return;

    const transformedPart = transformedParts.find((tPart) => tPart.uuid === part.uuid);
    const _stage = stage.getStage();

    // Times by 0.8 to give a bit of space
    const scale = Math.min(
      (_stage.width() / transformedPart!.boundsMM.width) * 0.8,
      (_stage.height() / transformedPart!.boundsMM.height) * 0.8
    );

    const partWidth = transformedPart!.boundsMM.width * scale;
    const partHeight = transformedPart!.boundsMM.height * scale;
    const xCenter = (_stage.width() - partWidth) / 2;
    const yCenter = (_stage.height() - partHeight) / 2;

    _stage.to({
      x: -transformedPart!.boundsMM.x * scale + xCenter,
      y: -transformedPart!.boundsMM.y * scale + yCenter,
      scaleX: scale,
      scaleY: scale,
    });
    setIsViewportFit(false);
    setSidebarOpen(false);
  };

  // Sub-sort by uuid to handle conflicting names and preserve correct sorting
  const comparePartNames = (a: IPartGETResponse, b: IPartGETResponse) =>
    `${a.name}-${a.uuid}` > `${b.name}-${b.uuid}` ? 1 : -1;

  return (
    <PartSelectorContainer sidebarOpen={sidebarOpen}>
      {!sidebarOpen && (
        <ConditionalTooltip hideTooltip={!!parts.length} tooltip="Part data is not available">
          <ViewerButton
            onClick={() => setSidebarOpen(!sidebarOpen)}
            disabled={!parts.length}
            style={{
              pointerEvents: 'auto',
              width: '120px',
              justifyContent: 'flex-start',
              backgroundColor: 'transparent',
              paddingLeft: BUTTON_MARGIN * 2,
              paddingTop: BUTTON_MARGIN * 2,
            }}
          >
            <>
              Parts List <Menu style={{marginLeft: '6px', marginTop: '-2px'}} />
            </>
          </ViewerButton>
        </ConditionalTooltip>
      )}
      <SidebarContainer
        sidebarOpen={sidebarOpen}
        stageHeight={stageHeight}
        style={{
          overflow: 'hidden',
          width: '100%',
          pointerEvents: 'all',
          boxSizing: 'border-box',
        }}
        tabIndex={0}
      >
        {sidebarOpen ? (
          <>
            <ViewerButton
              onClick={() => setSidebarOpen(!sidebarOpen)}
              disabled={!parts.length}
              style={{
                pointerEvents: 'auto',
                position: 'absolute',
                right: BUTTON_MARGIN * 2,
                top: BUTTON_MARGIN,
                zIndex: 1,
                backgroundColor: grayColor[7],
              }}
            >
              <>
                <MenuOpen style={{marginLeft: '6px', marginTop: '-2px'}} />
              </>
            </ViewerButton>
            <PartListContainer>
              {parts.sort(comparePartNames).map((part) => {
                const layerNums = Object.keys(part.areas || {}).map((layerNum) => parseInt(layerNum));
                const maxLayer = max(layerNums) || 0;
                const isVisibleOnLayer = !!layerNums.length && !!currentLayer && currentLayer <= maxLayer;

                return (
                  <Tooltip
                    arrow
                    key={part.uuid}
                    placement="right"
                    title={
                      <Typography>
                        {!isAvailable ? (
                          'Bounding box, zoom functionality currently unavailable'
                        ) : !isVisibleOnLayer ? (
                          <>Part "{part.name}" is not visible on current layer</>
                        ) : (
                          part.name
                        )}
                      </Typography>
                    }
                  >
                    <ViewerButton
                      style={{justifyContent: 'flex-start', width: '100%'}}
                      onMouseLeave={() => onPartHover?.(null)}
                      onMouseEnter={() => onPartHover?.(part)}
                      disabled={!isVisibleOnLayer || !isAvailable}
                      onClick={() => onPartSelected(part)}
                    >
                      <Typography
                        noWrap
                        variant="button"
                        style={hoveredViewportPart?.uuid === part.uuid ? {color: 'orange'} : {}}
                      >
                        {part.name}
                      </Typography>
                    </ViewerButton>
                  </Tooltip>
                );
              })}
            </PartListContainer>
            <SetFitToViewContainer>
              <ViewerButton onClick={setFitToView}>Fit image to view</ViewerButton>
            </SetFitToViewContainer>
          </>
        ) : (
          <></>
        )}
      </SidebarContainer>
    </PartSelectorContainer>
  );
};

export default PartSelector;

const PartSelectorContainer = styled.div<{sidebarOpen: boolean}>`
  position: absolute;
  overflow: hidden;
  max-width: 300px;
  min-width: 175px;
  left: 0;
  top: 0;
  padding-bottom: ${BUTTON_HEIGHT + BUTTON_MARGIN};
  ${({sidebarOpen}) => sidebarOpen && 'z-index: 1;'}
  pointer-events: none;
  button {
    &:disabled {
      color: ${grayColor[3]};
    }
  }
`;

const PartListContainer = styled.div`
  width: 100%;
  height: calc(100% - ${BUTTON_HEIGHT}px);
  background-color: ${grayColor[7]};
  padding: 1rem;
  padding-right: 4rem;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;

const SetFitToViewContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: ${grayColor[7]};
  display: flex;
  justify-content: center;
  height: ${BUTTON_HEIGHT}px;
`;
