import React from 'react';
import {useHistory} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {cloneDeep} from 'lodash';
import {Button, Typography} from '@material-ui/core';
import {Add} from '@material-ui/icons';
import {DEFAULT_BUILD_REPORT_DEFECT_PROFILE} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import VerticalButton from '../../components/atoms/VerticalButton';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {RootState} from '../../store/reducers';
import buildReportDefectProfileActions from '../../store/actions/buildReportDefectProfileActions';
import {useAsyncDispatch} from '../../ReduxRoot';

function NewProfileButton() {
  const history = useHistory();
  const asyncDispatch = useAsyncDispatch();
  const isSmallScreen = useSmallScreenSize();
  const newProfileExists = useSelector((state: RootState) => !!state.buildReportDefectProfileStore.byId['new']);

  async function handleAddProfile() {
    await asyncDispatch(
      buildReportDefectProfileActions.updateProfile('new', cloneDeep(DEFAULT_BUILD_REPORT_DEFECT_PROFILE))
    );
    history.replace('/reports/buildReport/defectProfile/new');
  }

  if (isSmallScreen) {
    return (
      <VerticalButton color="secondary" onClick={handleAddProfile} disabled={newProfileExists}>
        <Add />
        <Typography variant="caption">New Profile</Typography>
      </VerticalButton>
    );
  }

  return (
    <Button variant="contained" color="primary" onClick={handleAddProfile} disabled={newProfileExists}>
      <Add />
      New Profile
    </Button>
  );
}

export default NewProfileButton;
