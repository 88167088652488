import React from 'react';
import {SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import SimilarityStatusChip from './SimilarityStatusChip';
import EditableHeader from '../../../components/atoms/EditableHeader';

interface SimilarityHeaderProps {
  reportName: string;
  onChangeAccepted: (newName: string) => Promise<boolean>;
  similarityStatus?: SimilarityStatus;
}

const SimilarityHeader = ({reportName, similarityStatus, onChangeAccepted}: SimilarityHeaderProps) => {
  const saveName = async (newName: string) => {
    return await onChangeAccepted(newName);
  };

  return (
    <EditableHeader
      label="Report Name"
      name={reportName}
      saveName={saveName}
      viewEndAdornment={!!similarityStatus && <SimilarityStatusChip similarityStatus={similarityStatus} size="small" />}
      viewEndAdornmentSize={!!similarityStatus ? '170px' : '46px'}
    />
  );
};

export default SimilarityHeader;
