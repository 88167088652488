import {AnalysisType3D, CTDefectType3D} from '@common/api/models/builds/data/defects/IDefect';
import {IPointCloud} from '@common/api/models/builds/data/IPointCloud';
import {BoxGeometry, SphereGeometry, BufferGeometry, PointsMaterial, Points, Object3D, Vector3, Color} from 'three';
import {BoundingBox} from '../Base3DViewport';
import {PointCloud2} from './pointCloudV2';
import {PointCloud3} from './pointCloudV3';
import {cmap} from '../../../../../utils/colormap';

export interface ThreePoints extends Points {
  geometry: BufferGeometry;
  material: PointsMaterial;
}

export type AnalysisTypeMap<T> = {
  [analysisType in AnalysisType3D]: T;
};

export type CTFiltersMap = {
  [ctDefectType in CTDefectType3D]?: {min?: number; max?: number};
};

export const sphereGeometry = new SphereGeometry(1, 7, 5);
export const boxGeometry = new BoxGeometry(1, 1, 1);

export type View3DState = 'noselection' | 'loading' | 'failed' | 'unavailable' | 'viewing';

export type LayerImageState = 'loading' | 'failed' | 'viewing';

export type GetBlockColorFn = (
  point: ExplicitPoint & Partial<SimilarityPoint>,
  analysisType: AnalysisType3D
) => [Color, number | null];

export interface PointCloudFilter {
  partUuids?: string[];
  analysisTypes?: AnalysisType3D[];
}

/** Information we need to store about each part's point cloud */
export interface SinglePartPointCloudData {
  pointCloudsByType: AnalysisTypeMap<IPointCloud[]>;
  numModelPartitionsDownloaded: number;
  partBounds?: BoundingBox;
}

export interface PointCloudLoadSuccess {
  success: true;
  object: Object3D;
  bounds: BoundingBox;
}

interface PointCloudLoadFailure {
  success: false;
  error: string;
}

export type PointCloudLoadResult = PointCloudLoadSuccess | PointCloudLoadFailure;

export interface ComparisonLoadSuccess {
  success: true;
  comparisonPoints: {[key in AnalysisType3D]?: Object3D};
}

export type ComparisonLoadResult = ComparisonLoadSuccess | PointCloudLoadFailure;

interface PointCloudParseSuccess {
  success: true;
  pointCloud: PointCloud2 | PointCloud3;
}

export type PointCloudParseResult = PointCloudParseSuccess | PointCloudLoadFailure;

// Unlike the Point interface from the point cloud definition,
// here colour and scale are stored explicitly
export interface ExplicitPoint {
  position: Vector3;
  colour: Color;
  scale: number;
  defectArea?: number;
  [CTDefectType3D.SurfaceArea]?: number;
  [CTDefectType3D.Volume]?: number;
  [CTDefectType3D.SurfacePerVolume]?: number;
  [CTDefectType3D.Extent]?: number;
  [CTDefectType3D.Solidity]?: number;
  [CTDefectType3D.DistanceToSurface]?: number;
  defectId?: number;
}

export interface SimilarityPoint extends ExplicitPoint {
  similarityCoefficient: number;
}

export const redBlueDivergentColourMap = cmap({
  colormap: 'RdBu',
  nshades: 200,
}).reverse();

export const turboColourMap = cmap({
  colormap: 'turbo',
  nshades: 100,
});

export type ComparisonPoints = {[key in AnalysisType3D]?: SimilarityPoint[]};
