import React, {useEffect, useState} from 'react';
import {IconButton, Grid, CircularProgress, Tooltip, TextField} from '@material-ui/core';
import {Edit, Save, Clear} from '@material-ui/icons';
import {Controller, useForm} from 'react-hook-form';
import EllipsisTextWithTooltip from '../../components/atoms/Texts/EllipsisTextWithTooltip';
import {uniqueId} from 'lodash';

interface EditableHeaderProps {
  name: string;
  saveName: (name: string) => Promise<boolean>;
  label: string;
  viewEndAdornment?: React.ReactNode;
  viewEndAdornmentSize?: string;
}

function EditableHeader({name, saveName, label, viewEndAdornment, viewEndAdornmentSize}: EditableHeaderProps) {
  const [reportName, setReportName] = useState<string>('');
  const [reportNameEditing, setReportNameEditing] = useState(false);
  const [requestingNameUpdate, setRequestingNameUpdate] = useState(false);

  useEffect(() => {
    if (name) setReportName(name);
  }, [name]);

  async function saveReportName(name: string) {
    setRequestingNameUpdate(true);
    const success = await saveName(name);

    if (success) {
      setReportName(name);
      setReportNameEditing(false);
    }

    setRequestingNameUpdate(false);
  }

  return (
    <>
      {reportNameEditing ? (
        <EditMode
          name={reportName}
          onSave={({name}) => saveReportName(name)}
          onCancel={() => setReportNameEditing(false)}
          requestingNameUpdate={requestingNameUpdate}
          label={label}
        />
      ) : (
        <ViewMode
          name={reportName}
          viewEndAdornment={viewEndAdornment}
          setReportNameEditing={() => setReportNameEditing(true)}
          viewEndAdornmentSize={viewEndAdornmentSize}
        />
      )}
    </>
  );
}

export default EditableHeader;

interface ViewModeProps {
  name: string;
  viewEndAdornment?: React.ReactNode;
  setReportNameEditing: () => void;
  viewEndAdornmentSize?: string;
}

const ViewMode = ({name, viewEndAdornment, setReportNameEditing, viewEndAdornmentSize}: ViewModeProps) => {
  return (
    <>
      <Grid item style={{maxWidth: `calc(100% - ${viewEndAdornmentSize || '0px'})`}}>
        <EllipsisTextWithTooltip variant="h4">{name}</EllipsisTextWithTooltip>
      </Grid>
      <Grid item style={{margin: '-6px 12px 0px 12px'}}>
        <Tooltip title="Edit">
          <IconButton size="small" onClick={setReportNameEditing}>
            <Edit />
          </IconButton>
        </Tooltip>
      </Grid>
      {viewEndAdornment && <Grid item>{viewEndAdornment}</Grid>}
    </>
  );
};

interface EditModeProps {
  name: string;
  label: string;
  onSave: (data: {name: string}) => void;
  onCancel: () => void;
  requestingNameUpdate: boolean;
}

const EditMode = ({name, label, onSave, onCancel, requestingNameUpdate}: EditModeProps) => {
  const id = uniqueId('editable-header-');
  const {
    errors,
    control,
    handleSubmit: onSubmit,
  } = useForm<{name: string}>({
    mode: 'all',
    defaultValues: {name},
  });

  return (
    <>
      <Grid
        item
        style={{
          width: 'calc(100% - 76px)',
          maxWidth: '360px',
          marginRight: '12px',
          marginTop: '6px',
        }}
      >
        <form id={id} onSubmit={onSubmit(onSave)}>
          <Controller
            name="name"
            control={control}
            render={({onChange, value, ref}) => {
              return (
                <TextField
                  fullWidth
                  autoFocus
                  variant="outlined"
                  size="small"
                  label={label}
                  onKeyDown={(event) => event.key === 'Escape' && onCancel()}
                  inputRef={ref}
                  value={value}
                  error={!!errors.name}
                  onChange={(event) => onChange(event.target.value)}
                />
              );
            }}
          />
        </form>
      </Grid>
      <Grid item>
        {requestingNameUpdate ? (
          <CircularProgress size={20} />
        ) : (
          <>
            <Tooltip title="Save">
              <IconButton size="small" onClick={onSubmit(onSave)}>
                <Save />
              </IconButton>
            </Tooltip>
            <Tooltip title="Cancel">
              <IconButton size="small" onClick={onCancel}>
                <Clear />
              </IconButton>
            </Tooltip>
          </>
        )}
      </Grid>
    </>
  );
};
