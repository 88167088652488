import React, {forwardRef, Ref, useEffect, useReducer, useRef, useState} from 'react';
import {TablePagination} from '@material-ui/core';
import MaterialTable from 'material-table';
import axios from 'axios';
import {maxBy, minBy} from 'lodash';
import {CommonCTStepProps} from '../steps/CTSteps';
import {materialTableIcons} from '../../../components/material-table-icons';
import {BASE_TABLE_OPTIONS, TableContainer} from '../../shared/IndexPage/IndexTable';
import {CTDefectType3D} from '@common/api/models/builds/data/defects/IDefect';
import {objMapValues} from '../../../utils/objectFunctions';
import {CTFiltersMap} from '../../../components/molecules/Viewport/3D/types/pointCloudTypes';
import {ctReportDownloadStatsCsvURLsGET} from '../../../api/ajax/ctReport';
import Papa from 'papaparse';

const CTDefectColumns: any = [
  {title: 'ID', field: 'id'},
  {
    title: 'Volume (mm³)',
    field: 'volume',
    defaultSort: 'desc',
    render: (row: CTDefectData) =>
      row.volume !== undefined
        ? `${new Intl.NumberFormat('en', {maximumSignificantDigits: 3}).format(row.volume)}mm³`
        : '-',
  },
  {
    title: 'Surface Area (mm²)',
    field: 'surfaceArea',
    render: (row: CTDefectData) =>
      row.surfaceArea !== undefined
        ? `${new Intl.NumberFormat('en', {maximumSignificantDigits: 3}).format(row.surfaceArea)}mm²`
        : '-',
  },
  {
    title: 'Surface Area/Volume (mm⁻¹)',
    field: 'surfacePerVolume',
    render: (row: CTDefectData) =>
      row.surfacePerVolume !== undefined
        ? `${new Intl.NumberFormat('en', {maximumSignificantDigits: 3}).format(row.surfacePerVolume)}mm⁻¹`
        : '-',
  },
  {
    title: 'Solidity (%)',
    field: 'solidity',
    // A solidity value of -1 is used to represent null, so we need to avoid displaying it
    render: (row: CTDefectData) =>
      row.solidity !== undefined && row.solidity >= 0
        ? `${new Intl.NumberFormat('en', {maximumSignificantDigits: 3}).format(row.solidity * 100)}%`
        : '-',
  },
  {
    title: 'Extent (%)',
    field: 'extent',
    render: (row: CTDefectData) =>
      row.extent ? `${new Intl.NumberFormat('en', {maximumSignificantDigits: 3}).format(row.extent * 100)}%` : '-',
  },

  {
    title: 'Distance to surface (mm)',
    field: 'distanceToSurface',
    render: (row: CTDefectData) =>
      row.distanceToSurface
        ? `${new Intl.NumberFormat('en', {maximumSignificantDigits: 3}).format(row.distanceToSurface)}mm`
        : '-',
  },
];

type CTDefectData = {
  id: number;
  volume?: number;
  surfaceArea?: number;
  surfacePerVolume?: number;
  solidity?: number;
  extent?: number;
  distanceToSurface?: number;
};

async function fetchCtDefectData(reportUuid: string) {
  const data: {[id: number]: CTDefectData} = {};

  const url = await ctReportDownloadStatsCsvURLsGET(reportUuid);
  if (!url.success) {
    return;
  }

  const response = await axios.get(url.data.url, {responseType: 'text'});
  const csvData = response.data;

  Papa.parse(csvData, {
    header: true,
    dynamicTyping: true,
    complete: function (results) {
      results.data.forEach((row: any) => {
        if (!row['Defect ID']) return;
        data[row['Defect ID']] = {
          id: row['Defect ID'],
          volume: row['Volume [mm^3]'],
          surfaceArea: row['Surface [mm^2]'],
          surfacePerVolume: row['Surface/Volume [mm^-1]'],
          solidity: row['Solidity'],
          extent: row['Extent'],
          distanceToSurface: row['Distance to surface [mm]'],
        };
      });
    },
  });

  return data;
}

function ctDefectFilter(data: CTDefectData, ctFilters: CTFiltersMap) {
  const filters = Object.entries(ctFilters || {});

  for (const [key, {min, max}] of filters) {
    const filterKey = key as CTDefectType3D;
    if (!data[filterKey]) {
      if (min || max) return false;
      else continue;
    }

    if (min && data[filterKey]! < min) return false;
    if (max && data[filterKey]! > max) return false;
  }

  return true;
}

function CTDefectsTable({
  currentCTReport,
  setActiveDefectId,
  setFilterRanges,
  ctFilters,
  activeDefectId,
}: CommonCTStepProps & {
  setActiveDefectId: (defectId: number) => void;
  setFilterRanges: (ranges: CTFiltersMap) => void;
  ctFilters: CTFiltersMap;
  activeDefectId: number | null;
}) {
  const tableRef = useRef<any>();
  const [tableKey, updateTableKey] = useReducer((x) => x + 1, 0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [data, setData] = useState<CTDefectData[]>([]);
  const [filteredData, setFilteredData] = useState<CTDefectData[]>([]);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadDefects = async () => {
      setLoading(true);
      const defectData = await fetchCtDefectData(currentCTReport.uuid);
      if (defectData) {
        setData(Object.values(defectData));
      }
      setLoading(false);
    };

    loadDefects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCTReport.uuid]);

  useEffect(() => {
    const getMinOrMax = (type: 'min' | 'max', field: CTDefectType3D) => {
      const minOrMaxBy = type === 'min' ? minBy : maxBy;
      // -1 values can occur, these represent null and should be avoided
      const filteredData = data.filter((o) => o[field] !== undefined && o[field]! >= 0);
      return minOrMaxBy(filteredData, (o) => o[field])?.[field] || 0;
    };

    const filterRanges = objMapValues(CTDefectType3D, (_, ctDefectType) => {
      return {
        min: getMinOrMax('min', ctDefectType),
        max: getMinOrMax('max', ctDefectType),
      };
    });
    setFilterRanges(filterRanges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length]);

  useEffect(() => {
    const newData = data.filter((defect) => ctDefectFilter(defect, ctFilters));

    // Delay the update to ensure material table re-renders, preventing memory leak.
    setFilteredData(newData);
    updateTableKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctFilters, data.length]);

  return (
    <MaterialTable
      key={`${currentCTReport.uuid}-table-ct-defects-${tableKey}`}
      tableRef={tableRef}
      columns={CTDefectColumns}
      data={filteredData}
      icons={materialTableIcons}
      onRowClick={(_, rowData: any) => setActiveDefectId(rowData.id)}
      components={{
        Container: (props) => <TableContainer {...props} $minTableWidth="750px" className="material-index-table" />,
        Pagination: forwardRef((props, ref: Ref<HTMLAnchorElement>) => (
          <TablePagination
            {...props}
            ref={ref}
            rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
            rowsPerPage={rowsPerPage}
            count={filteredData.length || 0}
            page={page}
            onChangePage={(event, newPageNumber) => {
              setPage(newPageNumber);
              tableRef?.current?.onChangePage(event, newPageNumber);
            }}
            onChangeRowsPerPage={(event) => {
              setRowsPerPage(parseInt(event.target.value));
              tableRef?.current?.onChangeRowsPerPage(event);
            }}
            labelRowsPerPage="Rows per page:"
          />
        )),
      }}
      options={{
        ...BASE_TABLE_OPTIONS,
        pageSize: rowsPerPage,
        rowStyle: (rowData) => ({
          backgroundColor: rowData?.id === activeDefectId ? '#F5FaFF' : '',
        }),
      }}
      localization={{
        body: {emptyDataSourceMessage: loading ? '' : 'No CT Defects to display'},
      }}
    />
  );
}

export default CTDefectsTable;
