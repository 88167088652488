import {Pagination} from '@common/api/models/common';

export enum BuildReportStatus {
  Submitted = 'submitted',
  Generating = 'generating',
  Success = 'success',
  Failure = 'failure',
}

export interface IBuildReportConfiguration {
  includeCoverPage: boolean;
  includeToc: boolean;
  includedParts: Array<string> | null;
  includeBuildPhoto: boolean;
  includeGlossary: boolean;
}

export const DEFAULT_REPORT_CONFIGURATION: IBuildReportConfiguration = {
  includeCoverPage: false,
  includeToc: false,
  includedParts: null,
  includeBuildPhoto: false,
  includeGlossary: false,
};

export interface IBuildReport {
  uuid: string;
  reportUrl?: string;
  createdAt: Date;
  buildUuid: string;
  buildReportDefectProfileUuid: string;
  status: BuildReportStatus;
  reportConfiguration: IBuildReportConfiguration;
}

export type IBuildReportSortOptions = 'buildName' | 'buildUuid' | 'buildEndDate' | 'createdAt' | 'status';

export interface IBuildReportsGETRequest extends Pagination {
  uuid: string | null;
  buildUuid: string | null;
  buildName: string | null;
  buildEndDate: string | null;
  status: BuildReportStatus | null;
}

export interface IBuildReportGETResponse extends Omit<IBuildReport, 'reportUrl'> {
  buildUuid: string;
  buildEndDate: Date;
  buildName: string;
  selectedPartNames: string[];
  buildReportDefectProfileName: string;
}

export interface IBuildReportPOSTRequest {
  buildUuid: string;
  reportConfiguration: IBuildReportConfiguration;
  buildReportDefectProfileUuid: string;
}
export interface IBuildReportUrlResponse {
  url: string;
}
