import React from 'react';
import {MenuItem, TextField} from '@material-ui/core';
import {Units} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {DefectType} from '@common/api/models/builds/data/defects/IDefect';

interface NumberInputProps {
  value: number;
  onChange: (field: string, value: number) => void;
  field: string;
  label: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  fullWidth?: boolean;
  max?: number;
}

const positiveIntegerOrEmpty = /^\d*$/;
export function IntegerInput({
  value,
  onChange,
  field,
  label,
  marginLeft,
  marginRight,
  width = '180px',
  fullWidth = false,
}: NumberInputProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (positiveIntegerOrEmpty.test(newValue)) {
      onChange(field, parseInt(newValue));
    }
  };

  return (
    <TextField
      size="small"
      type="number"
      variant="outlined"
      style={{width, margin: `0px ${marginRight || '8px'} 0px ${marginLeft || '8px'}`}}
      label={label}
      value={value}
      onChange={handleChange}
      fullWidth={fullWidth}
    />
  );
}

const positiveFloatOrEmpty = /^(0|[1-9]\d*){0,1}(\.+\d*)?$/;
export function FloatInput({
  value,
  onChange,
  field,
  label,
  marginLeft,
  marginRight,
  width = '180px',
  fullWidth = false,
  max,
}: NumberInputProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    if (positiveFloatOrEmpty.test(newValue)) {
      if (newValue && ((max && parseFloat(newValue) > max) || parseFloat(newValue) < 0)) {
        return;
      }
      onChange(field, parseFloat(newValue));
    } else if (parseFloat(newValue) === 0) {
      onChange(field, 0);
    }
  };

  return (
    <TextField
      size="small"
      type="number"
      variant="outlined"
      style={{width, margin: `10px ${marginRight || '12px'} 4px ${marginLeft || '0px'}`}}
      label={label}
      value={`${value ?? ''}`}
      onChange={handleChange}
      fullWidth={fullWidth}
      inputProps={{max: max ?? undefined}}
    />
  );
}

type SelectTypes = DefectType | Units;

interface SelectInputProps {
  value: SelectTypes;
  options: {value: SelectTypes; label: string}[];
  onChange: (field: string, value: SelectTypes) => void;
  field: string;
  label: string;
  marginLeft?: string;
  marginRight?: string;
  width?: string;
  fullWidth?: boolean;
}

export function SelectInput({
  value,
  options,
  onChange,
  field,
  label,
  marginLeft,
  marginRight,
  width = '180px',
  fullWidth = false,
}: SelectInputProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    onChange(field, newValue as SelectTypes);
  };

  return (
    <TextField
      select
      size="small"
      style={{width, margin: `10px ${marginRight || '12px'} 4px ${marginLeft || '0px'}`}}
      variant="outlined"
      label={label}
      value={value}
      onChange={handleChange}
      fullWidth={fullWidth}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
}
