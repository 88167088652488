import {toast} from 'react-toastify';

export function downloadUrlMultiple(downloads: [string, string][]) {
  if (downloads.length === 0) return;

  const thisDownload = downloads.pop();
  downloadUrl(...thisDownload!);
  // For some reason, need to wait a second before triggering the next or it gets ignored by the browser
  setTimeout(() => downloadUrlMultiple(downloads), 1000);
}

export function downloadUrl(url: string, filename: string) {
  let a = document.createElement('a');
  a.download = filename;
  a.href = url;
  // For Firefox https://stackoverflow.com/a/32226068
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export function downloadBlob(filename: string, blob: Blob) {
  let a = document.createElement('a');
  a.download = filename;
  a.href = window.URL.createObjectURL(blob);
  document.body.appendChild(a);
  a.click();
  a.remove();
}

export async function downloadResource(url: string, filename: string) {
  try {
    const response = await fetch(url, {
      headers: new Headers({
        Origin: window.location.origin,
      }),
      mode: 'cors',
    });

    if (response.status < 400) {
      const blob = await response.blob();

      if (blob) {
        let blobUrl = window.URL.createObjectURL(blob);
        downloadUrl(blobUrl, filename);
      }
    } else {
      toast(`Failed to download ${filename}`, {type: 'error'});
    }
  } catch (e) {
    toast(`Failed to download ${filename}`, {type: 'error'});
    console.error(e);
  }
}

export function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

// The below is sourced from https://stackoverflow.com/questions/23945494/use-html5-to-resize-an-image-before-upload/39235724#39235724
interface IResizeImageOptions {
  maxSize: number;
  file: File;
}
export function resizeImage(settings: IResizeImageOptions): Promise<File> {
  const file = settings.file;
  const maxSize = settings.maxSize;
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  function dataURItoBlob(dataURI: string) {
    const bytes =
      dataURI.split(',')[0].indexOf('base64') >= 0 ? atob(dataURI.split(',')[1]) : unescape(dataURI.split(',')[1]);
    const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const max = bytes.length;
    const ia = new Uint8Array(max);
    for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    return new Blob([ia], {type: mime});
  }

  function resize() {
    let width = image.width;
    let height = image.height;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else {
      if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d')!.drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/jpeg', 0.9); // 70% quality
    const blob = dataURItoBlob(dataUrl);
    return new File([blob], file.name.replace(/\.[^/.]+$/, '.jpg'), {type: 'image/jpeg'});
  }

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent: any) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };
    reader.readAsDataURL(file);
  });
}
