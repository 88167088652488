import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {ChevronUp, ChevronRight} from 'react-feather';
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  Box,
  Icon,
  IconButton,
  Tooltip,
  CircularProgress,
} from '@material-ui/core';
import {Delete} from '@material-ui/icons';
import {IBuildReportDefectProfile} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {useSmallScreenSize} from '../../utils/utilHooks';
import {RootState} from '../../store/reducers/index';
import {buildReportDefectProfileDELETE} from '../../api/ajax/buildReportDefectProfile';
import {NoProfiles} from './BuildReportDefectProfilesNoProfiles';
import {ChipStatus} from '../../components/atoms/Status/Status';

function BuildReportDefectProfilesList({setIsDrawerOpen}: {setIsDrawerOpen?: (open: boolean) => void}) {
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const profiles = useSelector((state: RootState) => Object.values(state.buildReportDefectProfileStore.byId));

  return (
    <Card style={{height: '100%', overflow: 'auto'}}>
      {profiles.length === 0 ? (
        <NoProfiles />
      ) : (
        <List style={{padding: 0}}>
          {profiles.map((profile) => (
            <DefectProfileListItem
              key={profile.uuid}
              selected={profile.uuid === selectedUuid}
              setIsDrawerOpen={setIsDrawerOpen}
              {...profile}
            />
          ))}
        </List>
      )}
    </Card>
  );
}

export default BuildReportDefectProfilesList;

interface IDefectProfileListItem extends IBuildReportDefectProfile {
  selected?: boolean;
  setIsDrawerOpen?: (open: boolean) => void;
}

function DefectProfileListItem({uuid, name, selected, setIsDrawerOpen}: IDefectProfileListItem) {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const [deleting, setDeleting] = useState(false);
  const hasUnsavedChanges = useSelector((state: RootState) => !!state.buildReportDefectProfileStore.originals[uuid]);

  function onListItemClicked() {
    history.replace(`/reports/buildReport/defectProfile/${uuid}/`);
    setIsDrawerOpen && setIsDrawerOpen(false);
  }

  async function onDeleteClick() {
    setDeleting(true);
    await buildReportDefectProfileDELETE(uuid);
    setDeleting(false);
  }

  return (
    <ListItem divider selected={selected} onClick={onListItemClicked} style={{cursor: 'pointer'}}>
      <ListItemText
        primary={
          <Box display="flex" justifyContent="space-between">
            <Typography style={{paddingRight: '12px', wordBreak: 'break-word'}}>{name}</Typography>
            {hasUnsavedChanges && <ChipStatus chipSize="small" message="Unsaved" variant="none" />}
          </Box>
        }
        style={{paddingRight: '48px'}}
      />
      <ListItemSecondaryAction style={{display: 'flex'}}>
        {uuid !== 'new' && (
          <Tooltip title={<Typography>Delete profile</Typography>}>
            <span>
              <IconButton edge="end" onClick={onDeleteClick} disabled={deleting}>
                {deleting ? <CircularProgress size={20} /> : <Delete />}
              </IconButton>
            </span>
          </Tooltip>
        )}
        <Icon
          component="div"
          color={selected ? 'secondary' : undefined}
          style={{padding: '12px 0px 12px 12px', height: '36px', width: '36px'}}
        >
          {isSmallScreen ? <ChevronUp /> : <ChevronRight />}
        </Icon>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
