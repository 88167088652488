import {cloneDeep, set} from 'lodash';
import {IBuildReportDefectProfile, Units} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {BuildReportDefectProfileActions} from '../model/buildReportDefectProfile';
import {RootState} from '../reducers';
import {DefectType} from '@common/api/models/builds/data/defects/IDefect';
import {LogicalOperator} from '@common/api/models/builds/data/defects/IDefectNotifications';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  updateProfile(uuid: string, profile: Partial<IBuildReportDefectProfile>) {
    return async (dispatch: Function, _getState: () => RootState) => {
      dispatch({
        type: BuildReportDefectProfileActions.UPDATE_BUILD_REPORT_DEFECT_PROFILE,
        payload: {uuid, profile},
      });

      return true;
    };
  },

  updateField(
    uuid: string,
    grade: 'aGrade' | 'bGrade' | 'cGrade',
    path: string,
    value: number | Units | DefectType | LogicalOperator | null
  ) {
    return async (dispatch: Function, getState: () => RootState) => {
      const currentProfile = getState().buildReportDefectProfileStore.byId[uuid] || {};
      const profile = cloneDeep(currentProfile);
      set(profile[grade], path, value);

      dispatch({
        type: BuildReportDefectProfileActions.UPDATE_BUILD_REPORT_DEFECT_PROFILE,
        payload: {uuid, profile},
      });

      return true;
    };
  },

  cloneProfile(uuid: string) {
    return async (dispatch: Function, getState: () => RootState) => {
      try {
        const profile = getState().buildReportDefectProfileStore.byId[uuid];

        dispatch({
          type: BuildReportDefectProfileActions.CLONE_BUILD_REPORT_DEFECT_PROFILE,
          payload: {
            profile: {
              ...cloneDeep(profile),
              uuid: 'new',
              name: `${profile.name} (copy)`,
            },
          },
        });

        return true;
      } catch {
        console.error('Failed to clone build report defect profile');
        return false;
      }
    };
  },

  removeUnsavedChanges(uuid: string) {
    return async (dispatch: Function, _getState: () => RootState) => {
      dispatch({
        type: BuildReportDefectProfileActions.REMOVE_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE,
        payload: {uuid},
      });

      return true;
    };
  },

  revertUnsavedChanges(uuid: string) {
    return async (dispatch: Function, _getState: () => RootState) => {
      dispatch({
        type: BuildReportDefectProfileActions.REVERT_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE,
        payload: {uuid},
      });

      return true;
    };
  },
};
