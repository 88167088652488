import {ISimilarityComparisonGETResponse, SimilarityStatus} from '@common/api/models/builds/data/ISimilarity';
import {AnalysisType3D} from '@common/api/models/builds/data/defects/IDefect';
import {similarityComparisonPointCloudUrlsGET} from '../../../../../api/ajax/similarityReport';
import {MinMax} from '../../../../../pages/builds/liveBuild/activeBuildPages/DefectsPage';
import {objMapValues} from '../../../../../utils/objectFunctions';
import {useState, useMemo, useEffect} from 'react';
import {toast} from 'react-toastify';
import {BoundingBox} from '../Base3DViewport';
import {View3DViewportParams} from '../View3DViewport';
import {PointCloud2} from '../types/pointCloudV2';
import {PointCloud3} from '../types/pointCloudV3';
import {usePartPointClouds} from './usePartPointClouds';
import {minBy, maxBy} from 'lodash';
import {PartPointClouds} from '../PartPointCloud';
import {downloadSimilarityPointCloud} from '../loaders/downloadPointClouds';
import {loadPointCloudAsMesh, loadSimilarityComparisonsAsMesh} from '../loaders/meshLoaders';
import {
  PointCloudLoadSuccess,
  ComparisonLoadSuccess,
  SimilarityPoint,
  AnalysisTypeMap,
  View3DState,
  boxGeometry,
} from '../types/pointCloudTypes';

const getMinMaxAnalysisValues = (result: PointCloudLoadSuccess, comparisons: ComparisonLoadSuccess) => {
  const getMin = (points: SimilarityPoint[], field: 'scale' | 'similarityCoefficient') => {
    const value = minBy(points, (o) => o[field])?.[field] || 0;
    return Math.floor(value * 10) / 10;
  };

  const getMax = (points: SimilarityPoint[], field: 'scale' | 'similarityCoefficient') => {
    const value = maxBy(points, (o) => o[field])?.[field] || 0;
    return Math.ceil(value * 10) / 10;
  };

  return objMapValues(AnalysisType3D, (_, analysisType) => {
    if (analysisType === AnalysisType3D.Model) {
      const points: SimilarityPoint[] = result.object.userData.pointData;
      return {
        min: getMin(points, 'scale'),
        max: getMax(points, 'scale'),
      };
    } else {
      const points: SimilarityPoint[] = comparisons.comparisonPoints[analysisType]?.userData?.pointData;
      return {
        min: getMin(points, 'similarityCoefficient'),
        max: getMax(points, 'similarityCoefficient'),
      };
    }
  });
};

const noAnalysisType = {} as AnalysisTypeMap<boolean>;

/**
 * Custom hook for managing similarity point clouds on the Similarity 3D viewport.
 *
 * @param similarityComparison - The similarity comparison data.
 * @param sourceUuid - The UUID of the source part.
 * @param reportUuid - The UUID of the report.
 * @param params - The viewport parameters.
 * @param renderScene - A function to render the scene.
 * @returns An object containing the state and data related to the similarity point clouds.
 */
export function useSimilarityPointClouds(
  similarityComparison: ISimilarityComparisonGETResponse | undefined,
  sourceUuid: string,
  reportUuid: string,
  params: View3DViewportParams,
  renderScene: () => void
) {
  const [viewportState, setViewportState] = useState<View3DState>('noselection');

  const partPointCloudParams = useMemo(
    () => ({
      ...params,
      use3DPoints: false,
      pointSize: params.pointSize / 4,
      centerAllParts: true,
      isSimilarity: true,
    }),
    [params]
  );

  const {viewportState: geometryViewportState, pointClouds: geometryPointClouds} = usePartPointClouds(
    similarityComparison && sourceUuid ? [sourceUuid, similarityComparison?.targetPartUuid] : [],
    noAnalysisType,
    partPointCloudParams,
    renderScene
  );

  const [pointClouds] = useState(() => new PartPointClouds());

  const [availableAnalysisTypes, setAvailableAnalysisTypes] = useState<AnalysisTypeMap<boolean>>(
    objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>
  );
  // Min/Max comparisons results (used for the sliders). For model type, point size
  const [analysisTypeSizes, setAnalysisTypeSizes] = useState<AnalysisTypeMap<MinMax<number>>>(
    objMapValues(AnalysisType3D, () => ({})) as AnalysisTypeMap<MinMax<number>>
  );

  const [firstPartLoaded, setFirstPartLoaded] = useState(false);

  const [sceneBounds, setSceneBounds] = useState<BoundingBox | null>(null);

  useEffect(() => {
    if (!similarityComparison) {
      setViewportState('noselection');
      return;
    }

    if (similarityComparison.status !== SimilarityStatus.Success) {
      setViewportState('viewing');
    } else {
      setViewportState('loading');
    }

    setFirstPartLoaded(false);

    pointClouds.forEachAnalysisType((analysisTypeGroup) => {
      if (analysisTypeGroup.children) analysisTypeGroup.remove(...analysisTypeGroup.children);
    });

    setAvailableAnalysisTypes(objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>);
  }, [similarityComparison, pointClouds]);

  // FIXME: Duplicated code (kinda)
  const loadPointCloudWrapper = async (pointCloud: PointCloud2 | PointCloud3, comparisonUuid: string) => {
    // Pretend this is a model point cloud, as this is mainly used to
    // determine whether it should be transparent or not
    const result = loadPointCloudAsMesh(pointCloud, AnalysisType3D.Model, params, boxGeometry);

    if (!result.success) {
      toast(result.error, {type: 'error'});
      return;
    }

    pointClouds.addPointCloud(comparisonUuid, AnalysisType3D.Model, result.object);

    // Lower the min bounds and extend the dimensions a tad to ensure the clipping plane
    // extends for the full model. Without this, the clipping plane won't clip the whole model
    // as we show Similarity in cubes and cubes extend past the bounds (their centroid is within bounds)
    // Similarity Point Clouds load just above 0 on the x,y plane.
    result.bounds.min.x = 0;
    result.bounds.min.y = 0;
    result.bounds.min.z = 0;
    result.bounds.dimensions.x *= 1.2;
    result.bounds.dimensions.y *= 1.2;
    result.bounds.dimensions.z *= 1.2;
    // Only set the bounds if this is the first model to be loaded,
    // otherwise the camera will unexpectedly reset when changing number of points
    if (!firstPartLoaded) setSceneBounds(result.bounds);

    const comparisons = loadSimilarityComparisonsAsMesh(pointCloud, params, boxGeometry);
    if (!comparisons.success) return;

    Object.entries(comparisons.comparisonPoints).forEach(([type, object]) => {
      pointClouds.addPointCloud(comparisonUuid, type as AnalysisType3D, object!);
    });

    const availableAnalysisTypes = objMapValues(
      AnalysisType3D,
      (_, analysisType) => !!pointClouds.getPointClouds(comparisonUuid, analysisType as AnalysisType3D).length
    ) as AnalysisTypeMap<boolean>;
    availableAnalysisTypes[AnalysisType3D.Model] = true;
    availableAnalysisTypes[AnalysisType3D.Geometry] = true;
    const minMaxValues = getMinMaxAnalysisValues(result, comparisons) as AnalysisTypeMap<MinMax<number>>;

    setAvailableAnalysisTypes(availableAnalysisTypes);
    setAnalysisTypeSizes(minMaxValues);
  };

  const downloadPointCloudWrapper = (url: string, comparisonUuid: string, analysisType: AnalysisType3D) => {
    return downloadSimilarityPointCloud(url, comparisonUuid, analysisType, loadPointCloudWrapper, () => {
      toast('Could not download 3D data', {type: 'error'});
      setViewportState('failed');
    });
  };

  const loadComparisonPointClouds = async (comparisonUuid: string) => {
    const response = await similarityComparisonPointCloudUrlsGET(reportUuid, comparisonUuid);

    if (!response.success || !response.data.length) {
      setViewportState('unavailable');
      return;
    }

    setViewportState('loading');
    setAvailableAnalysisTypes(objMapValues(AnalysisType3D, () => false) as AnalysisTypeMap<boolean>);

    Promise.all(
      response.data.map(({url}) =>
        // We're not displaying a part, so use the comparison UUID as the part UUID
        downloadPointCloudWrapper(url, comparisonUuid, AnalysisType3D.Model)
      )
    ).then(() => setViewportState('viewing'));
  };

  useEffect(() => {
    if (similarityComparison?.status !== SimilarityStatus.Success) {
      return;
    }

    if (!firstPartLoaded) {
      loadComparisonPointClouds(similarityComparison.uuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [similarityComparison, firstPartLoaded]);

  const addBasePointClouds = (
    basePointClouds: PartPointClouds,
    similarityGeometryType: 'source' | 'target',
    partUuid: string
  ) => {
    const original = basePointClouds.getPointClouds(partUuid, AnalysisType3D.Model)[0];
    const points = original.clone();
    // Cloning does some weird inversion to the rotation (0, 3.14, 0) becomes (-3.14, 3.14, -3.14). Can cause the desired rotation to be wrong.
    points.rotation.set(original.rotation.x, original.rotation.y, original.rotation.z);
    points.userData.partUuid = partUuid;
    points.userData.isSimilarityPartPointCloud = true;
    points.userData.similarityGeometryType = similarityGeometryType;
    points.renderOrder = 2;
    points.name = `${similarityGeometryType}GeometryPartition`;
    pointClouds.addPointCloud(partUuid, AnalysisType3D.Geometry, points);
  };

  useEffect(() => {
    const targetPartUuid = similarityComparison?.targetPartUuid;
    if (!targetPartUuid) return;

    if (
      viewportState === 'viewing' &&
      geometryViewportState === 'viewing' &&
      geometryPointClouds.getPointClouds(sourceUuid, AnalysisType3D.Model).length && //. We only consider the first source part for display purposes
      geometryPointClouds.getPointClouds(targetPartUuid, AnalysisType3D.Model).length //. We only consider the first source part for display purposes
    ) {
      addBasePointClouds(geometryPointClouds, 'source', sourceUuid);
      addBasePointClouds(geometryPointClouds, 'target', targetPartUuid);
      setFirstPartLoaded(true);
      setAvailableAnalysisTypes((available) => {
        available[AnalysisType3D.Geometry] = true;
        return available;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geometryViewportState, viewportState]);

  return {
    viewportState,
    viewportLoading: similarityComparison && (viewportState === 'loading' || geometryViewportState === 'loading'),
    pointClouds,
    sceneBounds,
    availableAnalysisTypes,
    analysisTypeSizes,
  };
}
