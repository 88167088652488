import React, {
  createContext,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import {useParams} from 'react-router-dom';
import {IBuild} from '@common/api/models/builds/IBuild';
import {ISliceAttachment} from '@common/api/models/attachments/ISliceAttachment';
import {buildsByUuidPATCH} from '../../../api/ajax/builds';
import {newDebouncer} from '../../../api/ajax';
import buildSteps, {BuildStep} from './draftBuildStepsConfig';
import {useDispatch} from 'react-redux';
import {DraftBuildActions} from '../../../store/model/draftBuild';
import {isEqual, pick} from 'lodash';

const SET_SAVE_STATE_ACTION = DraftBuildActions.SET_DRAFT_BUILD_SAVE_STATE;

type DraftBuildContextStore = {
  isComplete: boolean;
  isStepComplete: (step: BuildStep, includeOptional?: boolean) => boolean;
  draftBuild: IBuild;
  setDraftWithSave: Dispatch<SetStateAction<IBuild>>;
};

export const DraftBuildContext = createContext<DraftBuildContextStore | null>(null);

const delay = newDebouncer();

const DraftBuildProvider = ({
  children,
  build,
  slices,
  isSimpleWorkflow,
}: {
  children: React.ReactNode;
  build: IBuild;
  slices: ISliceAttachment[];
  isSimpleWorkflow: boolean;
}) => {
  const dispatch = useDispatch();
  const [changed, setChanged] = useState(false);
  const {uuid} = useParams<{uuid: string}>();
  const [draftBuild, setDraftBuild] = useState<IBuild>(build);

  useEffect(() => {
    const changes = pick(build, Object.keys(draftBuild));
    if (!isEqual(changes, draftBuild)) {
      setDraftBuild(build);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build]);

  useEffect(() => {
    if (changed) {
      setChanged(false);
      delay(async () => {
        dispatch({type: SET_SAVE_STATE_ACTION, payload: 'saving'});

        const res = await buildsByUuidPATCH(uuid, getDiff(draftBuild, build));
        if (res.success) {
          dispatch({type: SET_SAVE_STATE_ACTION, payload: 'success'});
        } else {
          dispatch({type: SET_SAVE_STATE_ACTION, payload: 'error'});
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changed, draftBuild, uuid]);

  const getDiff = (nextDraft: IBuild, currentDraft: IBuild) => {
    const keys: Array<keyof IBuild> = Object.keys(nextDraft) as Array<keyof IBuild>;
    const differences: Partial<IBuild> = {};

    keys.forEach((key) => {
      if (!isEqual(nextDraft[key], currentDraft[key])) {
        // @ts-ignore
        differences[key] = nextDraft[key];
      }
    });

    return differences;
  };

  const setDraftWithSave: Dispatch<SetStateAction<IBuild>> = (arg: SetStateAction<IBuild>) => {
    setDraftBuild(arg);
    setChanged(true);
    dispatch({type: SET_SAVE_STATE_ACTION, payload: 'saving'});
  };

  const isStepComplete = useCallback(
    (step: BuildStep, includeOptional: boolean = true) =>
      step && (step.completed(draftBuild, slices, isSimpleWorkflow) || (includeOptional && step.optional)),
    [draftBuild, slices, isSimpleWorkflow]
  );

  const isComplete = useMemo(
    () => buildSteps.every((step) => isStepComplete(step) || step.nonBlockingRequired),
    [isStepComplete]
  );

  const store = {
    isComplete,
    isStepComplete,
    draftBuild,
    setDraftWithSave,
  };

  return <DraftBuildContext.Provider value={store}>{children}</DraftBuildContext.Provider>;
};

export default DraftBuildProvider;

export function useDraftBuild() {
  const draftBuildContext = useContext(DraftBuildContext);
  if (!draftBuildContext) {
    throw new Error('useDraftBuild must be called within a DraftBuild provider');
  }
  return draftBuildContext;
}
