import React from 'react';
import {useParams} from 'react-router-dom';
import {Box, Grid, Typography} from '@material-ui/core';
import {Units} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {DefectType} from '@common/api/models/builds/data/defects/IDefect';
import {LogicalOperator} from '@common/api/models/builds/data/defects/IDefectNotifications';
import {IntegerInput} from './BuildReportDefectProfileInputs';
import {useSelector} from 'react-redux';
import {RootState} from '../../store/reducers';
import buildReportDefectProfileActions from '../../store/actions/buildReportDefectProfileActions';
import {useAsyncDispatch} from '../../ReduxRoot';
import BuildReportDefectProfileConditionGroup from './BuildReportDefectProfileConditionGroup';

interface BuildReportDefectProfileGradeCriteriaFormProps {
  grade: 'a' | 'b' | 'c';
}

function BuildReportDefectProfileGradeCriteriaForm({grade}: BuildReportDefectProfileGradeCriteriaFormProps) {
  const asyncDispatch = useAsyncDispatch();
  const {uuid: selectedUuid} = useParams<{uuid: string}>();
  const gradeConditions = useSelector(
    (state: RootState) => state.buildReportDefectProfileStore.byId[selectedUuid][`${grade}Grade`]
  );

  async function onChange(path: string, value: number | Units | DefectType | LogicalOperator | null) {
    await asyncDispatch(buildReportDefectProfileActions.updateField(selectedUuid, `${grade}Grade`, path, value));
  }

  return (
    <>
      <Box display="flex" alignItems="center">
        <img src={`/img/Defect_Grades/${grade}gradeicon.svg`} width="40px" alt={`${grade.toUpperCase()} Grade Icon`} />
        <Typography variant="h5" style={{marginLeft: '8px', marginTop: '2px'}}>
          Grade Criteria
        </Typography>
      </Box>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} style={{marginBottom: '1rem'}}>
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <Typography style={{lineHeight: '2.7'}}>
              To pass {grade.toUpperCase()} grade, a part cannot record the following defects over
              <IntegerInput
                value={gradeConditions.consecutiveLayers}
                onChange={(_field, value) => onChange('consecutiveLayers', value)}
                field="consecutiveLayers"
                label="Layers"
                width="100px"
              />
              consecutive layers.
            </Typography>
          </Box>
        </Grid>

        {gradeConditions.conditionGroups.map((conditionGroup, conditionGroupIndex) => (
          <BuildReportDefectProfileConditionGroup
            key={`${grade}-${conditionGroupIndex}`}
            grade={grade}
            conditionGroup={conditionGroup}
            conditionGroupIndex={conditionGroupIndex}
            onChange={onChange}
          />
        ))}
      </Grid>
    </>
  );
}

export default BuildReportDefectProfileGradeCriteriaForm;

export function BuildReportDefectProfileNCGradeCriteria() {
  return (
    <>
      <Box display="flex" alignItems="center">
        <img src="/img/Defect_Grades/ncgradeicon.svg" width="40px" alt="A Grade Icon" />
        <Typography variant="h5" style={{marginLeft: '8px', marginTop: '2px'}}>
          Grade Criteria
        </Typography>
      </Box>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography style={{lineHeight: '2.7'}}>
            Where a part fails to meet the requirements of any higher grade it will be classified as NC grade
            (non-conformance).
          </Typography>
        </Box>
      </Grid>
    </>
  );
}
