import {DispatchWithoutAction} from 'react';
import Konva from 'konva';
import {groupBy} from 'lodash';
import {RGBColor} from 'react-color';

import {ILayerImage, LayerImageResolutionSize} from '@common/api/models/builds/data/ILayerImage';
import {ICalibration} from '@common/api/models/devices/ICalibration';
import {IPartGETResponse} from '@common/api/models/builds/data/IPart';
import LazyLayerImage from '../../../atoms/LazyLayerImage';
import {LiveStoreState} from '../../../../store/model/liveUpdateStore';
import {LayerImages, PartImages} from '../../../../pages/builds/liveBuild/activeBuildPages/ViewportsPage';
import {AnalysisType2D} from '@common/api/models/builds/data/defects/IDefect';

export const BUTTON_WIDTH = 30;
export const BIG_BUTTON_WIDTH = 40;
export const BUTTON_HEIGHT = 34;
export const BUTTON_MARGIN = 10;

// Birdseye is the "default" part for now, while we are not supporting multiple parts.
export const defaultPart = 'birdsEye';

const EPS = 1e-10;

// We need to call this to avoid infinite loop of scale adjustment due to float imprecision.
export function setStageScaleIfChanged(stage: Konva.Stage, scale: number, callback?: (newScale: number) => any) {
  const oldScale = stage.getStage().getAttr('scaleX');

  if (Math.abs(oldScale - scale) > EPS) {
    // The newScale inputted is a value agnostic to the width  of the image
    stage.setAttr('scaleX', scale);
    stage.setAttr('scaleY', scale);
    if (callback) {
      callback(scale);
    }
  }
}

export function getMaxIncrementalLayer(layers: LayerImages): number {
  // Remove layers where the only image for the layer is a layerMask.
  // These are generated when the build goes to monitoring,
  // so they don't indicate a completed layer.
  const nonMaskLayers = Object.entries(layers)
    .filter(([_layerNumber, layerImage]) => {
      const layerTypes = Object.keys(layerImage[defaultPart]);
      return !(layerTypes.length === 1 && layerTypes[0] === AnalysisType2D.LayerMask);
    })
    .map(([layerNumber, _layerImage]) => Number(layerNumber));

  return Math.max(...nonMaskLayers);
}

export interface LayerImageData {
  image?: LazyLayerImage;
  imageSize: ILayerImage;
  width: number;
  height: number;
  resolutionSize: LayerImageResolutionSize;
}

export interface GenerateLayerImagesResponse {
  [layerId: number]: {
    [defaultPart]: {
      [type: string]: {
        timestamp: Date;
        images: LayerImageData[];
      };
    };
  };
}

export const shouldColourLsdd = (partImages: PartImages) => {
  // Severe defect images come coloured as of v1.18. This provides backwards compatibility.
  // Previously, thumbnails were jpg, so if a jpg exists, we should colour severe defect.
  return (
    partImages?.lsdd?.images?.length && partImages.lsdd.images.some((image) => image.imageSize.key.endsWith('.jpg'))
  );
};

export const generateLayerImages = (layerImages: ILayerImage[]): GenerateLayerImagesResponse => {
  const layerImagesGroup: Record<string, ILayerImage[]> = groupBy(
    layerImages,
    (item) => `${item.layerId}-${item.type}`
  );

  let outputLayerImages: GenerateLayerImagesResponse = {};
  for (const layerImage of layerImages) {
    // Instantiate LayerImages with an empty part
    if (!outputLayerImages[layerImage.layerId]) {
      // Birdseye is the "default" part for now, while we are not supporting multiple parts.
      outputLayerImages[layerImage.layerId] = {[defaultPart]: {}};
    }

    // Ensure we only do this once per layer-image type
    if (outputLayerImages[layerImage.layerId][defaultPart][layerImage.type]) {
      continue;
    }

    const storedLayerImages = layerImagesGroup[`${layerImage.layerId}-${layerImage.type}`];

    if (storedLayerImages) {
      outputLayerImages[layerImage.layerId][defaultPart][layerImage.type] = {
        timestamp: !!layerImage.layerEnd ? layerImage.layerEnd : layerImage.timestamp,
        images: storedLayerImages.map((imageSize) => {
          return {
            imageSize,
            width: imageSize.width,
            height: imageSize.height,
            resolutionSize: imageSize.resolutionSize,
          };
        }),
      };
    }
  }

  return outputLayerImages;
};

export function getCalibrationData(
  calibrationStore: LiveStoreState<ICalibration>,
  calibrationUuid?: string
): {
  calibrationScale: number;
  plateBoundingBox?: [number, number, number, number];
  calibrationResolution?: [number, number];
} {
  // Default to 35 μm/px, but this should only ever happen on dev machines.
  // Real machines should always be calibrated.
  const defaultCalibrationData = {
    calibrationScale: 0.035,
    plateBoundingBox: undefined,
    calibrationResolution: undefined,
  };
  if (!calibrationUuid) return defaultCalibrationData;

  const calibration = calibrationStore.byId[calibrationUuid];
  if (!calibration) return defaultCalibrationData;

  return {
    calibrationScale: calibration.scale,
    plateBoundingBox: calibration.plateBoundingBox,
    calibrationResolution: calibration.shape,
  };
}

// Just setting this to non null will trigger singleImageViewport to update the scale.
// @ts-ignore
export const onPositionChange = (stage: Stage | null) => () => {
  if (stage) {
    stage.getStage().batchDraw();
  }
};

export function num4svg(num: number) {
  return num.toFixed(10);
}

const initialiseColourMapContext = (layerImg: HTMLImageElement, cmRange: number[]) => {
  let cmRangeLen = cmRange[1] - cmRange[0];
  if (cmRangeLen === 0) {
    cmRangeLen = 1;
  }
  let convScale = 255 / cmRangeLen;

  let renderer = document.createElement('img');
  let canvas = document.createElement('canvas');
  canvas.width = layerImg?.width || 400;
  canvas.height = layerImg?.height || 400;

  // Copy the image contents to the canvas
  let ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  ctx?.drawImage(layerImg!, 0, 0, canvas.width, canvas.height);

  let imageData = ctx?.getImageData(0, 0, canvas.width, canvas.height);

  return {imageData, ctx, renderer, convScale, canvas};
};

const renderColourMap = (
  imageData: ImageData,
  ctx: CanvasRenderingContext2D,
  renderer: HTMLImageElement,
  canvas: HTMLCanvasElement,
  forceUpdate: DispatchWithoutAction
) => {
  ctx?.putImageData(imageData, 0, 0);
  renderer.crossOrigin = 'anonymous';
  renderer.src = canvas.toDataURL();
  renderer.addEventListener('load', () => {
    forceUpdate();
  });
};

export const generateColourMaps = (
  layerImg: HTMLImageElement,
  cmRange: number[],
  colorMappings: any,
  forceUpdate: DispatchWithoutAction,
  transparentFilter = true
) => {
  const {imageData, ctx, renderer, convScale, canvas} = initialiseColourMapContext(layerImg, cmRange);

  for (let index = 0; index < imageData.data.length; index += 4) {
    // check for only gray since image grayscale
    if (imageData.data[index] >= cmRange[0] && imageData.data[index] <= cmRange[1]) {
      let convIndex = imageData.data[index] - cmRange[0];
      let colorMap = colorMappings[Math.floor(convIndex * convScale)];

      imageData.data[index] = colorMap[0];
      imageData.data[index + 1] = colorMap[1];
      imageData.data[index + 2] = colorMap[2];
      imageData.data[index + 3] = 255;
    } else {
      imageData.data[index + 3] = transparentFilter ? 1 : 255;
    }
  }

  renderColourMap(imageData, ctx, renderer, canvas, forceUpdate);

  return renderer;
};

export const removeBlacksWithMask = (layerImg: HTMLImageElement, mplmImage: HTMLImageElement) => {
  let layerImageCanvas = document.createElement('canvas');
  let mplmImageCanvas = document.createElement('canvas');
  layerImageCanvas.width = layerImg!.width;
  layerImageCanvas.height = layerImg!.height;
  // Model predicted layer masks are full res and layer images are half res. Setting the canvas width/height
  // to that of the layerImg is essentially down/up scaling the mplmImage to the same size as the layerImg
  mplmImageCanvas.width = layerImg!.width;
  mplmImageCanvas.height = layerImg!.height;

  // Copy the image contents to the canvas
  let layerImageContext = layerImageCanvas.getContext('2d') as CanvasRenderingContext2D;
  let mplmImageContext = mplmImageCanvas.getContext('2d') as CanvasRenderingContext2D;
  layerImageContext?.drawImage(layerImg!, 0, 0, layerImageCanvas.width, layerImageCanvas.height);
  mplmImageContext?.drawImage(mplmImage!, 0, 0, mplmImageCanvas.width, mplmImageCanvas.height);

  let imageData = layerImageContext?.getImageData(0, 0, layerImageCanvas.width, layerImageCanvas.height);
  let mplmData = mplmImageContext?.getImageData(0, 0, mplmImageCanvas.width, mplmImageCanvas.height);
  // Iterate over each pixel
  for (let i = 0; i < mplmData.data.length; i += 4) {
    // check for only one value as image grayscale
    if (mplmData.data[i] <= 127) {
      imageData.data[i + 3] = 0;
    }
  }

  // Put the modified image data back into the context
  layerImageContext?.putImageData(imageData, 0, 0);

  // Convert the canvas to a data URL
  return layerImageCanvas.toDataURL();
};

export const generateOverlayColourMaps = (
  layerImg: HTMLImageElement,
  cmRange: number[],
  severeDefectColor: RGBColor,
  forceUpdate: DispatchWithoutAction
) => {
  const {imageData, ctx, renderer, canvas} = initialiseColourMapContext(layerImg, cmRange);

  for (let index = 0; index < imageData.data.length; index += 4) {
    // check for only gray since image grayscale
    if (imageData.data[index] >= cmRange[0] && imageData.data[index] <= cmRange[1]) {
      imageData.data[index] = severeDefectColor.r;
      imageData.data[index + 1] = severeDefectColor.g;
      imageData.data[index + 2] = severeDefectColor.b;
      imageData.data[index + 3] = (severeDefectColor.a || 1) * 255;
    } else {
      imageData.data[index + 3] = 1;
    }
  }
  renderColourMap(imageData, ctx, renderer, canvas, forceUpdate);

  return renderer;
};

type ImageOrigin = [number, number];

export function calculateImageOffset(
  plateBoundingBox: [number, number, number, number],
  scale: number,
  calibrationResolution: [number, number],
  imageWidthMM: number,
  imageHeightMM: number
): {plateOrigin: ImageOrigin; imageOrigin: ImageOrigin; imageScale: number} {
  // [x, y]
  const plateOrigin: ImageOrigin = [
    (plateBoundingBox[0] + (plateBoundingBox[2] - plateBoundingBox[0]) / 2) * scale,
    (plateBoundingBox[1] + (plateBoundingBox[3] - plateBoundingBox[1]) / 2) * scale,
  ];

  // Origin of the image in mm values
  const imageOrigin: ImageOrigin = [
    (plateOrigin[0] / (calibrationResolution[1] * scale)) * imageWidthMM,
    (plateOrigin[1] / (calibrationResolution[0] * scale)) * imageHeightMM,
  ];

  // Percentage size of the image, compared to full resolution (scale)
  const imageScale = imageWidthMM / (calibrationResolution[1] * scale);

  return {plateOrigin, imageScale, imageOrigin};
}

export function transformPartBoundsToImagePosition(
  parts: IPartGETResponse[],
  imageOrigin: ImageOrigin,
  imageScale: number
) {
  return parts.map((part) => {
    const boundsMM = {
      x: imageOrigin[0] + part.bounds[0] * imageScale,
      y: imageOrigin[1] - (part.bounds[1] + part.bounds[4]) * imageScale,
      width: part.bounds[3] * imageScale,
      height: part.bounds[4] * imageScale,
    };
    const center = {
      x: boundsMM.x + boundsMM.width / 2,
      y: boundsMM.y + boundsMM.height / 2,
    };
    return {...part, boundsMM, center};
  });
}

export function imageSizePixels(
  calibrationResolution: Array<number> | undefined,
  lazyLayerImage: LazyLayerImage | undefined,
  performanceMode?: boolean
) {
  // Calibration Resolution provides the most accurate values for what the intended image width/height is
  // regardless of the actual resolution of the image. Used for scaling and image positioning.
  if (calibrationResolution && calibrationResolution.length) {
    return [calibrationResolution[1], calibrationResolution[0]];
  }

  const widthPx = lazyLayerImage?.widthPx;
  const heightPx = lazyLayerImage?.heightPx;

  // Thumbnail is loaded with no sizing, use a temporary value to prevent flickering
  // (I don't know why this works... Ideally 8000ish should be used by default so the scale is correct)
  if (!widthPx || !heightPx) return [400, 400];
  // Thumbnail loaded. Thumbnails are usually set to 256px width, so there isn't an exact scale
  // If we have the calibration image, we can use that, otherwise * 33 is a best guess.
  if (widthPx < 400) {
    return [widthPx * 33, heightPx * 33];
  }

  // Image is half-size in performance mode, double the pixel size we use to ensure scale is correct.
  return performanceMode ? [widthPx * 2, heightPx * 2] : [widthPx, heightPx];
}

export function getClosestFullLayer(
  layerNum: number,
  totalLayers: number,
  hidePartialLayers: boolean,
  partialLayerNumbers: Set<number>,
  missingLayerNumbers: Set<number>
): number {
  if (!missingLayerNumbers.has(layerNum)) {
    // Include partial layers, so return layerNum regardless
    if (!hidePartialLayers) return layerNum;
    // Hiding partial layers
    // Not a partial layer, return it
    if (!partialLayerNumbers.has(layerNum)) return layerNum;
  }

  // Partial layer or missing layer, find the closest full layer
  function findClosestFullLayer(layerNum: number, direction: -1 | 1): number {
    let newLayerNum = layerNum;
    while (partialLayerNumbers.has(newLayerNum) || missingLayerNumbers.has(newLayerNum)) {
      newLayerNum += direction;
    }
    return newLayerNum;
  }

  const closestLowerLayer = findClosestFullLayer(layerNum, -1);
  const closestHigherLayer = findClosestFullLayer(layerNum, 1);
  if (closestHigherLayer > totalLayers) return closestLowerLayer;
  if (closestLowerLayer < 1) return closestHigherLayer;
  return closestHigherLayer - layerNum < layerNum - closestLowerLayer ? closestHigherLayer : closestLowerLayer;
}
