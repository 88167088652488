import React, {useEffect, useState} from 'react';
import {spacing} from '@material-ui/system';
import styled from 'styled-components';
import {BuildState, IBuild, isCompletedBuildState} from '@common/api/models/builds/IBuild';
import {
  Card as MuiCard,
  CardContent as MuiCardContent,
  Grid,
  Typography,
  CircularProgress,
  Button,
} from '@material-ui/core';
import {toast} from 'react-toastify';
import {DeviceDetailedState} from '@common/api/models/devices/IDevice';
import {Link, useHistory} from 'react-router-dom';
import buildSubmittedImg from '../../../assets/img/build-submitted.png';
import {useSmallScreenSize} from '../../../utils/utilHooks';
import {BUILD_LONG_TRANSITION_TIMEOUT} from './index';
import BuildHeader from '../shared/BuildHeader';
import Header from '../../../components/organisms/Header';
import {useSelector} from 'react-redux';
import {useDeviceStoreActions} from '../../../store/actions';
import {RootState} from '../../../store/reducers';

const Card = styled(MuiCard)(spacing);

const StagingCompleteBuild = ({build}: {build: IBuild}) => {
  const history = useHistory();
  const isSmallScreen = useSmallScreenSize();
  const localStorageKey = `${build.uuid}-staging-complete`;
  const [wasMonitoringStarting, setWasMonitoringStarting] = useState(false);

  const device = useSelector((s: RootState) =>
    build?.deviceSerial ? s.deviceStore.byId[build.deviceSerial] : undefined
  );
  const deviceActions = useDeviceStoreActions();

  const isDev = process.env.NODE_ENV === 'development';

  const transitionFromStarted = () => {
    localStorage.removeItem(localStorageKey);
    history.push(`/builds/uuid/${build.uuid}`);
  };

  useEffect(() => {
    if (
      device?.stateDetailed === DeviceDetailedState.Monitoring ||
      build.state === BuildState.PAUSED ||
      isCompletedBuildState(build.state)
    ) {
      transitionFromStarted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device, build]);

  useEffect(() => {
    const buildTimeoutTimer = setTimeout(() => {
      toast('Starting build has timed out', {type: 'error'});
      transitionFromStarted();
    }, BUILD_LONG_TRANSITION_TIMEOUT);

    // If the build is old enough, transition to the build page
    const buildStartedTime = parseInt(String(localStorage.getItem(localStorageKey)));
    if (buildStartedTime && Date.now() - buildStartedTime > BUILD_LONG_TRANSITION_TIMEOUT) {
      toast('Starting build has timed out', {type: 'error'});
      transitionFromStarted();
    }

    return () => clearTimeout(buildTimeoutTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (build.deviceSerial) {
      deviceActions.ensureConsistent({serial: build.deviceSerial});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [build.deviceSerial]);

  useEffect(() => {
    if (!device) return;

    if (
      [
        DeviceDetailedState.MonitoringStarting,
        DeviceDetailedState.Monitoring,
        DeviceDetailedState.MonitoringStopping,
      ].includes(device.stateDetailed)
    ) {
      setWasMonitoringStarting(true);
    }

    // Gone from any monitoring state -> staging. Indicates failure to start monitoring. Show error and redirect to staging build page.
    if (wasMonitoringStarting && device?.stateDetailed === DeviceDetailedState.Staging) {
      toast('Device failed to enter monitoring, please check device logs.', {type: 'error'});
      transitionFromStarted();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device?.stateDetailed, wasMonitoringStarting]);

  return (
    <>
      <Header
        helmet={`Staging Complete - ${build.name}`}
        title={<BuildHeader build={build} />}
        breadcrumbs={[{title: 'Builds', path: '/builds'}, build.name]}
      />

      <Grid container spacing={isSmallScreen ? 3 : 6}>
        <Grid item xs={12}>
          <Card mb={isSmallScreen ? 3 : 6} padding={isSmallScreen ? 1 : 3}>
            <CardContent style={isSmallScreen ? {padding: '3vh'} : {}}>
              <img src={buildSubmittedImg} alt="Build submitted" />
              <Typography variant={isSmallScreen ? 'h4' : 'h2'}>Your build is starting.</Typography>
              <Typography variant="subtitle1" align="center">
                We're getting the cameras ready, you'll be redirected to the active build page shortly...
              </Typography>
              <CircularProgress />

              <Button color="primary" variant="outlined" component={Link} to="/builds">
                Return to builds list
              </Button>
              {isDev && (
                <Button color="primary" variant="outlined" onClick={transitionFromStarted} style={{marginTop: '16px'}}>
                  Cancel Operation (Dev -UI only)
                </Button>
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default StagingCompleteBuild;

const CardContent = styled(MuiCardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6vh;
  padding-bottom: 6vh !important;

  h2 {
    padding: 32px 0px;
  }
  img {
    height: 25vh;
  }
  .MuiCircularProgress-root {
    margin: 32px 0px;
  }
`;
