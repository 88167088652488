import {useMemo} from 'react';
import {useDispatch} from 'react-redux';
import {bindActionCreators} from 'redux';

import {Resource} from '@common/api/websocket/resourceMessage';

import {createLiveStoreActions, QueryFilter} from './liveUpdateStore';
import {LiveStoreState} from '../model/liveUpdateStore';
import {RootState} from '../reducers';

import {buildsAllGET} from '../../api/ajax/builds';
import {alertsAllGET} from '../../api/ajax/alerts';
import {sliceAttachmentsAllGET} from '../../api/ajax/sliceAttachments';
import {batchesAllGET} from '../../api/ajax/batches';
import {devicesAllGET} from '../../api/ajax/devices';
import {machinesAllGET} from '../../api/ajax/machines';
import {machineParametersAllGET} from '../../api/ajax/machineParameters';
import {materialsAllGET} from '../../api/ajax/materials';
import {partsAllGET} from '../../api/ajax/parts';
import {pointCloudAllGET} from '../../api/ajax/pointCloud';
import {usersAllGET} from '../../api/ajax/users';
import {buildAttachmentsAllGET} from '../../api/ajax/buildAttachments';
import {batchAttachmentsAllGET} from '../../api/ajax/batchAttachments';
import {materialAttachmentsAllGET} from '../../api/ajax/materialAttachments';
import {recipientsAllGET} from '../../api/ajax/recipients';
import {organizationsAllGET} from '../../api/ajax/organizations';
import {layerImageGET} from '../../api/ajax/layerImages';
import {calibrationsAllGET} from '../../api/ajax/calibrations';
import {machineLogsAllGET} from '../../api/ajax/machineLogs';
import {notificationsAllGET} from '../../api/ajax/notification';
import {buildReportAllGET} from '../../api/ajax/buildReport';
import {defectStatisticGET} from '../../api/ajax/defectStatistic';
import {defectSummaryGET} from '../../api/ajax/defectSummary';
import {defectNotificationGET} from '../../api/ajax/defectNotification';
import {buildNotesAllGET} from '../../api/ajax/buildNotes';
import {similarityReportAllGET} from '../../api/ajax/similarityReport';
import {buildIntensityReportAllGET} from '../../api/ajax/buildIntensityReport';
import {resourcePermissionsAllGET} from '../../api/ajax/resourcePermissions';
import {sensorProfilesGET} from '../../api/ajax/sensorProfile';
import {locationBasedDefectsReportGET} from '../../api/ajax/locationBasedDefectsReport';
import {AjaxApiResult} from '../../api/ajax/ajaxWrapper';
import {partModelAttachmentsAllGET} from '../../api/ajax/partModelAttachments';

import similarityReportActions from './similarityReportActions';
import similarityComparisonActions from './similarityComparisonActions';
import buildIntensityReportActions from './buildIntensityReportActions';
import sensorProfileActions from './sensorProfileActions';
import locationBasedDefectsReportActions from './locationBasedDefectsReportActions';
import {ctReportsAllGet} from '../../api/ajax/ctReport';
import ctReportActions from './ctReportActions';
import {buildEventsAllGET} from '../../api/ajax/buildEvent';
import {buildReportDefectProfileAllGET} from '../../api/ajax/buildReportDefectProfile';
import buildReportDefectProfileActions from './buildReportDefectProfileActions';

export function useActions(actions: any) {
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map((a) => bindActionCreators(a, dispatch));
    }
    return bindActionCreators(actions, dispatch);
  }, [actions, dispatch]);
}

function useLiveStoreActions<T>(
  resourceType: Resource,
  wsUrl: string | null,
  fetchFn: (filter: QueryFilter<T>) => AjaxApiResult<T[]>,
  selectFn: (state: RootState) => LiveStoreState<T>
) {
  const actions = createLiveStoreActions(resourceType, wsUrl, fetchFn, selectFn);
  const dispatch = useDispatch();
  return useMemo(() => {
    return bindActionCreators(actions, dispatch);
  }, [actions, dispatch]);
}

export function useBuildStoreActions() {
  return useLiveStoreActions(Resource.BUILD, '/api/builds', buildsAllGET, (s) => s.buildStore);
}

export function useDefectNotificationStoreActions() {
  return useLiveStoreActions(
    Resource.DEFECT_NOTIFICATION,
    '/api/defectNotification',
    defectNotificationGET,
    (s) => s.defectNotificationStore
  );
}

export function useSimilarityReportStoreActions() {
  const liveStoreActions = createLiveStoreActions(
    Resource.SIMILARITY_REPORT,
    null,
    similarityReportAllGET,
    // @ts-ignore
    (s) => s.similarityReportStore
    // @ts-ignore
  );
  const dispatch = useDispatch();
  const allActions = useMemo(() => ({...liveStoreActions, ...similarityReportActions}), [liveStoreActions]);
  return useMemo(() => {
    // @ts-ignore // TODO: TS doesn't respect that an API response may not exactly match table interface
    return bindActionCreators(allActions, dispatch);
    // @ts-ignore
  }, [allActions, dispatch]);
}

export function useSimilarityComparisonStoreActions() {
  const liveStoreActions = createLiveStoreActions(
    Resource.SIMILARITY_COMPARISON,
    null,
    similarityReportAllGET,
    // @ts-ignore
    (s) => s.similarityComparisonStore
    // @ts-ignore
  );
  const dispatch = useDispatch();
  const allActions = useMemo(() => ({...liveStoreActions, ...similarityComparisonActions}), [liveStoreActions]);
  return useMemo(() => {
    // @ts-ignore // TODO: TS doesn't respect that an API response may not exactly match table interface
    return bindActionCreators(allActions, dispatch);
    // @ts-ignore
  }, [allActions, dispatch]);
}

export function useBuildIntensityReportStoreActions() {
  const liveStoreActions = createLiveStoreActions(
    Resource.BUILD_INTENSITY_REPORT,
    '/api/reports/buildIntensity',
    // TODO: WS/redux doesn't respect that an API request may be different from its response
    // @ts-ignore
    buildIntensityReportAllGET,
    // @ts-ignore
    (s) => s.buildIntensityReportStore
  );
  const dispatch = useDispatch();
  const allActions = useMemo(() => ({...liveStoreActions, ...buildIntensityReportActions}), [liveStoreActions]);
  return useMemo(() => {
    // @ts-ignore // TODO: TS doesn't respect that an API response may not exactly match table interface
    return bindActionCreators(allActions, dispatch);
    // @ts-ignore
  }, [allActions, dispatch]);
}

export function useSensorProfileStoreActions() {
  const liveStoreActions = createLiveStoreActions(
    Resource.SENSOR_PROFILE,
    '/api/sensorProfiles',
    // @ts-ignore // TODO: TS doesn't respect that an API response may not exactly match table interface
    sensorProfilesGET,
    // @ts-ignore
    (s) => s.sensorProfileStore
  );

  const dispatch = useDispatch();
  const allActions = useMemo(() => ({...liveStoreActions, ...sensorProfileActions}), [liveStoreActions]);

  return useMemo(() => {
    return bindActionCreators(allActions, dispatch);
  }, [allActions, dispatch]);
}

export function useLocationBasedDefectsReportActions() {
  const liveStoreActions = createLiveStoreActions(
    Resource.LOCATION_BASED_DEFECTS_REPORT,
    '/api/reports/locationBasedDefects',
    // TODO: TS doesn't respect that an API request may be different from its response
    // @ts-ignore
    locationBasedDefectsReportGET,
    // @ts-ignore
    (s) => s.locationBasedDefectsReportStore
  );
  const dispatch = useDispatch();
  const allActions = useMemo(() => ({...liveStoreActions, ...locationBasedDefectsReportActions}), [liveStoreActions]);
  return useMemo(() => {
    // @ts-ignore // TODO: TS doesn't respect that an API response may not exactly match table interface
    return bindActionCreators(allActions, dispatch);
    // @ts-ignore
  }, [allActions, dispatch]);
}

export function useDefectStatisticStoreActions() {
  return useLiveStoreActions(
    Resource.DEFECT_STATISTIC,
    '/api/defectStats',
    defectStatisticGET,
    (s) => s.defectStatisticStore
  );
}

export function useDefectSummaryStoreActions() {
  return useLiveStoreActions(
    Resource.DEFECT_SUMMARY,
    '/api/defectSummary/',
    defectSummaryGET,
    (s) => s.defectSummaryStore
  );
}

export function useBuildReportStoreActions() {
  return useLiveStoreActions(
    Resource.BUILD_REPORT,
    null,
    // TODO: WS/redux doesn't respect that an API request may be different from its response
    // @ts-ignore
    buildReportAllGET,
    // @ts-ignore
    (s) => s.buildReportStore
  );
}

export function useBuildReportDefectProfileStoreActions() {
  const liveStoreActions = createLiveStoreActions(
    Resource.BUILD_REPORT_DEFECT_PROFILE,
    '/api/reports/buildReportDefectProfiles',
    buildReportDefectProfileAllGET,
    (s) => s.buildReportDefectProfileStore
  );

  const dispatch = useDispatch();
  const allActions = useMemo(() => ({...liveStoreActions, ...buildReportDefectProfileActions}), [liveStoreActions]);

  return useMemo(() => {
    return bindActionCreators(allActions, dispatch);
  }, [allActions, dispatch]);
}

export function useCtReportStoreActions() {
  const liveStoreActions = createLiveStoreActions(
    Resource.CT_REPORT,
    '/api/reports/ct',
    // @ts-ignore

    ctReportsAllGet,
    // @ts-ignore

    (s) => s.ctReportStore
  );
  const dispatch = useDispatch();
  const allActions = useMemo(() => ({...liveStoreActions, ...ctReportActions}), [liveStoreActions]);
  return useMemo(() => {
    // @ts-ignore // TODO: TS doesn't respect that an API response may not exactly match table interface
    return bindActionCreators(allActions, dispatch);
    // @ts-ignore
  }, [allActions, dispatch]);
}

export function useAlertStoreActions() {
  return useLiveStoreActions(Resource.ALERT, '/api/alerts', alertsAllGET, (s) => s.alertStore);
}

export function useSliceAttachmentStoreActions() {
  return useLiveStoreActions(
    Resource.SLICE_ATTACHMENT,
    '/api/sliceAttachments',
    sliceAttachmentsAllGET,
    (s) => s.sliceAttachmentStore
  );
}

export function useBuildAttachmentStoreActions() {
  return useLiveStoreActions(
    Resource.BUILD_ATTACHMENT,
    '/api/buildAttachments',
    buildAttachmentsAllGET,
    (s) => s.buildAttachmentStore
  );
}

export function usePartModelAttachmentStoreActions() {
  return useLiveStoreActions(
    Resource.PART_MODEL_ATTACHMENT,
    '/api/partModelAttachments',
    // TODO: WS/redux doesn't respect that an API request may be different from its response
    // @ts-ignore
    partModelAttachmentsAllGET,
    // @ts-ignore
    (s) => s.partModelAttachmentStore
  );
}

export function useBatchAttachmentStoreActions() {
  // TODO: depreciate & remove
  return useLiveStoreActions(
    Resource.BATCH_ATTACHMENT,
    '/api/batchAttachments',
    batchAttachmentsAllGET,
    (s) => s.batchAttachmentStore
  );
}

export function useMaterialAttachmentStoreActions() {
  return useLiveStoreActions(
    Resource.MATERIAL_ATTACHMENT,
    '/api/materialAttachments',
    materialAttachmentsAllGET,
    (s) => s.materialAttachmentStore
  );
}

export function useBatchStoreActions() {
  return useLiveStoreActions(Resource.BATCH, '/api/batches', batchesAllGET, (s) => s.batchStore);
}

export function useDeviceStoreActions() {
  return useLiveStoreActions(Resource.DEVICE, '/api/devices', devicesAllGET, (s) => s.deviceStore);
}

export function useCalibrationStoreActions() {
  return useLiveStoreActions(Resource.CALIBRATION, '/api/calibrations', calibrationsAllGET, (s) => s.calibrationStore);
}

export function useMachineStoreActions() {
  return useLiveStoreActions(Resource.MACHINE, '/api/machines', machinesAllGET, (s) => s.machineStore);
}

export function useNotificationStoreActions() {
  return useLiveStoreActions(
    Resource.NOTIFICATION,
    '/api/notification',
    notificationsAllGET,
    (s) => s.notificationStore
  );
}

export function useMachineParameterStoreActions() {
  return useLiveStoreActions(
    Resource.MACHINE_PARAMETER,
    '/api/machineParameters',
    machineParametersAllGET,
    (s) => s.machineParameterStore
  );
}

export function useBuildEventStoreActions() {
  return useLiveStoreActions(
    Resource.BUILD_EVENT,
    '/api/buildEvents',
    // TODO: WS/redux doesn't respect that an API request may be different from its response
    // @ts-ignore
    buildEventsAllGET,
    (s) => s.buildEventStore
  );
}

export function useMachineLogStoreActions() {
  return useLiveStoreActions(Resource.MACHINE_LOG, '/api/machineLogs', machineLogsAllGET, (s) => s.machineLog);
}

export function useMaterialStoreActions() {
  return useLiveStoreActions(Resource.MATERIAL, '/api/materials', materialsAllGET, (s) => s.materialStore);
}

export function usePartStoreActions() {
  return useLiveStoreActions(
    Resource.PART,
    '/api/parts',
    // TODO: WS/redux doesn't respect that an API request may be different from its response
    // @ts-ignore
    partsAllGET,
    // @ts-ignore
    (s) => s.partStore
  );
}

export function usePointCloudStoreActions() {
  return useLiveStoreActions(
    Resource.POINT_CLOUD,
    '/api/pointCloud',
    // TODO: WS/redux doesn't respect that an API request may be different from its response
    // @ts-ignore
    pointCloudAllGET,
    // @ts-ignore
    (s) => s.pointCloudStore
  );
}

export function useLayerImageStoreActions() {
  return useLiveStoreActions(Resource.LAYER_IMAGE, '/api/layerImages', layerImageGET, (s) => s.layerImageStore);
}

export function useUserStoreActions() {
  return useLiveStoreActions(Resource.USER, '/api/users', usersAllGET, (s) => s.userStore);
}

export function useResourcePermissionStoreActions() {
  return useLiveStoreActions(
    Resource.RESOURCE_PERMISSION,
    null,
    resourcePermissionsAllGET,
    (s) => s.resourcePermissionStore
  );
}

export function useAuthorizationActions() {
  return useLiveStoreActions(
    Resource.AUTHORIZATION,
    '/api/resourcePermissions',
    resourcePermissionsAllGET,
    (s) => s.authorizationStore
  );
}

export function useRecipientStoreActions() {
  return useLiveStoreActions(Resource.RECIPIENT, '/api/recipients', recipientsAllGET, (s) => s.recipientStore);
}

export function useOrganizationStoreActions() {
  return useLiveStoreActions(
    Resource.ORGANIZATION,
    '/api/organizations',
    organizationsAllGET,
    (s) => s.organizationStore
  );
}

export function useBuildNoteStoreActions() {
  return useLiveStoreActions(Resource.BUILD_NOTE, `/api/buildNotes`, buildNotesAllGET, (s) => s.buildNoteStore);
}

const actions: {[key: string]: () => any} = {
  useBuildStoreActions,
  useDefectNotificationStoreActions,
  useDefectStatisticStoreActions,
  useDefectSummaryStoreActions,
  useAlertStoreActions,
  useSliceAttachmentStoreActions,
  useBuildAttachmentStoreActions,
  useBatchAttachmentStoreActions,
  useMaterialAttachmentStoreActions,
  useBatchStoreActions,
  useDeviceStoreActions,
  useCalibrationStoreActions,
  useMachineStoreActions,
  useNotificationStoreActions,
  useMachineParameterStoreActions,
  useMachineLogStoreActions,
  useMaterialStoreActions,
  usePartStoreActions,
  usePointCloudStoreActions,
  useLayerImageStoreActions,
  useUserStoreActions,
  useResourcePermissionStoreActions,
  useAuthorizationActions,
  useRecipientStoreActions,
  useOrganizationStoreActions,
  useBuildNoteStoreActions,
  useSimilarityReportStoreActions,
  useSimilarityComparisonStoreActions,
  useBuildIntensityReportStoreActions,
  useBuildReportStoreActions,
  useBuildReportDefectProfileStoreActions,
  useSensorProfileStoreActions,
  useLocationBasedDefectsReportActions,
  useCtReportStoreActions,
  useBuildEventStoreActions,
  usePartModelAttachmentStoreActions,
};

export default actions;
