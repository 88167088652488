import React from 'react';
import {Box, Typography} from '@material-ui/core';
import {Units} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {DefectType, FILTERABLE_DEFECT_TYPES, getPrettyDefectType} from '@common/api/models/builds/data/defects/IDefect';
import {FloatInput, SelectInput} from './BuildReportDefectProfileInputs';
import {useSmallScreenSize} from '../../utils/utilHooks';

interface BuildReportDefectProfileGradeSingleConditionProps {
  threshold: number;
  unit: Units;
  defectType: DefectType;
  onChange: (field: string, value: number | Units | DefectType | null) => void;
}

const UnitsLabel = {
  [Units.VOLUME]: 'mm³ (Defect Volume)',
  [Units.AREA]: 'mm² (Defect Area)',
  [Units.COVERAGE]: '% (Defect Coverage)',
};

function BuildReportDefectProfileGradeSingleCondition({
  threshold,
  unit,
  defectType,
  onChange,
}: BuildReportDefectProfileGradeSingleConditionProps) {
  const isSmallScreen = useSmallScreenSize();
  const thresholdValue = !!threshold && unit === Units.COVERAGE ? threshold * 100 : threshold;

  const onThresholdChanged = (field: string, value: number | Units | DefectType) => {
    if (unit === Units.COVERAGE && field === 'threshold') {
      onChange(field, (value as number) / 100);
    } else {
      onChange(field, value);
    }
  };

  const onUnitChanged = (field: string, value: number | Units | DefectType) => {
    onChange('threshold', null);
    onChange(field, value);
  };

  return (
    <>
      <FloatInput
        value={thresholdValue}
        onChange={onThresholdChanged}
        field="threshold"
        label="Threshold Amount"
        marginLeft="0px"
        fullWidth={isSmallScreen}
        max={unit === Units.COVERAGE ? 100 : undefined}
      />
      <Box display="flex" alignItems="center">
        <SelectInput
          value={unit}
          options={Object.values(Units).map((unit) => ({value: unit, label: UnitsLabel[unit]}))}
          onChange={onUnitChanged}
          field="unit"
          label="Units"
          fullWidth={isSmallScreen}
        />
        <Typography style={{marginRight: '12px'}}>of</Typography>
      </Box>
      <SelectInput
        value={defectType}
        options={FILTERABLE_DEFECT_TYPES.map((defectType) => ({
          value: defectType,
          label: getPrettyDefectType(defectType),
        }))}
        onChange={onChange}
        field="defectType"
        label="Defect Type"
        fullWidth={isSmallScreen}
      />
    </>
  );
}

export default BuildReportDefectProfileGradeSingleCondition;
