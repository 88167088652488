import {cloneDeep, omit} from 'lodash';
import {IBuildReportDefectProfile} from '@common/api/models/builds/data/IBuildReportDefectProfiles';
import {
  BuildReportDefectProfileActions,
  BuildReportDefectProfileStoreState,
  CloneProfileAction,
  RemoveUnsavedChangesAction,
  RevertUnsavedChangesAction,
  UpdateProfileAction,
} from '../../model/buildReportDefectProfile';
import {LiveStoreState} from '../../model/liveUpdateStore';
import createReducer from '../createReducer';
import {LiveStoreReducers} from '../liveUpdateStore';

export class BuildReportDefectProfileLiveReducers extends LiveStoreReducers<
  IBuildReportDefectProfile,
  BuildReportDefectProfileStoreState
> {
  additionalReducers() {
    return {
      updateProfile(
        state: BuildReportDefectProfileStoreState,
        {payload: {profile, uuid}}: UpdateProfileAction
      ): BuildReportDefectProfileStoreState {
        const currentProfile = state.byId[uuid] || {};
        const original = state.originals[uuid] || cloneDeep(currentProfile);

        return {
          ...state,
          byId: {...state.byId, [uuid]: {...currentProfile, ...profile}},
          originals: {...state.originals, [uuid]: original},
        };
      },

      removeUnsavedChanged(
        state: BuildReportDefectProfileStoreState,
        {payload: {uuid}}: RemoveUnsavedChangesAction
      ): BuildReportDefectProfileStoreState {
        let byId = state.byId;

        if (uuid === 'new') {
          byId = omit(byId, 'new');
        }

        return {
          ...state,
          byId,
          originals: {...omit(state.originals, uuid)},
        };
      },

      revertUnsavedChanges(
        state: BuildReportDefectProfileStoreState,
        {payload: {uuid}}: RevertUnsavedChangesAction
      ): BuildReportDefectProfileStoreState {
        let byId = state.byId;

        if (uuid === 'new') {
          byId = omit(byId, 'new');
        } else {
          byId[uuid] = cloneDeep(state.originals[uuid] || byId[uuid]);
        }

        return {
          ...state,
          byId,
          originals: {...omit(state.originals, uuid)},
        };
      },

      cloneNewProfile(
        state: BuildReportDefectProfileStoreState,
        {payload: {profile}}: CloneProfileAction
      ): BuildReportDefectProfileStoreState {
        return {
          ...state,
          byId: {...state.byId, new: profile},
        };
      },
    };
  }

  createReducer(initialState: BuildReportDefectProfileStoreState) {
    const superReducers = this.bindReducers();
    const additionalReducers = this.additionalReducers();

    return createReducer<LiveStoreState<IBuildReportDefectProfile>>(initialState, {
      ...superReducers,

      [BuildReportDefectProfileActions.UPDATE_BUILD_REPORT_DEFECT_PROFILE]: additionalReducers.updateProfile,
      [BuildReportDefectProfileActions.CLONE_BUILD_REPORT_DEFECT_PROFILE]: additionalReducers.cloneNewProfile,
      [BuildReportDefectProfileActions.REMOVE_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE]:
        additionalReducers.removeUnsavedChanged,
      [BuildReportDefectProfileActions.REVERT_UNSAVED_CHANGES_BUILD_REPORT_DEFECT_PROFILE]:
        additionalReducers.revertUnsavedChanges,
    });
  }
}
