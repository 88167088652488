import React from 'react';
import {useSelector} from 'react-redux';
import {Typography, Box} from '@material-ui/core';
import {RootState} from '../../store/reducers/index';
import {FetchingState} from '../../store/model/liveUpdateStore';
import NewProfileButton from './NewProfileButton';

export function NoProfiles() {
  const failedToFetch = useSelector(
    (state: RootState) => state.buildReportDefectProfileStore.fetched === FetchingState.Error
  );

  return (
    <Box height="100%" width="100%" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Typography variant="h3" style={{marginBottom: '32px'}}>
        {failedToFetch ? 'Failed to fetch profiles.' : 'No profiles have been created.'}
      </Typography>
      {!failedToFetch && <NewProfileButton />}
    </Box>
  );
}
